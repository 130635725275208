export enum VisualSearchAPIResponse {
  UNKNOWN,
  FETCHING,
  FETCHED,
  FAILED
}

export interface QnaItem {
  question: string;
  answer: string;
}

export interface ImageMatchSearchResponse {
  debugInfo: {
    queryId: string;
    timeElapsed: number;
  };
  styleIdList: string[];
}

export interface UploadImageResponse {
  keyName: string;
  presignedURL: string;
}

export interface BoundingBoxData {
  boundingBox: BoundingBox;
  boundingBoxId: string;
  score: number;
  productType: string;
}

export interface BoundingBox {
  bbTx: number;
  bbTy: number;
  bbTrx: number;
  bbTry: number;
}

export interface BoundingBoxProperties {
  boundingBox: BoundingBox;
  boundingBoxId: string;
  score: number;
  productAttributes: {
    productType: string;
    brand: string;
    gender: string;
  };
}

export interface BoundingBoxResult {
  contentType: string;
  properties: BoundingBoxProperties;
}

export interface DebugInfo {
  totalTime: number;
  queryId: string;
}

export interface BoundingBoxApiResponse {
  result: BoundingBoxResult[];
  debugInfo: DebugInfo;
}
