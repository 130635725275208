import { ProductCarousel } from '@mweb/zappos-ui/ProductCarousel';
import type { ProductCarouselProps } from '@mweb/zappos-ui/ProductCarousel';

interface ProductDetailCarouselProps {
  slides: React.ReactNode[];
  title: string | undefined;
  overrides?: ProductCarouselProps['overrides'];
}

export const ProductDetailCarousel = (props: ProductDetailCarouselProps) => {
  const { title, slides, overrides } = props;

  return (
    <section className="flex flex-col gap-4">
      {title && <h2 className="grow text-xl/[1.2] font-semibold lg:text-2xl/[1.2]">{title}</h2>}

      <ProductCarousel options={{ slidesToScroll: 1, skipSnaps: true }} slides={slides} overrides={overrides} />
    </section>
  );
};
