import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';

interface ProductGenderProps {
  gender: string;
  className?: string;
}

function ProductGender({ gender, className }: ProductGenderProps) {
  const { testId } = useMartyContext();
  return (
    <span className={cn(className, 'text-tertiary')} data-test-id={testId('productGender')}>
      {gender}
    </span>
  );
}

export default ProductGender;
