import appendQuery from 'append-query';

import { ABSOLUTE_URL_RE } from 'common/regex';
import { logError } from 'middleware/logger';
// need this so that we can just reuse the createLocation logic for parsing string path to pathname + query
import { getType, isExternalType, stripSchemeAndDomain } from 'history/historyFactory';
import { createLocationWithParams } from 'helpers/SearchUtils';

/**
 * Given a relative string URL, returns a Location object with pathname and search fields parsed.
 * @param  {String} path a relative url
 * @return {Location}      object with pathname and search fields parsed from input.
 */
export function parsePath(path: string) {
  const loc = createLocationWithParams(path, {});
  const { pathname, search, hash } = new URL(loc, 'http://blah.com'); // host doesn't matter since we don't care about the href
  return {
    pathname,
    search,
    hash
  };
}

/**
 * Given an absolute URL, return the relative portion of it.
 * @param  {String} url absolute url
 * @return {String}     relative portion of url.
 */
export function relativizeUrl(url: string) {
  const matches = ABSOLUTE_URL_RE.exec(url);
  if (matches) {
    const relativeUrl = url.substring(matches.index + matches[0]!.length);
    return relativeUrl === '' ? '/' : relativeUrl;
  } else {
    return url;
  }
}

export const toRelativeUrlString = ({ pathname, search = '', hash = '' }: { pathname: string; search?: string; hash?: string }) =>
  `${pathname}${search}${hash}`;

export const queryParameterExists = (key: string, queryString = window.location.search) => new URLSearchParams(queryString).has(key);

export const getAbsoluteUrl = (relativePath: string, origin: string = window.location.origin) => `${origin}${relativePath}`;

export const stripQueryParams = (urlString: string, ...paramsToStrip: string[]) =>
  appendQuery(
    urlString,
    paramsToStrip.reduce((acc, cur) => ({ ...acc, [cur]: null }), {}),
    { removeNull: true }
  );

export const isValidMartyPath = (urlString: string, baseUrl?: string) => {
  let formattedString = urlString;

  if (/%/.test(formattedString)) {
    // is an encoded string
    try {
      formattedString = decodeURIComponent(formattedString);
    } catch (err) {
      logError(err); // malformed URI
    }
  }

  formattedString = stripSchemeAndDomain(formattedString, baseUrl);
  // if formattedString is a valid Marty path or a valid non-Marty path
  return getType(formattedString) || isExternalType(formattedString);
};

/**
 * Returns true if the given string is a valid URL.
 */
const isValidUrl = (urlString: string) => {
  try {
    new URL(urlString);
    return true;
  } catch (err) {
    return false;
  }
};

/**
 * Parses the returnTo URL and returns a valid absolute URL to redirect to. If the returnTo URL is an
 * absolute URL and a subdomain of the marketplace.siteDomain, it will be returned as is. Otherwise,
 * it will be relativized and returned as an absolute URL.
 */
export const parseReturnToUrl = (returnTo: string, cookieDomain: string, protocol: string, host: string) => {
  const isAbsoluteUrl = isValidUrl(returnTo);

  if (isAbsoluteUrl) {
    const isSubdomain = new URL(returnTo)?.host.endsWith(cookieDomain);
    return isSubdomain ? returnTo : '';
  } else {
    const martyPath = isValidMartyPath(returnTo) && relativizeUrl(returnTo);
    return martyPath ? `${protocol}://${host}${martyPath}` : '';
  }
};
