import type { ContextType, ReactNode } from 'react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { MartyContext } from 'utils/context';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { trackError } from 'helpers/ErrorUtils';
import { track as amethystTrack, titaniteView } from 'apis/amethyst';
import { noop } from 'helpers/index';
import marketplace from 'cfg/marketplace.json';
import type { AppState } from 'types/app';

function identity<T>(i: T) {
  return i;
}

interface Props {
  renderTestLocators: boolean;
  legacyCustomerId?: string;
  history?: any; // TODO type redux router history
  environmentConfig?: any; // TODO type config from server
  children: ReactNode;
}

type State = ContextType<typeof MartyContext>;

export class MartyContextProvider extends Component<Props, State> {
  static childContextTypes = {
    testId: PropTypes.func,
    marketplace: PropTypes.object,
    router: PropTypes.object
  };
  constructor(props: Props) {
    super(props);
    // needs to be in constructor rather than instance property because testId
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      testId: this.getTestId(),
      loadedWithHash: false,
      touchDetected: false,
      preventOnTouchDevice: (i: any) => (!this.state.touchDetected ? i : undefined),
      router: props.history,
      environmentConfig: props.environmentConfig,
      // set this in constructor so there is no initial render with the old marketplace config
      marketplace,
      trackError,
      trackEvent,
      trackLegacyEvent,
      amethystTrack,
      titaniteView
    };
  }
  getChildContext() {
    // Legacy context has been deprecated and will be removed in a future marty release
    return {
      testId: this.getTestId(),
      marketplace,
      router: this.props.history
    };
  }

  componentDidMount() {
    // detect if user touched device (needs to be a named function so it can be unbound within itself)
    const detectTouch = () => {
      this.setState(state => ({ ...state, touchDetected: true }));
      window.removeEventListener('touchstart', detectTouch);
    };
    window.addEventListener('touchstart', detectTouch);

    if (window.location.hash) {
      this.setState(state => ({ ...state, loadedWithHash: true }));
    }
  }

  getTestId = () => {
    const { renderTestLocators } = this.props;
    return renderTestLocators ? identity : (noop as any);
  };

  render() {
    return <MartyContext.Provider value={this.state}>{this.props.children}</MartyContext.Provider>;
  }
}

export default connect((state: AppState) => {
  const { cookies, holmes } = state;
  const renderTestLocators = 'renderTestLocators' in cookies;
  const legacyCustomerId = holmes ? holmes.customerId : '';
  return {
    renderTestLocators,
    legacyCustomerId
  };
})(MartyContextProvider);
