export const inIframe = (win?: Window) => {
  if (typeof win === 'undefined' && typeof window === 'undefined') {
    return false;
  }

  const w = win || window;

  try {
    return w.self !== w.top;
  } catch (e) {
    return true;
  }
};
