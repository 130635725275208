import { useIsEnrolledInVariant } from './unleash/useIsEnrolledInVariant';
import useMartyContext from './useMartyContext';

import { FEATURE_MY_ACCOUNT_REDESIGN } from 'constants/features';

const useMyAccountRedesignEnabled = () => {
  const isInMyAccountRedesignTreatment = useIsEnrolledInVariant(FEATURE_MY_ACCOUNT_REDESIGN).isEnrolled;
  const { marketplace } = useMartyContext();
  return isInMyAccountRedesignTreatment && marketplace.hasMyAccountRedesign;
};

export default useMyAccountRedesignEnabled;
