/**
 * A function for generating guids
 */
export function guid(dashed: boolean = false): string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return dashed
    ? s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
    : s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
}
