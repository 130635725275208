import type { ProductLanding } from 'types/landing';
import type { FormattedJanusReco, RecoFromJanus } from 'types/mafia';
import type { ProductWithRelationsFromCalypso } from 'types/calypso';
import { getProductWithRelatedStyles } from 'helpers/RecoUtils';
import type { BadgeData } from 'types/badges';

import css from 'styles/components/common/badgeUtils.scss';

export const badgeLabelMap = new Map([
  ['NEW', 'New'],
  ['OZ', 'Only at Zappos'],
  ['BS', 'Best Seller'],
  ['ZBD', 'Zappos Birthday'],
  ['', ''],
  [undefined, '']
]);

export const getProductNameBadgeLabel = (badges: { bid: string | undefined }[]) => {
  if (!badges) {
    return;
  }

  let badgeLabel;

  if (badges?.[0]?.bid !== 'BS') {
    badgeLabel = badgeLabelMap.get(badges[0]?.bid) + ' ';
  }

  return badgeLabel ?? '';
};

export const getRowHasBadge = (products: ProductLanding[] | FormattedJanusReco[] | RecoFromJanus[], productRelations: any) => {
  if (!products) {
    return;
  }
  let rowHasBadge = false;

  products.map((product: ProductLanding | FormattedJanusReco | RecoFromJanus) => {
    const { styleId } = product;
    let productWithRelatedStyles: any = {};
    if (productRelations) {
      productWithRelatedStyles = getProductWithRelatedStyles(styleId, productRelations);
    }

    const { badges } = productWithRelatedStyles;

    if (badges) {
      rowHasBadge = true;
      return;
    }
  });

  return rowHasBadge;
};

export const getBadgeId = (badges: BadgeData[] | undefined) => {
  if (!badges) {
    return;
  }

  return badges.length > 0 ? badges[0]?.bid : undefined;
};

export const formattedProductsWithBadges = (
  products: ProductWithRelationsFromCalypso[] | FormattedJanusReco[] | RecoFromJanus[] | ProductLanding[],
  productRelations: any // TODO ts needs to be typed correctly in future
) => {
  if (!productRelations) {
    return [];
  }

  const formattedProduct = products.flatMap(({ styleId }: { styleId: string }) => {
    const productWithRelatedStyles = getProductWithRelatedStyles(styleId, productRelations);

    if (!productWithRelatedStyles) {
      return [];
    }
    const { badges, productId, colorId } = productWithRelatedStyles || {};

    return { productId, styleId, colorId, badgeId: getBadgeId(badges) };
  });

  return formattedProduct;
};

export const getRowHasBadgeClassname = (style: string | undefined) => {
  switch (style) {
    case 'melody':
      return css.productSearchRow;
    case 'melody-grid-half':
      return css.smallCardRow;
    case 'melody-curated':
      return css.curatedRow;
    case 'image-inline':
    case 'default-results':
      return css.largeCardRow;
    default:
      return css.rowHasBadge;
  }
};

const BadgeUtils = {
  getProductNameBadgeLabel,
  getRowHasBadge,
  getBadgeId,
  formattedProductsWithBadges,
  getRowHasBadgeClassname
};

export default BadgeUtils;
