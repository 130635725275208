import { loadableReady } from '@loadable/component';

import bootstrapOnClient from 'entrypoints/bootstrapOnClient';
import ApplicationRoot from 'containers/Root';
import { makeRoutes, storeOptions } from 'ecommerce/ecommerceAppConfig';
import { loadPolyfills } from 'helpers/polyfills';
import reportWebVitals from 'utils/reportWebVitals';
import marketplace from 'cfg/marketplace.json';

Promise.all([loadPolyfills(), loadableReady()]).then(() => bootstrapOnClient(ApplicationRoot, makeRoutes, { storeOptions }));

if (module.hot) {
  module.hot.accept();
}

if (marketplace.reportWebVitals) {
  reportWebVitals();
}
