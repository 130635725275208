import { track } from 'apis/amethyst';
import { evNavigationClick } from 'events/headerFooter';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';

export const trackAccountMenuEvent = (event: string, legacyEvent: string, navValue: string) => {
  trackEvent(event);
  trackLegacyEvent('Main-Nav', 'Account', legacyEvent);
  track(() => [
    evNavigationClick,
    {
      valueClicked: navValue,
      parentDropdown: 'My Account'
    }
  ]);
};
