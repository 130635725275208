import marketplace from 'cfg/marketplace.json';
import { ABSOLUTE_URL_RE, PRODUCT_COLOR_URL_FRAGMENT_RE } from 'common/regex';
import { buildSeoProductUrl } from 'helpers/SeoUrlBuilder';
const { authUrl } = marketplace;

/**
 * Given an express request, generate a base url for redirects
 * @param  {object} req express request object
 * @return {string}     redirect base url
 */
export const baseUrl = req => `${req.protocol}://${req.get('Host')}`;

/**
 * Return the url to redirect to if the request should be redirected.
 * @param  {object}   req       express request object
 * @param  {number}   status    response status
 * @param  {string}   location  location to redirect to
 * @return {boolean}            true if redirect executed, else false
 */
export const redirectLocation = ({ req, status, location }) => {
  if (location && [301, 302, 307].some(statusCode => statusCode === status)) {
    return location.replace('{{applicationBaseUrl}}', baseUrl(req));
  }
};

/**
 * Return a URL to ZAP with the provided redirectPath to take the user to upon successful authentication.
 * @param {String} redirectPath URL to return to upon successful authentication
 * @param {String?} authUrlPrefix optional route to ZAP
 */
export const buildAuthenticationRedirectUrl = (redirectPath, authUrlPrefix = authUrl) => {
  const isAbsoluteUrl = ABSOLUTE_URL_RE.test(redirectPath);
  return `${authUrlPrefix}${isAbsoluteUrl ? '' : '{{applicationBaseUrl}}'}${redirectPath}`;
};

const buildSeoUrl = (originalUrl, productState) => {
  const originalUrlHasColor = PRODUCT_COLOR_URL_FRAGMENT_RE.test(originalUrl);
  const { colorId, detail, requestedAsin, requestedStockId } = productState;
  return buildSeoProductUrl(detail, originalUrlHasColor || requestedAsin || requestedStockId ? colorId : undefined);
};

const extractPathFromUrl = requestUrl => {
  try {
    // base URL doesn't matter
    const url = new URL(`http://www.zappos.com${requestUrl}`);
    return url.pathname;
  } catch (e) {
    return '';
  }
};

export const redirectNonGenderedUrlToGenderedUrl = ({ pageType, store, originalUrl }) => {
  const isProductPageType = pageType === 'product';
  const state = store.getState();
  // short circuit if we're not on the product page or the state indicates we should be redirecting already
  if (!isProductPageType || state.redirect?.location) {
    return false;
  }

  const seoProductUrl = buildSeoUrl(originalUrl, state.product);
  const originalPath = extractPathFromUrl(originalUrl);
  if (originalPath && originalPath !== seoProductUrl) {
    const queryString = state.router.location.search;
    return { seoProductUrl: `${seoProductUrl}${queryString || ''}` };
  } else {
    return false;
  }
};
