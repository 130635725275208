import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/common/crossSiteLabel.scss';

const CrossSiteLabel = ({ storeName }) => {
  const { testId } = useMartyContext();
  if (storeName) {
    return (
      <p className={cn(css.crossSiteLabel)} data-test-id={testId('crossSiteLabel')} aria-label={`Available on ${storeName}.com`}>
        {`${storeName}.com`}
      </p>
    );
  }
  return null;
};

export default CrossSiteLabel;
