// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { SmallIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeCloseSmallIcon = (props: SmallIconProps) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path
      d="M4 3.66675L12.3333 12.0001M4 12.0001L12.3333 3.66675"
      stroke="currentcolor"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default UtilityStrokeCloseSmallIcon;
