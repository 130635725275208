import { constructMSAImageUrl, constructMSASrcset } from 'helpers';
import ProductUtils from 'helpers/ProductUtils';
import { createCurrencyObj } from 'helpers/DataFormatUtils';

const getCardData = props => {
  const {
    alt = '',
    index,
    imageId,
    imageMap = {},
    mainImage = {},
    isHovered,
    msaImageId,
    msaImageParams,
    originalPrice,
    price = null,
    productRating,
    reviewCount,
    reviewRating,
    thumbnailImageId,
    fullSizeImage = false,
    imageNoBackground = false,
    animationTimerIndex,
    animationImages,
    showRatingStars,
    color,
    brandName,
    productName,
    txAttrFacet_Gender: gendersAttribute
  } = props;

  /**
   * Media props
   */

  const media = {
    imageIndex: index,
    isHovered: false,
    fullSizeImage,
    imageNoBackground,
    mainImage
  };

  const msaId = imageId || thumbnailImageId || msaImageId || null;

  if (msaId) {
    const makeProductHoverImage = () => {
      const { PT01, PT03, TOPP, LEFT, RGHT, BACK } = imageMap;
      return RGHT || TOPP || LEFT || BACK || PT03 || PT01 || msaId;
    };

    const mainImage = msaId;
    const hoverImageId = makeProductHoverImage();
    const hasHoverImage = hoverImageId && mainImage !== hoverImageId;

    media.isHovered = isHovered;

    const imageParams = { ...msaImageParams, width: 768, height: 1024 };

    const makeImage = (image, params) => {
      const url = constructMSAImageUrl(image, params);
      const srcset = constructMSASrcset(image, {
        ...params,
        useWebp: true,
        minWidth: 150
      });

      return {
        alt: alt || [color, brandName, productName].filter(Boolean).join(' '),
        src: url,
        // sizes is not exact, but pretty close.
        // it might make sense to move sizes and aspect ratio to the layout (component?) eventually.
        sizes: '(max-width: 1024px) 50vw, max(25vw, 300px)',
        style: {
          aspectRatio: '3 / 4'
        },
        webp: {
          srcSet: srcset
        }
      };
    };

    if (animationImages?.length > 0) {
      let activeIndex;
      // If the image has less animation frames than the timer count, just show the last one
      if (animationTimerIndex > animationImages.length - 1) {
        activeIndex = animationImages.length - 1;
      } else {
        activeIndex = animationTimerIndex;
      }

      const { msaId, attrs } = animationImages[activeIndex];

      // this doesn't get a srcSet or else animation doesn't happen
      media.mainImage = {
        src: `https://m.media-amazon.com/images/I/${msaId}._${attrs}_AC_SR255,340.jpg`
      };
    } else {
      media.mainImage = makeImage(mainImage, imageParams);
    }

    if (hasHoverImage) {
      media.hoverImage = makeImage(hoverImageId, imageParams);
    }
  }

  /**
   * Price component props
   */

  const priceData = {};

  const priceObj = createCurrencyObj(price);

  if (price) {
    priceData.price = priceObj;
    priceData.label = priceData.price.string;
  }

  if (priceObj.string && originalPrice) {
    priceData.msrp = createCurrencyObj(originalPrice);
    priceData.onSale = ProductUtils.isStyleOnSale({
      price: priceObj.string,
      originalPrice: priceData.msrp.string
    });
    if (priceData.onSale) {
      priceData.label = `On sale for ${priceData.price.string}. MSRP ${priceData.msrp.string}.`;
    }
  }

  /**
   * Review component props
   */

  const reviews = {};

  if ((showRatingStars && productRating) || reviewRating) {
    const rating = typeof reviewRating === 'number' ? reviewRating : parseInt(productRating, 10);
    const floatRating = rating.toFixed(1);
    reviews.roundedRating = productRating;
    reviews.rating = floatRating;
    reviews.label = floatRating > 0 ? `${floatRating} out of 5 stars` : '';
  }

  if (reviewCount) {
    reviews.ratingCount = reviewCount;
  }

  /**
   * txAttrFacet_Gender component prop
   */
  const gender = ProductUtils.getProductGender(gendersAttribute);

  return { media, price: priceData, reviews, gender };
};

export default getCardData;
