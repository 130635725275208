// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { SmallIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeInfoOutlineSmallIcon = (props: SmallIconProps) => (
  <Icon {...props} viewBox="0 0 16 16">
    <circle cx="8" cy="8" r="6.6" stroke="currentcolor" strokeWidth="0.8" />
    <circle cx="8.00016" cy="5.33317" r="0.666667" fill="currentcolor" />
    <path d="M8 8V11" stroke="currentcolor" strokeLinecap="round" />
  </Icon>
);

export default UtilityStrokeInfoOutlineSmallIcon;
