import { lowercaseThenCapitalize } from 'helpers/index';
import { devLogger } from 'middleware/logger';
import type {
  AirplaneCache,
  AirplaneCacheAgeGroup,
  AirplaneCacheConcreteDimensionOption,
  AirplaneCacheDimensionOption,
  AirplaneCacheGenderOption,
  AirplaneCacheShoeTypeOption,
  AirplaneCacheStock,
  AirplaneCacheUnitOption,
  VirtualDimension
} from 'types/AirplaneCache';
import type { AirplaneSizingOption, ProductBundle, ProductStockData } from 'types/cloudCatalog';
import type { SelectedSizing } from 'types/product';

type MinimumProductBundle = Pick<ProductBundle, 'defaultProductType' | 'sizing' | 'styles'>;

export const WOMENS_GENDER_RANK = 31000;
export const MENS_GENDER_RANK = 31100;
export const UNKNOWN_GENDER_RANK = 32000;
export const UNKNOWN_SIZE_RANK = 1000000;
export const UNKNOWN_WIDTH_RANK = 1000001;

const VIRTUAL_DIMENSIONS: VirtualDimension[] = [
  {
    allKey: 'ageGroups',
    constraintsKey: 'ageGroup',
    stockKey: 'ageGroup'
  },
  {
    allKey: 'countryOrUnitOptions',
    constraintsKey: 'countryOrUnit',
    stockKey: 'countryOrUnit'
    // default to be dictated by ranking
  },
  {
    allKey: 'genderOptions',
    constraintsKey: 'gender',
    defaultValue: 'womens',
    stockKey: 'gender'
  },
  {
    allKey: 'shoeTypeOptions',
    constraintsKey: 'shoeType',
    defaultValue: 'LEFT',
    stockKey: 'shoeType'
  },
  {
    allKey: 'sizeRangeOptions',
    constraintsKey: 'sizeRange',
    defaultValue: 'LEFT',
    stockKey: 'shoeType'
  }
];

export function makeVirtualDimensions(all: AirplaneCache['all']): VirtualDimension[] {
  return VIRTUAL_DIMENSIONS.filter(vd => all[vd.allKey].length > 0);
}

export function makeValidConstraints(
  all: AirplaneCache['all'],
  virtualDimensions: AirplaneCache['virtualDimensions'],
  givenConstraints: Partial<AirplaneCache['constraints']>
): AirplaneCache['constraints'] {
  const ret = { ...givenConstraints };
  // there's a few instances of `as any` below. i think it's necessary here - i
  // don't know of a way to tie the virtual dimension default value type to the
  // constraint value type without making the virtual dimension lists
  // unnecessarily difficult to work with
  for (const vd of virtualDimensions) {
    // if a value is not already set for this virtual dimension...
    if (!givenConstraints[vd.constraintsKey]) {
      // if this virtual dimension has no default value...
      if (typeof vd.defaultValue === 'undefined') {
        // if there is at least 1 option for this virtual dimension...
        if (all[vd.allKey].length > 0) {
          // set the selected value to the first option
          ret[vd.constraintsKey] = all[vd.allKey][0]!.constraintValue as any;
        }
      } else {
        // otherwise, if there _is_ a default value for this virtual dimension...
        // and one of the following is true:
        // - there are no options
        // - there is an option that matches the default
        if (all[vd.allKey].length === 0 || all[vd.allKey].some(option => option.constraintValue === vd.defaultValue)) {
          // set the selected value to the virtual dimension's default
          ret[vd.constraintsKey] = vd.defaultValue as any;
        } else {
          // otherwise set the selected value to the first option
          ret[vd.constraintsKey] = all[vd.allKey][0]!.constraintValue as any;
        }
      }
    }
  }
  return ret as AirplaneCache['constraints'];
}

type ConstraintKey = keyof AirplaneCache['constraints'];

/**
 * Basically orders gender buttons as "Women" and then "Men". Using a rank for
 * this matches how we do it for sizing and leaves room in case we use this
 * field for "unisex", non-binary genders, or just non-gender data in general.
 */
export function getGenderRank(gender: string): number {
  if (gender === 'womens') {
    return WOMENS_GENDER_RANK;
  }
  if (gender === 'mens') {
    return MENS_GENDER_RANK;
  }
  return UNKNOWN_GENDER_RANK;
}

const DEFAULT_AGE_GROUP = 'adult';

const AGE_GROUP_LABELS: Record<AirplaneCacheStock['ageGroup'], string> = {
  'adult': 'Adult',
  'big-kids': 'Big Kid',
  'infant': 'Infant',
  'kids': 'Kids',
  'little-kids': 'Little Kid',
  'toddler': 'Toddler'
};

const AGE_GROUP_RANKS: Record<AirplaneCacheStock['ageGroup'], number> = {
  'adult': 16000,
  'big-kids': 15000,
  'infant': 11000,
  'kids': 13000,
  'little-kids': 14000,
  'toddler': 12000
};

function makeAirplaneCacheAgeGroup(airplaneSizingOption: AirplaneSizingOption): AirplaneCacheAgeGroup {
  const id = airplaneSizingOption.ageGroup || DEFAULT_AGE_GROUP;
  const constraintValue = id;
  const label = AGE_GROUP_LABELS[id];
  const rank = AGE_GROUP_RANKS[id];
  return { constraintValue, id, label, rank };
}

function makeColorAirplaneCacheDimensionOption(detail: MinimumProductBundle, legacyStockObject: ProductStockData): AirplaneCacheDimensionOption {
  const { styles } = detail;
  const colorId = legacyStockObject.color;

  const index = styles.findIndex(style => style.colorId === colorId);
  const style = styles[index];
  const label = style?.color || '';

  return {
    id: colorId,
    constraintValue: colorId,
    label,
    rank: index
  };
}

type SizeTypeRankKey = '_clothingStandardOverride' | AirplaneCacheStock['countryOrUnit'];

const SIZE_TYPE_RANK: Record<SizeTypeRankKey, number> = {
  _clothingStandardOverride: 10000,
  US: 11000,
  AT: 12000,
  AU: 12000,
  BR: 12000,
  EU: 12000,
  UK: 12000,
  standard: 13000,
  centimeter: 14000,
  inch: 14000
};

const DEFAULT_SIZE_UNIT = 'standard';

const AIRPLANE_CACHE_SIZE_UNIT_LABEL: Record<AirplaneCacheStock['countryOrUnit'], string> = {
  AT: 'AT Sizing',
  AU: 'AU Sizing',
  BR: 'BR Sizing',
  centimeter: 'Centimeters',
  EU: 'EU Sizing',
  inch: 'Inches',
  standard: 'Standard',
  UK: 'UK Sizing',
  US: 'US Sizing'
};

function makeAirplaneCacheUnitRank(detail: MinimumProductBundle, id: AirplaneCacheStock['countryOrUnit']) {
  if (detail.defaultProductType === 'Clothing' && id === 'standard') {
    return SIZE_TYPE_RANK['_clothingStandardOverride'];
  }
  return SIZE_TYPE_RANK[id];
}

function makeAirplaneCacheUnitOption(detail: MinimumProductBundle, airplaneSizingOption: AirplaneSizingOption): AirplaneCacheUnitOption {
  const id = airplaneSizingOption.sizeType || DEFAULT_SIZE_UNIT;
  const constraintValue = id;
  const label = AIRPLANE_CACHE_SIZE_UNIT_LABEL[id];
  const rank = makeAirplaneCacheUnitRank(detail, id);
  return { constraintValue, id, label, rank };
}

function isKidsAgeGroup(ageGroup: AirplaneSizingOption['ageGroup']): boolean {
  return ageGroup !== 'adult';
}

const DEFAULT_GENDER = 'womens';

const ADULT_GENDER_LABELS: Record<'mens' | 'womens', string> = {
  mens: 'Men',
  womens: 'Women'
};

const KIDS_GENDER_LABELS: Record<'mens' | 'womens', string> = {
  mens: 'Boys',
  womens: 'Girls'
};

function makeGenderId({ ageGroup, gender }: AirplaneSizingOption): AirplaneCacheGenderOption['id'] {
  return `${ageGroup || DEFAULT_AGE_GROUP}-${gender || DEFAULT_GENDER}`;
}

function makeGenderAirplaneCacheDimensionOption(airplaneSizingOption: AirplaneSizingOption): AirplaneCacheGenderOption {
  const { ageGroup: maybeAgeGroup, gender } = airplaneSizingOption;
  const ageGroup = maybeAgeGroup || DEFAULT_AGE_GROUP;
  const constraintValue = gender || DEFAULT_GENDER;
  const id = makeGenderId(airplaneSizingOption);
  const label = isKidsAgeGroup(ageGroup) ? KIDS_GENDER_LABELS[constraintValue] : ADULT_GENDER_LABELS[constraintValue];
  const rank = getGenderRank(constraintValue);
  return { ageGroup, constraintValue, id, label, rank };
}

function getShoeTypeLabel(shoeType: AirplaneSizingOption['shoeType']): string {
  if (shoeType === 'LEFT') {
    return 'Left';
  }
  if (shoeType === 'RIGHT') {
    return 'Right';
  }
  return 'Pair';
}

function getShoeTypeRank(shoeType: AirplaneSizingOption['shoeType']): number {
  if (shoeType === 'LEFT') {
    return 41000;
  }
  if (shoeType === 'RIGHT') {
    return 41100;
  }
  return 42000;
}

function makeShoeTypeAirplaneCacheDimensionOption(airplaneSizingOption: AirplaneSizingOption): AirplaneCacheShoeTypeOption {
  const { shoeType } = airplaneSizingOption;
  const constraintValue = shoeType || '';
  const id = shoeType || '';
  const label = getShoeTypeLabel(shoeType);
  const rank = getShoeTypeRank(shoeType);
  return { constraintValue, id, label, rank };
}

function makeSizeRangeId(airplaneSizingOption: AirplaneSizingOption) {
  const { bodyType, heightType } = airplaneSizingOption;
  const sizeRangeQualifiers = [];
  if (bodyType && bodyType !== 'REGULAR') {
    sizeRangeQualifiers.push(bodyType);
  }
  if (heightType && heightType !== bodyType && heightType !== 'REGULAR') {
    sizeRangeQualifiers.push(heightType);
  }
  if (sizeRangeQualifiers.length === 0) {
    return 'REGULAR';
  }
  sizeRangeQualifiers.sort();
  return sizeRangeQualifiers.join(':');
}

function makeLabelForSizeRange(sizeRangeId: string): string {
  const [a, b] = sizeRangeId.split(':').map(lowercaseThenCapitalize);
  if (b) {
    return `${a} & ${b}`;
  }
  return a!;
}

/** Put compound size ranges after simple ones ("Big" -> "Tall" -> "Big & Tall") */
function makeRankForSizeRange(sizeRangeId: string) {
  if (sizeRangeId === 'REGULAR') {
    return 1000;
  }
  if (sizeRangeId.includes(':')) {
    return 3000;
  }
  return 2000;
}

function makeSizeRangeAirplaneCacheDimensionOption(airplaneSizingOption: AirplaneSizingOption): AirplaneCacheDimensionOption {
  const id = makeSizeRangeId(airplaneSizingOption);
  const constraintValue = id;
  const label = makeLabelForSizeRange(id);
  const rank = makeRankForSizeRange(id);
  return { id, constraintValue, label, rank };
}

function getRankAndDimensionValue(
  detail: MinimumProductBundle,
  legacyStockObject: ProductStockData,
  dimensionKey: string,
  isPrimary: boolean
): { rank: number; value: string } {
  const value = legacyStockObject[dimensionKey]!;
  const legacyDimensionOption = detail.sizing.allValues.find(option => option.id === value);
  const defaultRank = isPrimary ? UNKNOWN_SIZE_RANK : UNKNOWN_WIDTH_RANK;
  const rank = legacyDimensionOption ? +legacyDimensionOption.rank : defaultRank;
  return { rank, value };
}

function makeLabelForAirplaneCacheSizeOrWidthOption(option: AirplaneSizingOption, sizeRangeId: string, isPrimary: boolean): string {
  const { sizeLabel, widthLabel } = option;
  const baseOptionText = (isPrimary ? sizeLabel : widthLabel) || '';
  if (sizeRangeId !== 'REGULAR') {
    const sizeRangeLabel = makeLabelForSizeRange(sizeRangeId);
    return `${baseOptionText} ${sizeRangeLabel}`;
  }
  return baseOptionText;
}

function makeIdForAirplaneCacheSizeOrWidthOption(option: AirplaneSizingOption, sizeRange: string, isPrimary: boolean): string {
  const { ageGroup, gender, shoeType, sizeLabel, sizeType, widthLabel } = option;
  // this is called baseLabel because when a product has size ranges, the
  // label is modified to include the size range
  const baseLabel = isPrimary ? sizeLabel : widthLabel;
  return [sizeType, gender, ageGroup, sizeRange, baseLabel, shoeType].filter(item => !!item).join('-');
}

function makeSizeOrWidthAirplaneCacheDimensionOption(
  detail: MinimumProductBundle,
  legacyStockObject: ProductStockData,
  airplaneSizingOption: AirplaneSizingOption,
  sizeRange: string,
  dimensionKey: string,
  isPrimary: boolean
): AirplaneCacheConcreteDimensionOption {
  const { ageGroup: maybeAgeGroup, gender: maybeGender, sizeType } = airplaneSizingOption;

  const ageGroup = maybeAgeGroup || DEFAULT_AGE_GROUP;
  const countryOrUnit = sizeType || DEFAULT_SIZE_UNIT;
  const gender = maybeGender || DEFAULT_GENDER;

  const label = makeLabelForAirplaneCacheSizeOrWidthOption(airplaneSizingOption, sizeRange, isPrimary);
  const id = makeIdForAirplaneCacheSizeOrWidthOption(airplaneSizingOption, sizeRange, isPrimary);
  const shoeType = airplaneSizingOption.shoeType || undefined;
  const { rank, value: dimensionValue } = getRankAndDimensionValue(detail, legacyStockObject, dimensionKey, isPrimary);
  const constraintValue = dimensionValue;

  return {
    ageGroup,
    constraintValue,
    countryOrUnit,
    gender,
    id,
    label,
    rank,
    shoeType,
    sizeRange
  };
}

/**
 * Can be used to sort an array of objects with a `rank` field in ascending
 * order.
 *
 * @example
 * const sclRankings = [{ name: 'Zain', rank: 2 }, { name: 'Mango', rank: 1 }];
 * sclRankings.sort(rankComparator);
 * // sclRankings is now [{ name: 'Mango', rank: 1 }, { name: 'Zain', rank: 2 }]
 */
export function rankComparator(a: { rank: number }, b: { rank: number }) {
  return a.rank - b.rank;
}

function scrubShoeType(shoeType: AirplaneSizingOption['shoeType']): AirplaneCacheStock['shoeType'] {
  return shoeType || undefined;
}

/**
 * This function helps with the following:
 *
 * - Ensures that we only add one of each dimension option into their
 *   respective arrays
 * - Ensures that we only do the work to construct the dimension option info
 *   once per option
 */
function recordAirplaneCacheDimensionOption<T extends { id: string }>(
  array: T[],
  id: string | null | undefined,
  makeDimensionOption: () => T
): T | undefined {
  if (id && !array.some(option => option.id === id)) {
    const newOption = makeDimensionOption();
    if (newOption.id !== id) {
      devLogger(
        `WARNING: mismatch between given ID and produced ID for airplane cache dimension option (${JSON.stringify({
          given: id,
          produced: newOption.id
        })})`
      );
    }
    array.push(newOption);
    return newOption;
  } else {
    return;
  }
}

export function getSingleOptionDimensionValue(options: AirplaneCacheConcreteDimensionOption[]): string | undefined {
  if (options.length === 0) {
    return;
  }
  const returnValue = options[0]!.constraintValue;
  if (options.some(option => returnValue !== option.constraintValue)) {
    return;
  }
  return returnValue;
}

export function getSingleOptionDimensionValues(
  airplaneCache: Pick<AirplaneCache, 'all' | 'sizeDimensionKey' | 'widthDimensionKey'>
): SelectedSizing {
  const {
    all: { sizeOptions, widthOptions },
    sizeDimensionKey,
    widthDimensionKey
  } = airplaneCache;

  const ret: SelectedSizing = {};

  if (sizeDimensionKey) {
    ret[sizeDimensionKey] = getSingleOptionDimensionValue(sizeOptions);
  }
  if (widthDimensionKey) {
    ret[widthDimensionKey] = getSingleOptionDimensionValue(widthOptions);
  }

  return ret;
}

function getEffectiveConstraints(airplaneCache: Omit<AirplaneCache, 'available'>) {
  const effectiveConstraints: Partial<AirplaneCache['constraints']> = {
    ...airplaneCache.constraints
  };

  function removeConstraintIfNotAChoice(options: { constraintValue: string }[], key: ConstraintKey) {
    const value = effectiveConstraints[key];
    if (value === undefined) {
      // no constraint is set for `key`
      return;
    }
    if (options.length > 1 && options.some(option => option.constraintValue === value)) {
      // this is a valid constraint
      return;
    }
    // this is an invalid constraint
    delete effectiveConstraints[key];
  }

  removeConstraintIfNotAChoice(airplaneCache.all.colorOptions, 'ageGroup');
  removeConstraintIfNotAChoice(airplaneCache.all.colorOptions, 'colorId');
  removeConstraintIfNotAChoice(airplaneCache.all.countryOrUnitOptions, 'countryOrUnit');
  removeConstraintIfNotAChoice(airplaneCache.all.genderOptions, 'gender');
  removeConstraintIfNotAChoice(airplaneCache.all.shoeTypeOptions, 'shoeType');
  removeConstraintIfNotAChoice(airplaneCache.all.sizeOptions, 'sizeDimensionValue');
  removeConstraintIfNotAChoice(airplaneCache.all.widthOptions, 'widthDimensionValue');

  return effectiveConstraints;
}

/**
 * WARNING: The airplane cache object is updated in-place. It is returned from
 * this function for typechecking convenience.
 */
function refreshAirplaneCacheAvailableObject(airplaneCache: Omit<AirplaneCache, 'available'>): AirplaneCache {
  const effectiveConstraints = getEffectiveConstraints(airplaneCache);

  const available: AirplaneCache['available'] = {
    ageGroupOptions: [],
    colorOptions: [],
    countryOrUnitOptions: [],
    genderOptions: [],
    shoeTypeOptions: [],
    sizeRangeOptions: [],
    sizeOptions: [],
    widthOptions: []
  };

  function deleteAllButOneKey(stock: Partial<AirplaneCacheStock>, omittedKey: keyof AirplaneCacheStock) {
    const keys: (keyof AirplaneCacheStock)[] = ['colorId', 'countryOrUnit', 'gender', 'sizeDimensionValue', 'sizeRange', 'widthDimensionValue'];
    keys.splice(keys.indexOf(omittedKey), 1);
    for (const key of keys) {
      delete stock[key];
    }
  }

  function markIfAvailable<T>(array: T[], id: T | undefined) {
    if (!id) {
      return;
    }
    if (array.includes(id)) {
      return;
    }
    array.push(id);
  }

  airplaneCache.all.stocks.forEach(stock => {
    if (!(stock.onHand > 0)) {
      // skip this stock if it's unavailable
      return;
    }
    const stockCopy: Partial<AirplaneCacheStock> = { ...stock };
    if (effectiveConstraints.ageGroup && stock.ageGroup !== effectiveConstraints.ageGroup) {
      deleteAllButOneKey(stockCopy, 'ageGroup');
    }
    if (effectiveConstraints.colorId && stock.colorId !== effectiveConstraints.colorId) {
      deleteAllButOneKey(stockCopy, 'colorId');
    }
    if (effectiveConstraints.countryOrUnit && stock.countryOrUnit !== effectiveConstraints.countryOrUnit) {
      deleteAllButOneKey(stockCopy, 'countryOrUnit');
    }
    if (effectiveConstraints.sizeDimensionValue && stock.sizeDimensionValue !== effectiveConstraints.sizeDimensionValue) {
      deleteAllButOneKey(stockCopy, 'sizeDimensionValue');
    }
    if (effectiveConstraints.sizeRange && stock.sizeRange !== effectiveConstraints.sizeRange) {
      deleteAllButOneKey(stockCopy, 'sizeRange');
    }
    if (effectiveConstraints.widthDimensionValue && stock.widthDimensionValue !== effectiveConstraints.widthDimensionValue) {
      deleteAllButOneKey(stockCopy, 'widthDimensionValue');
    }
    if (effectiveConstraints.gender && stock.gender !== effectiveConstraints.gender) {
      deleteAllButOneKey(stockCopy, 'gender');
    }
    if (effectiveConstraints.shoeType && stock.shoeType !== effectiveConstraints.shoeType) {
      deleteAllButOneKey(stockCopy, 'shoeType');
    }
    markIfAvailable(available.ageGroupOptions, stockCopy.ageGroup);
    markIfAvailable(available.colorOptions, stockCopy.colorId);
    markIfAvailable(available.countryOrUnitOptions, stockCopy.countryOrUnit);
    markIfAvailable(available.genderOptions, stockCopy.gender);
    markIfAvailable(available.shoeTypeOptions, stockCopy.shoeType);
    markIfAvailable(available.sizeOptions, stockCopy.sizeDimensionValue);
    markIfAvailable(available.sizeRangeOptions, stockCopy.sizeRange);
    markIfAvailable(available.widthOptions, stockCopy.widthDimensionValue);
  });

  const ret = airplaneCache as AirplaneCache;
  ret.available = available;
  return ret;
}

/**
 * Find which stocks are available for a new set of airplane cache constraints.
 *
 * WARNING: The airplane cache object is updated in-place. It is returned from
 * this function for typechecking convenience.
 *
 * Old constraints are passed in as part of the given airplaneCache (a field in
 * the first argument: airplaneCache.constraints). New constraints are passed
 * in as the second argument. The old constraints are used as the base for the
 * new ones. See the example below for more clarity.
 *
 * @return The airplaneCache given in the first argument.
 * @example
 *   oldConstraints = { color: 'blue', size: 7 };
 *   newConstraints = { color: 'green', width: 'wide' };
 *   resultingConstraints = { color: 'green', size: 7, width: 'wide' };
 */
export function addAndUpdateAirplaneCacheConstraints(
  airplaneCache: Omit<AirplaneCache, 'available'>,
  newConstraints: Partial<AirplaneCache['constraints']>
): AirplaneCache {
  airplaneCache.constraints = {
    ...airplaneCache.constraints,
    ...newConstraints
  };
  return refreshAirplaneCacheAvailableObject(airplaneCache);
}

/**
 * Find which stocks are available for a new set of airplane cache constraints.
 *
 * WARNING: The airplane cache object is updated in-place. It is returned from
 * this function for typechecking convenience.
 *
 * The old constraints are discarded completely.
 *
 * @return The airplaneCache given in the first argument.
 */
export function wipeAndReplaceAirplaneCacheConstraints(
  airplaneCache: Omit<AirplaneCache, 'available'>,
  newConstraints: AirplaneCache['constraints']
): AirplaneCache {
  airplaneCache.constraints = newConstraints;
  return refreshAirplaneCacheAvailableObject(airplaneCache);
}

/**
 * Creates the initial airplane cache for the redux store.
 *
 * @param detail The product bundle from cloud catalog.
 * @param constraints The constraints (partial color and sizing selection) to
 *                    put on the `available` entry in the cache. (The product's
 *                    default color and the customer's sizing prediction may be
 *                    set as initial constraints.)
 * @returns           An airplane cache object. This gets assigned to
 *                    `state.product.detail.sizing.airplaneCache`
 */
export function makeAirplaneCache(
  detail: MinimumProductBundle,
  givenConstraints: Partial<AirplaneCache['constraints']> = {}
): AirplaneCache | undefined {
  const {
    sizing: { airplaneSizingByStockId, dimensions, stockData: legacyStockList }
  } = detail;

  if (!airplaneSizingByStockId || Object.keys(airplaneSizingByStockId).length === 0) {
    return;
  }

  let sizeDimensionKey: string | undefined;
  let sizeDimensionName: string | undefined;
  let widthDimensionKey: string | undefined;
  let widthDimensionName: string | undefined;
  const [sizeDimensionInfo, widthDimensionInfo] = dimensions;
  if (sizeDimensionInfo) {
    sizeDimensionKey = 'd' + sizeDimensionInfo.id; // 'd3', 'd11', etc.
    sizeDimensionName = sizeDimensionInfo.name;
  }
  if (widthDimensionInfo) {
    widthDimensionKey = 'd' + widthDimensionInfo.id; // 'd4', 'd13', etc.
    widthDimensionName = widthDimensionInfo.name;
  }

  const sizeHints: string[] = [];

  let useNarrowButtonsForSizeOptions = true;

  // the "all" field in the airplane seat sizing cache
  const all: AirplaneCache['all'] = {
    ageGroups: [],
    colorOptions: [],
    countryOrUnitOptions: [],
    genderOptions: [],
    shoeTypeOptions: [],
    sizeOptions: [],
    sizeRangeOptions: [],
    widthOptions: [],
    stocks: []
  };

  for (let i = 0; i < legacyStockList.length; ++i) {
    const legacyStockObject = legacyStockList[i]!;
    const airplaneSizingOptions = airplaneSizingByStockId[legacyStockObject.id]!;
    if (!airplaneSizingOptions) {
      // skip if there's no airplane sizing data for the stock ID (just in
      // case. this should be an invalid state)
      continue;
    }

    for (let j = 0; j < airplaneSizingOptions.length; ++j) {
      const airplaneSizingOption = airplaneSizingOptions[j]!;

      const { ageGroup } = airplaneSizingOption;
      recordAirplaneCacheDimensionOption(all.ageGroups, ageGroup, () => makeAirplaneCacheAgeGroup(airplaneSizingOption));

      recordAirplaneCacheDimensionOption(all.colorOptions, legacyStockObject.color, () =>
        makeColorAirplaneCacheDimensionOption(detail, legacyStockObject)
      );

      recordAirplaneCacheDimensionOption(all.countryOrUnitOptions, airplaneSizingOption.sizeType, () =>
        makeAirplaneCacheUnitOption(detail, airplaneSizingOption)
      );

      const genderId = makeGenderId(airplaneSizingOption);
      recordAirplaneCacheDimensionOption(all.genderOptions, genderId, () => makeGenderAirplaneCacheDimensionOption(airplaneSizingOption));

      const shoeType = scrubShoeType(airplaneSizingOption.shoeType);
      recordAirplaneCacheDimensionOption(all.shoeTypeOptions, shoeType, () => makeShoeTypeAirplaneCacheDimensionOption(airplaneSizingOption));

      const { sizeHint } = airplaneSizingOption;
      if (sizeHint && !sizeHints.includes(sizeHint)) {
        sizeHints.push(sizeHint);
      }

      const sizeRangeId = makeSizeRangeId(airplaneSizingOption);
      if (sizeRangeId) {
        recordAirplaneCacheDimensionOption(all.sizeRangeOptions, sizeRangeId, () => makeSizeRangeAirplaneCacheDimensionOption(airplaneSizingOption));
      }

      if (sizeDimensionKey) {
        const sizeOptionId = makeIdForAirplaneCacheSizeOrWidthOption(airplaneSizingOption, sizeRangeId, true);
        if (legacyStockObject[sizeDimensionKey]) {
          const newSizeOption = recordAirplaneCacheDimensionOption(all.sizeOptions, sizeOptionId, () =>
            makeSizeOrWidthAirplaneCacheDimensionOption(detail, legacyStockObject, airplaneSizingOption, sizeRangeId, sizeDimensionKey!, true)
          );
          const sizeLabelLength = newSizeOption?.label?.length || 0;
          if (sizeLabelLength >= 6) {
            useNarrowButtonsForSizeOptions = false;
          }
        }
      }

      if (widthDimensionKey) {
        const widthOptionId = makeIdForAirplaneCacheSizeOrWidthOption(airplaneSizingOption, sizeRangeId, false);
        if (legacyStockObject[widthDimensionKey]) {
          recordAirplaneCacheDimensionOption(all.widthOptions, widthOptionId, () =>
            makeSizeOrWidthAirplaneCacheDimensionOption(detail, legacyStockObject, airplaneSizingOption, sizeRangeId, widthDimensionKey!, false)
          );
        }
      }

      const stock: AirplaneCacheStock = {
        ageGroup: airplaneSizingOption.ageGroup || DEFAULT_AGE_GROUP,
        colorId: legacyStockObject.color,
        countryOrUnit: airplaneSizingOption.sizeType || DEFAULT_SIZE_UNIT,
        gender: airplaneSizingOption.gender || DEFAULT_GENDER,
        onHand: +(legacyStockObject.onHand || 0),
        shoeType,
        sizeLabel: airplaneSizingOption.sizeLabel || '',
        sizeRange: sizeRangeId,
        stockId: airplaneSizingOption.stockId || '',
        widthLabel: airplaneSizingOption.widthLabel || ''
      };
      if (sizeDimensionKey) {
        stock.sizeDimensionValue = legacyStockObject[sizeDimensionKey];
      }
      if (widthDimensionKey) {
        stock.widthDimensionValue = legacyStockObject[widthDimensionKey];
      }
      all.stocks.push(stock);
    }
  }

  all.ageGroups.sort(rankComparator);
  all.colorOptions.sort(rankComparator);
  all.countryOrUnitOptions.sort(rankComparator);
  all.genderOptions.sort(rankComparator);
  all.shoeTypeOptions.sort(rankComparator);
  all.sizeOptions.sort(rankComparator);
  all.sizeRangeOptions.sort(rankComparator);
  all.widthOptions.sort(rankComparator);

  const virtualDimensions = makeVirtualDimensions(all);
  const constraints = makeValidConstraints(all, virtualDimensions, givenConstraints);

  if (sizeHints.length > 1) {
    const sizeHintsToText = sizeHints.map(hint => `"${hint}"`).join(', ');
    devLogger(`WARNING: multiple size hints found in airplane data (${sizeHintsToText})`);
  }
  const sizeHint = sizeHints[0];

  return refreshAirplaneCacheAvailableObject({
    all,
    constraints,
    sizeDimensionKey,
    sizeDimensionName,
    sizeHint,
    useNarrowButtonsForSizeOptions,
    virtualDimensions,
    widthDimensionKey,
    widthDimensionName
  });
}
