import { RESET_ALL_DEBUG_FLAGS, RESET_DEBUG_FLAG, SET_DEBUG_FLAG } from 'constants/reduxActions';
import type { DebugFlagsAction } from 'actions/debugFlags';

export interface DebugFlags {
  alwaysAllowAirplane: boolean;
}

const INITIAL_STATE: DebugFlags = {
  alwaysAllowAirplane: false
};

export default function (state: Readonly<DebugFlags> = { ...INITIAL_STATE }, action: DebugFlagsAction) {
  switch (action.type) {
    case RESET_ALL_DEBUG_FLAGS: {
      return { ...INITIAL_STATE };
    }
    case RESET_DEBUG_FLAG: {
      return {
        ...state,
        [action.key]: INITIAL_STATE[action.key]
      };
    }
    case SET_DEBUG_FLAG: {
      return {
        ...state,
        [action.key]: action.value
      };
    }
  }
  return state;
}
