import { Helmet } from 'react-helmet-async';

import type { DocumentMetaProps } from './types';
import { renderTags } from './renderTags';

export function DocumentMeta(props: DocumentMetaProps) {
  const tags = renderTags(props);

  return (
    <>
      <Helmet>{tags}</Helmet>
      {props.children}
    </>
  );
}
