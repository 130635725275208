import ExecutionEnvironment from 'exenv';
import { stringify } from 'query-string';

import { setFederatedLoginModalVisibility } from 'actions/headerfooter';
import { REDIRECT } from 'constants/reduxActions';
import { prependAppRoot } from 'history/AppRootUtils';
import { buildAuthenticationRedirectUrl } from 'utils/redirect';
import { isCurrentPageUrlTest } from 'helpers/LandingPageUtils';
import { getInfoForUrlTest } from 'helpers/HydraHelpers';
import { triggerAssignment } from 'actions/ab';
import logger from 'middleware/logger';
import marketplace from 'cfg/marketplace.json';

const {
  hasFederatedLogin,
  search: { hasSeoUrls }
} = marketplace;

export const OOS_REDIRECT_SUFFIX = '?oosRedirected=true';

export function redirectTo(urlFragment, status = 302) {
  return {
    type: REDIRECT,
    location: urlFragment,
    status
  };
}

export function asyncRedirectTo(url, status = 302, timeout = 50) {
  return dispatch => {
    setTimeout(() => {
      dispatch(redirectTo(url, status));
    }, timeout);
  };
}

export function redirectWithAppRoot(route) {
  return (dispatch, getState) => {
    const {
      router: { location }
    } = getState();
    dispatch(redirectTo(prependAppRoot(route, location)));
    return Promise.resolve();
  };
}

export function redirectToSearch(seoTerm) {
  // redirect to pretty URL for search. If we use /search?term=${seoTerm} zfc
  // could strip out the actual oosRedirected flag.
  if (hasSeoUrls) {
    return redirectWithAppRoot(`/${seoTerm}${OOS_REDIRECT_SUFFIX}`);
  } else {
    return redirectWithAppRoot(`/search${OOS_REDIRECT_SUFFIX}&term=${seoTerm}`);
  }
}

/**
 * Returns a `redirectTo` action to login with the login return URL being the
 * provided location.
 *
 * @param {Object} Location object with pathname and search fields. URL to
 *                 return to upon successful authentication.
 * @param {string|undefined} [redirectOnClose] -> string path to redirect if the login modal closes
 */
export const redirectToAuthenticationFor = ({ pathname, search, hash = '' }, redirectOnClose) => {
  const encodedUriComponent = encodeURIComponent(`${pathname}${search}${hash}`);
  const authRedirectUrl = buildAuthenticationRedirectUrl(encodedUriComponent);

  return (dispatch, getState) => {
    const state = getState();
    const { pageView: { pageType: sourcePageType } = {} } = state;

    if (hasFederatedLogin) {
      return dispatch(
        setFederatedLoginModalVisibility(true, {
          returnTo: encodedUriComponent,
          redirectOnClose,
          sourcePageType
        })
      );
    } else {
      return dispatch(redirectTo(authRedirectUrl));
    }
  };
};

export function redirectToUrlTest(pathname, push, mp = marketplace) {
  return function (dispatch, getState) {
    const { cookies, ab: { urlTests } = {} } = getState();
    const currentTest = isCurrentPageUrlTest(pathname, urlTests, mp);
    if (currentTest) {
      const { name, phase } = currentTest;
      const assignment = dispatch(triggerAssignment(name));
      const { index: assignmentGroup } = assignment;

      const { ['session-id']: sessionId, zfc } = cookies;

      const urlTestData = {
        [name]: assignmentGroup,
        phase,
        ['session-id']: sessionId,
        zfc
      };

      const data = stringify(urlTestData);
      logger(`URL Test Assignment ${data}`);

      // check if `assignmentGroup` is defined
      if (typeof assignmentGroup !== 'undefined') {
        const { url } = currentTest.variants?.[assignmentGroup];
        const { pathName: testPath } = getInfoForUrlTest(url);
        // hard route if attempting to client route, otherwise do a standard server side redirect
        ExecutionEnvironment.canUseDOM ? push(testPath) : dispatch(redirectTo(testPath));
        return testPath;
      }
    }
  };
}
