import type { DevicePropsState } from 'store/ducks/deviceProps/types';

export const SET_RESIZE = 'layout/SET_RESIZE';
export const SET_TOUCH_DETECTED = 'layout/TOUCH_DETECTED';
export const SET_LAYOUT = 'layout/SET_LAYOUT';
export const SET_HF_LAYOUT = 'layout/SET_HF_LAYOUT';

export const setViewportSize = (payload: { viewportWidth: number; viewportHeight: number; screenWidth: number; screenHeight: number }) => ({
  type: SET_RESIZE,
  payload
});

export const setLayout = (layout: DevicePropsState['layout']) => ({
  type: SET_LAYOUT,
  payload: layout
});

export const setHfLayout = (layout: DevicePropsState['hfLayout']) => ({
  type: SET_LAYOUT,
  payload: layout
});

export const setTouchDetected = (touchDetected: boolean) => ({
  type: SET_LAYOUT,
  payload: { touchDetected }
});
