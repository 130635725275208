import type { Store } from 'redux';

import { LOAD_ZFC_SEO_DATA } from 'constants/reduxActions';

export function loadZfcSeoData(doc: Document) {
  const serverRenderedTitle = doc.title;
  const serverRenderedCanonical = doc.querySelector('link[rel="canonical"]');
  const serverRenderedDescription = doc.querySelector('meta[name="description"]');
  return {
    type: LOAD_ZFC_SEO_DATA,
    title: serverRenderedTitle,
    canonical: serverRenderedCanonical ? serverRenderedCanonical.getAttribute('href') : null,
    description: serverRenderedDescription ? serverRenderedDescription.getAttribute('content') : null
  };
}

export function injectZfcSeoMetadata(store: Store) {
  store.dispatch(loadZfcSeoData(document));
}
