import React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { AppState } from 'types/app';
import type { CSDropdown, CSSubNavMenu } from 'types/navigation';
import { selectCustomerServiceMenu, selectIsMobile, selectOpenedNav } from 'selectors/headerFooter';
import { getIsLiveChatEnabled } from 'selectors/navigation';
import { handleSubNavClick, handleSubNavClose, handleTopNavClick, handleTopNavCloseClick } from 'actions/headerfooterNav';
import useMartyContext from 'hooks/useMartyContext';
import { NavMenu } from 'components/hf/zappos/HeaderNav';

interface CustomerServiceMenuProps {
  isMobile: boolean;
  openedNav: string;
  openedSubNav: string;
  handleTopNavClick: (e: Event) => void;
  handleTopNavCloseClick: (e: Event) => void;
  customerServiceDropdown: CSDropdown;
  isLiveChatEnabled: boolean;
  hideCSMenuMobile: boolean; // keeping CS banner hidden for mobile view
  navsThatHaveBeenOpened: string[];
}

const CustomerServiceMenu = ({
  isMobile,
  openedNav,
  openedSubNav,
  handleTopNavClick,
  handleTopNavCloseClick,
  customerServiceDropdown,
  isLiveChatEnabled,
  hideCSMenuMobile,
  navsThatHaveBeenOpened = []
}: Props) => {
  const { testId } = useMartyContext();
  const {
    componentName,
    heading: { text, link },
    subNavMenu
  } = customerServiceDropdown;
  const topNavId = 'navCustomerService';
  const subNav: CSSubNavMenu = {};

  const isOpen = (id: string) => id === openedNav;
  const isSubNavOpen = (id: string) => id === openedSubNav;

  subNavMenu.forEach((menuItem, index) => {
    const isLiveChat = menuItem.text === 'Live Chat';

    if (isLiveChat && !isLiveChatEnabled) {
      return;
    }

    subNav[`submenu-${index + 1}`] = {
      componentName,
      heading: { ...menuItem },
      subNavMenu: []
    };
  });

  const subNavProps = {
    isSubNavOpen,
    isMobile,
    parentText: text,
    parentId: topNavId,
    handleSubNavClick,
    handleSubNavClose,
    navsThatHaveBeenOpened,
    testId,
    isLiveChatEnabled
  };

  return (
    <NavMenu
      key={topNavId}
      hasDropDown={true}
      handleTopNavClick={handleTopNavClick}
      handleTopNavCloseClick={handleTopNavCloseClick}
      isOpen={isOpen}
      link={link}
      parentId={topNavId}
      subNav={subNav}
      subNavProps={subNavProps}
      text={text}
      hideCSMenuMobile={hideCSMenuMobile}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  customerServiceDropdown: selectCustomerServiceMenu(state),
  isLiveChatEnabled: getIsLiveChatEnabled(state),
  isMobile: selectIsMobile(state),
  openedNav: selectOpenedNav(state)
});

const mapDispatchToProps = { handleTopNavClick, handleTopNavCloseClick, handleSubNavClick, handleSubNavClose };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = CustomerServiceMenuProps & PropsFromRedux;

export default connector(CustomerServiceMenu);
