import type { ProductWithRelations } from 'types/calypso';

export interface ProductHeader {
  assetId: string;
  alt: string;
  header: string;
  src: string;
  selected: boolean;
  linkedAsin: string;
  productId: string;
  styleId: string;
  productWithRelations: ProductWithRelations;
}

export interface ProductComparisonModule {
  header: ProductHeader[];
  row: ProductFeature[];
  rowAdditionalInfo: ProductFeatureInfo;
}

export interface ProductFeature {
  label: string;
  value: ProductFeatureValue;
}

export interface ProductFeatureValue {
  [key: string]: string;
}

export interface ProductFeatureInfo {
  [key: string]: {
    displayName?: string;
    additionalInfo?: string;
  };
}

export interface ProductComparisonTableType {
  asin: string;
  comparisonModule: ProductComparisonModule;
}

export enum ProductComparisonAPIResponse {
  UNKNOWN,
  FETCHING,
  FETCHED,
  FAILED
}
