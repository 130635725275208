// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeShoppingCartMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M22.6458 13.9375V9.12496C22.6458 5.45457 19.6704 2.47913 16 2.47913C12.3296 2.47913 9.35417 5.45457 9.35417 9.12496V13.9375M23.3333 27.2291H27.9167M25.625 24.9375V29.5208M28 20.2424V9.33329H4V29.3333H18.2041"
      stroke="currentcolor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default UtilityStrokeShoppingCartMediumIcon;
