import { forwardRef } from 'react';

import { cn } from 'helpers/classnames';
import { baseClasses, selectedClasses, sizeClasses, variantClasses } from 'tailwind/components/IconButton/IconButton.classes';
import type { IconButtonLinkProps, IconButtonSharedProps } from 'tailwind/components/IconButton/IconButton.types';
import type IconButtonProps from 'tailwind/components/IconButton/IconButton.types';

const useIconButtonProps = (props: IconButtonSharedProps) => {
  const { className, disabled, label, selected, size, variant } = props;

  return {
    'aria-label': label,
    'className': cn(
      className,
      baseClasses,
      sizeClasses[size],
      variantClasses[variant][disabled ? 'disabled' : 'enabled'],
      selected && selectedClasses[variant]
    )
  };
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props: IconButtonProps, ref) => {
  const { type = 'button', ...forwardProps } = props;
  const iconButtonProps = useIconButtonProps(props);

  // Values of type are specified via HTMLButtonElement types
  // eslint-disable-next-line react/button-has-type
  return <button {...forwardProps} {...iconButtonProps} ref={ref} type={type} />;
});

export const IconButtonLink = forwardRef<HTMLAnchorElement, IconButtonLinkProps>((props: IconButtonLinkProps, ref) => {
  // <a> elements don’t have a disabled attribute. When the disabled prop is truthy, remove the href and provide the correct ARIA attributes
  const { disabled, href, ...forwardProps } = props;
  const linkDisabledProps = disabled ? { 'aria-disabled': true, 'role': 'link' } : { href };
  const iconButtonProps = useIconButtonProps(props);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a {...forwardProps} {...iconButtonProps} {...linkDisabledProps} ref={ref} />
  );
});

export default IconButton;
