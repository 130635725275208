// Mobile
export const isMobileViewport = (width: number) => width <= 650;
export const isNonMobileViewport = (width: number) => width > 650;

// Tiny Screen
export const isTinyScreenViewport = (width: number) => width < 414;

// Desktop
export const isDesktopViewport = (width: number) => width > 1024;

// Tablet
export const isTabletOnlyViewport = (width: number) => width > 650 && width <= 1024;
export const isTabletViewport = (width: number) => width <= 1024;
export const isTabletPortraitViewport = (width: number) => width <= 768;
export const isNonTabletPortraitViewport = (width: number) => width > 768;

export const getViewportAsString = (width: number) => (isMobileViewport(width) ? 'mobile' : isDesktopViewport(width) ? 'desktop' : 'tablet');
