import { all } from 'redux-saga/effects';

export function createRootSaga(sagas: Function[]) {
  return function* () {
    yield all(loadSagas(sagas));
  };
}

function loadSagas(sagas: Function[]) {
  return sagas.map(sagaFn => sagaFn());
}
