import React from 'react';
import Icon from 'components/icons/Icon';

const Recycled = props => (
  <Icon
    width={45}
    height={42}
    title="Recycled"
    description="Recycling Symbol"
    {...props}>
    <defs>
      <circle id="prefix__a" cx={19.625} cy={19.5} r={19.5} />
    </defs>
    <g transform="translate(2 1)" fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <use fill="#FCF0CD" xlinkHref="#prefix__a" />
      <g mask="url(#prefix__b)">
        <path
          d="M9.355 28.097l4.094-7.109a.75.75 0 00-.287-1.03l-4.446-2.46a.75.75 0 00-1.009.274l-2.044 3.46a.75.75 0 00.004.77l3.688 6.095z"
          fill="#075031"
        />
        <path
          d="M18.204 22.95a.75.75 0 01.549.254l4.05 4.59a.75.75 0 01-.018 1.012l-4.208 4.446a.75.75 0 01-1.294-.529l.021-1.24H11.67a.75.75 0 01-.649-.374l-3.582-6.19h9.98l.022-1.232a.75.75 0 01.763-.736z"
          fill="#52AA85"
        />
        <path
          d="M17.9 6.132l4.108 7.084a.75.75 0 001.035.267l4.34-2.608a.75.75 0 00.267-1.012l-1.972-3.49a.75.75 0 00-.667-.381l-7.11.14z"
          fill="#075031"
        />
        <path
          d="M17.905 16.428a.75.75 0 01-.495.35l-5.982 1.203a.75.75 0 01-.866-.521l-1.746-5.846a.75.75 0 011.105-.858l1.058.636 2.81-4.869a.75.75 0 01.64-.375l7.114-.098-4.945 8.718 1.05.631a.75.75 0 01.257 1.03z"
          fill="#52AA85"
        />
        <path
          d="M32.269 24.827l-8.187.158a.75.75 0 00-.735.776l.176 5.06a.75.75 0 00.756.724l4.009-.032a.75.75 0 00.657-.4l3.324-6.286z"
          fill="#075031"
        />
        <path
          d="M23.262 19.839a.75.75 0 01-.066-.602l1.848-5.815a.75.75 0 01.875-.506l5.96 1.308a.75.75 0 01.214 1.382l-1.069.617 2.896 4.818a.75.75 0 01.017.742l-3.423 6.351-5.168-8.633-1.06.612a.75.75 0 01-1.024-.274z"
          fill="#52AA85"
        />
      </g>
    </g>
  </Icon>
);

export default Recycled;
