import type { MouseEvent } from 'react';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { track } from 'apis/amethyst';
import { evTopLevelNavigationClick } from 'events/headerFooter';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { selectHolmes } from 'selectors/holmes';
import { setFederatedLoginModalVisibility, toggleMobileHeaderExpand } from 'actions/headerfooter';
import useMartyContext from 'hooks/useMartyContext';
import { selectIsMobileHeaderExpanded, selectIsRemoteHF } from 'selectors/headerFooter';
import { selectIsMockApi } from 'selectors/environment';
import { IS_LOGIN_PAGE } from 'common/regex';
import { selectLocation } from 'selectors/router';
import HeaderIconLink from 'components/hf/zappos/HeaderIconLink';
import UtilityDottedUserMediumIcon from 'tailwind/components/Icons/UtilityDottedUserMediumIcon';
import { selectIsCustomer } from 'selectors/cookies';

interface AccountIconProps {
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  onPointerDown?: (e: MouseEvent<HTMLAnchorElement>) => void;
  headerAccountTestId?: string;
  headerSignInTestId?: string;
}

const AccountIcon = forwardRef<HTMLAnchorElement, AccountIconProps>((props, forwardedRef) => {
  const {
    marketplace: { dataMaskPiiElements, hasFederatedLogin },
    testId
  } = useMartyContext();
  const holmes = useSelector(selectHolmes);
  const isCustomer = useSelector(selectIsCustomer);
  const isMobileHeaderExpanded = useSelector(selectIsMobileHeaderExpanded);
  const isMockApi = useSelector(selectIsMockApi);
  const isRemoteHF = useSelector(selectIsRemoteHF);
  const location = useSelector(selectLocation);
  const { headerAccountTestId = 'headerAccount', headerSignInTestId = 'headerSignIn', ...iconLinkProps } = props;

  const dispatch = useDispatch();

  const onAccountIconClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (isMobileHeaderExpanded && !props.onClick) {
      dispatch(toggleMobileHeaderExpand());
    }

    props.onClick ? props.onClick(e) : props.onPointerDown?.(e);
  };

  const handleSignIn = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (hasFederatedLogin && !isMockApi) {
      const { pathname, search } = location;
      const returnTo = encodeURIComponent(`${pathname}${search}`);
      dispatch(toggleMobileHeaderExpand());
      // allow time for menu animation
      setTimeout(() => {
        dispatch(setFederatedLoginModalVisibility(true, { returnTo }));
      }, 250);
    } else {
      trackEvent('TE_HEADER_ACCOUNT_SIGNIN');
      trackLegacyEvent('Main-Nav', 'SignIn', 'LoginRegister');
      track(() => [
        evTopLevelNavigationClick,
        {
          valueClicked: 'Sign In'
        }
      ]);

      // Use `window` first because remote HF doesn't have its router synced up when remote.
      const { pathname, search } = window.location;
      const link =
        !pathname.match(IS_LOGIN_PAGE) && !isRemoteHF ? `/zap/preAuth/signin?openid.return_to=${pathname + encodeURIComponent(search)}` : '/login';
      if (e.shiftKey || e.ctrlKey || e.metaKey) {
        window.open(link, '_blank');
      } else {
        window.location.href = link;
      }
    }
  };

  if (isCustomer) {
    return (
      <HeaderIconLink
        {...iconLinkProps}
        ref={forwardedRef}
        href={'/account'}
        data-cs-mask={dataMaskPiiElements}
        data-test-id={testId(headerAccountTestId)}
        label="My Account"
        text={holmes?.firstName ? decodeURIComponent(holmes.firstName) : ''}
        onClick={onAccountIconClick}
      >
        <UtilityDottedUserMediumIcon data-shyguy="navAccountMenu" size={32} />
      </HeaderIconLink>
    );
  }

  return (
    <HeaderIconLink
      {...iconLinkProps}
      ref={forwardedRef}
      href={'/login'}
      data-test-id={testId(headerSignInTestId)}
      label="Sign In"
      onClick={handleSignIn}
    >
      <UtilityDottedUserMediumIcon size={32} />
    </HeaderIconLink>
  );
});

export default AccountIcon;
