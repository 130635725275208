/**
 * This is a stub of the portion of react-children-utilities package we use since their library is in ES format which our babel config does not like
 */
import type { ReactElement, ReactNode } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

const hasChildren = (element: ReactNode): element is ReactElement<{ children: ReactNode | ReactNode[] }> =>
  isValidElement<{ children?: ReactNode[] }>(element) && Boolean(element.props.children);

const hasComplexChildren = (element: ReactNode): element is ReactElement<{ children: ReactNode | ReactNode[] }> =>
  isValidElement(element) &&
  hasChildren(element) &&
  Children.toArray(element.props.children).reduce((response: boolean, child: ReactNode): boolean => response || isValidElement(child), false);

export const deepMap = (
  children: ReactNode | ReactNode[],
  deepMapFn: (child: ReactNode, index?: number, children?: ReactNode[]) => ReactNode
): ReactNode[] =>
  Children.toArray(children).map((child: ReactNode, index: number, mapChildren: ReactNode[]) => {
    if (isValidElement(child) && hasComplexChildren(child)) {
      // Clone the child that has children and map them too
      return deepMapFn(
        cloneElement(child, {
          ...child.props,
          children: deepMap(child.props.children, deepMapFn)
        })
      );
    }
    return deepMapFn(child, index, mapChildren);
  });
