import { CLEAR_SAVED_FILTERS, TOGGLE_FACET_GROUP_SHOW_MORE, TOGGLE_SAVED_FILTERS, UPDATE_SAVED_FILTERS } from 'constants/reduxActions';
import {
  NAVIGATION_FACET_SEARCH,
  SEARCH_FACET_CLICK,
  SEARCH_PAGE_CLICKTHROUGH,
  SEARCH_PAGE_VIEW,
  SEARCH_PILL_GROUP_TOGGLE,
  SEARCH_PRODUCT_INTERACTION,
  SEARCH_SHOW_MORE_TOGGLE,
  SEARCH_SORT_CLICK,
  SEARCH_SWATCH_IMPRESSION_WRAPPER,
  SEARCH_SWATCH_INTERACTION,
  TOP_BANNER_AD_CLICK,
  TOP_BANNER_AD_IMPRESSION
} from 'constants/amethyst';
import COLOR_SWATCH_MAP from 'constants/colorSwatchMap';
import { trackEvent } from 'helpers/analytics';
import { middlewareTrack, titaniteView } from 'apis/amethyst';
import marketplace from 'cfg/marketplace.json';
import { getBadgeId } from 'helpers/BadgeUtils';

const mapSortType = value => {
  switch (value) {
    case 'bestForYou/desc':
      return 'BEST_FOR_YOU';
    case 'relevance/desc':
      return 'RELEVANCE';
    case 'isNew/desc/goLiveDate/desc/recentSalesStyle/desc':
      return 'NEW_ARRIVALS';
    case 'productRating/desc':
      return 'CUSTOMER_RATING';
    case 'recentSalesStyle/desc':
      return 'BEST_SELLERS';
    case 'price/asc':
      return 'PRICE_LOW_TO_HIGH';
    case 'price/desc':
      return 'PRICE_HIGH_TO_LOW';
    case 'percentOff/desc':
      return 'PERCENT_OFF';
    case 'onSale/desc':
      return 'ON_SALE';
    case 'trending/desc':
      return 'TRENDING';
    default:
      return 'UNKNOWN_SORT';
  }
};

/**
 * @param {string} [color] - Color name in COLOR_SWATCH_MAP format
 * @param {string} [swatchUrl] - Swatch image href
 */
export const nameSwatchType = (color, swatchUrl) => {
  if (swatchUrl) {
    return swatchUrl.includes('fabric') ? 'FABRIC_SWATCH' : 'BASE_SWATCH';
  }

  if (COLOR_SWATCH_MAP[color?.toLowerCase()]) {
    return 'COLOR_SWATCH';
  }

  return 'MISSING_SWATCH';
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SearchPageView.proto
 *
 * Note: reco impressions are sent separately with the recommendationWrapper
 *
 * @param {integer} products - search result products
 * @param {string} filters - search result filters
 * @param {object} facets - search result filters
 */
const pvSearch = ({ products: { totalProductCount, list, trustedRetailers }, filters, facets }) => {
  titaniteView();

  const navFacets = [];
  const autoFacets = [];
  const filterableNavigationFacets = [];
  const { autoCompleteMinValues, usesFacetNavData } = marketplace.search;
  const { originalTerm, selected, sort, page, autocorrect } = filters;
  const { navigation, toDisplay } = facets;
  const { singleSelects, multiSelects } = selected;

  const addSelectedFacets = selects => {
    Object.keys(selects).forEach(attribute => {
      selects[attribute].forEach(value => {
        autoFacets.push({ attribute, value });
      });
    });
  };

  if (singleSelects) {
    addSelectedFacets(singleSelects);
  }
  if (multiSelects) {
    addSelectedFacets(multiSelects);
  }

  let currentSort = 'relevance/desc';
  const sortKeys = Object.keys(sort);
  if (sortKeys.length > 0) {
    currentSort = Object.keys(sort)
      .map(v => `${v}/${sort[v]}`)
      .join('/');
  }

  const productList = [...list, ...trustedRetailers];

  const productsImpressed = productList.map((p, i) => {
    const { productId, styleId, color, colorId, badges, relatedStyles = [], swatchUrl } = p;
    const swatchCount = relatedStyles.length;

    const productIdentifiers = {
      productId,
      styleId,
      colorId,
      supplementalData: {
        swatchCount,
        badgeId: getBadgeId(badges)
      }
    };

    const piObj = {
      pageNumber: page + 1,
      pageResultNumber: i + 1,
      productIdentifiers
    };

    if (swatchCount) {
      piObj.productIdentifiers.supplementalData.swatchType = nameSwatchType(color, swatchUrl);
    }

    return piObj;
  });

  for (const navigationKey in navigation) {
    const currentNav = navigation[navigationKey];

    if (currentNav.length > 0) {
      currentNav.forEach(({ facetField, facetFieldDisplayName, values }) => {
        values.forEach(({ count, name, selected }) => {
          navFacets.push({
            pageType: 'SEARCH',
            navigationKey,
            attribute: facetField,
            attributeDisplayName: facetFieldDisplayName,
            value: name,
            count,
            selected
          });
        });
      });
    }
  }

  if (usesFacetNavData) {
    Object.keys(navigation).forEach(section => {
      navigation[section].forEach(facetGroup => {
        if (facetGroup && section !== 'sizing' && facetGroup.values.length > autoCompleteMinValues) {
          filterableNavigationFacets.push(facetGroup.facetField);
        }
      });
    });
  } else if (toDisplay?.length) {
    toDisplay.forEach(facetGroup => {
      if (facetGroup.values?.length > autoCompleteMinValues) {
        filterableNavigationFacets.push(facetGroup.facetField);
      }
    });
  }

  const defaultSort = mapSortType(currentSort);
  const spvObj = {
    autoFacets,
    itemsFound: totalProductCount,
    searchTerm: originalTerm,
    defaultSort,
    productsImpressed,
    navFacets,
    filterableNavigationFacets
  };

  if (autocorrect?.termBeforeAutocorrect) {
    spvObj.autocorrectTerm = autocorrect.termBeforeAutocorrect;
  }

  middlewareTrack({
    [SEARCH_PAGE_VIEW]: spvObj
  });
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/FacetClick.proto
 *
 * @param {string} facetGroup - Attribute of facet
 * @param {string} facetName - Name of facet
 * @param {object} crumb - Clean crumb object containing the facet name & value in the object
 * @param {object} breadcrumbRemove - A little dirty object for the name & value of the facet. Need
 * to clean the removeName so it sends a sensible attribute property.
 * @param {bool} selected - true if selected
 * @param {bool} deselected - true if deselected
 * @param {bool} autoFacted - true if ML powered
 */
export const evFacetClick = ({ facetGroup, facetName, crumb, breadcrumbRemove, facetClickSource, selected, deselected }) => {
  let attribute, value;

  if (facetGroup) {
    attribute = facetGroup;
  } else if (crumb) {
    attribute = crumb.name;
  } else if (breadcrumbRemove) {
    // sometimes we get something like: 'Remove brandFacet: Ugg' and we need it to be 'brandFacet'
    attribute = breadcrumbRemove.removeName.replace(/:.*|Remove /g, '');
  }

  if (facetName) {
    value = facetName;
  } else if (crumb) {
    ({ value } = crumb);
  } else if (breadcrumbRemove) {
    value = breadcrumbRemove.name;
  }

  return {
    [SEARCH_FACET_CLICK]: {
      facetClick: {
        attribute,
        value
      },
      selected,
      deselected,
      facetClickSource
    }
  };
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/FacetAttributeDropdownClick.proto
 *
 * @param {string} facetGroup - Attribute of facet
 * @param {string} facetClickSource - Facet click source
 * @param {bool} collapse - true if not open
 */
export const evGroupedPillToggle = ({ facetGroup, facetClickSource, collapse }) => ({
  [SEARCH_PILL_GROUP_TOGGLE]: {
    attributeName: facetGroup,
    facetClickSource,
    collapse
  }
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/FacetAttributeShowMoreClick.proto
 *
 * @param {string} facetClickSource - Source of click
 * @param {bool} collapse - true if not open
 */
export const evShowMoreToggle = ({ facets }, { section, selectedFacetIndex }) => {
  const facetClickSource = 'FACET_SHOW_MORE';
  const hasShowMore = facets.navigation[section][selectedFacetIndex]?.showMore;
  middlewareTrack({
    [SEARCH_SHOW_MORE_TOGGLE]: {
      facetClickSource,
      collapse: !hasShowMore
    }
  });
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/NavigationFacetSearch.proto
 *
 * @param {object} autoComplete - Autocomplete object from Redux state
 * @param {object} facetGroup - Interacted facet group object
 * @param {string} term - Search query text
 */
export const evFacetSearch = ({ autoComplete, facetGroup, term }) => {
  const displayedValues = new Set(autoComplete[facetGroup.facetField].values.map(({ name }) => name));

  return {
    [NAVIGATION_FACET_SEARCH]: {
      attributeName: facetGroup.facetField,
      term,
      facetNames: facetGroup.values
        .filter(({ name }) => displayedValues.has(name))
        .map(({ count, name }) => ({
          count,
          value: name
        }))
    }
  };
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SortSearchClick.proto
 *
 * @param {string} sortType - See WebsiteEnums.proto file for potential values
 * Sort Map used to find value based off of values in WebsiteEnums file
 */
const SortTypeMap = {
  'unknown': 'UNKNOWN_SORT',
  'relevance': 'RELEVANCE',
  'new arrivals': 'NEW_ARRIVALS',
  'customer rating': 'CUSTOMER_RATING',
  'best sellers': 'BEST_SELLERS',
  'percent off': 'PERCENT_OFF',
  'price: low to high': 'PRICE_LOW_TO_HIGH',
  'price: high to low': 'PRICE_HIGH_TO_LOW',
  'brand name': 'BRAND_SORT'
};
export const evSortSearchClick = ({ sortType }) => ({
  [SEARCH_SORT_CLICK]: {
    sortType: SortTypeMap[sortType.toLowerCase().trim()] || 'UNKNOWN_SORT'
  }
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SearchPageClickthrough.proto
 *
 * @param {integer} pageNumber - Page number, ex: 1
 * @param {integer} pageResultNumber - Position out of all results on page
 * @param {string} productIdentifiers - Keys: productId, styleId, colorId, stockId, asin
 * @param {bool} isLowStock - Clicked product is low stock or not
 */
export const evSearchPageClickthrough = ({
  pageNumber,
  pageResultNumber,
  product,
  numberOfHearts,
  swatchCount,
  swatchIndex,
  isLowStock,
  currentStyleId,
  badgeId
}) => {
  const { productId, colorId } = product;
  return (event = {
    [SEARCH_PAGE_CLICKTHROUGH]: {
      pageNumber,
      pageResultNumber,
      clickedProduct: {
        productId,
        styleId: currentStyleId,
        colorId,
        supplementalData: {
          numberOfHearts,
          swatchCount,
          swatchIndex,
          isLowStock,
          badgeId
        }
      }
    }
  });
};

const tePageView = appState => {
  const {
    filters: { term, bestForYouSortEligible, personalizedSize }
  } = appState;
  trackEvent('TE_PV_SEARCHPAGE');
  if (personalizedSize) {
    trackEvent('TE_PERSONALIZED_SIZE_AVAILABLE');
  }

  if (bestForYouSortEligible) {
    trackEvent('TE_PERSONALIZED_SEARCH_BFU_ELIGIBLE');
    if (!term) {
      trackEvent('TE_PERSONALIZED_SEARCH_BFU_ELIGIBLE_NO_TERM');
    }
  }
};

const ameSavedSizeImpression = ({ filters: { savedsizes, applySavedFilters } }) => {
  if (savedsizes && Object.keys(savedsizes.filters).length > 0) {
    trackEvent('TE_SAVED_FILTERS_VISIBLE');
    const sizes = formatSavedFilters(savedsizes);
    const noSavedSizes = sizes.length <= 0;
    middlewareTrack({
      savedSizeImpression: {
        toggle: !!applySavedFilters,
        saved_sizes: sizes,
        no_saved_sizes: noSavedSizes
      }
    });
  }
};

/**
 * Fires the search page view events
 * @param {*} appState redux state
 */
export const evSearchPageView = appState => {
  pvSearch(appState);
  tePageView(appState);
  ameSavedSizeImpression(appState);
};

const formatSavedFilters = savedsizes => {
  const sizes = [];
  for (const filter of Object.keys(savedsizes.filters)) {
    savedsizes.filters[filter].map(v => sizes.push({ attribute: filter, value: v }));
  }
  return sizes;
};

const ameSavedFilterChange = ({ filters: { savedsizes } }, { oldFilters }) => {
  const deletedFilters = Object.keys(savedsizes.filters).some(f => oldFilters[f].length && !savedsizes.filters[f].length);
  let eventKey = 'saveSizeClick';
  const payload = {};

  if (deletedFilters) {
    eventKey = 'resetSavedSizeClick';
  }

  payload[eventKey] = { saved_sizes: formatSavedFilters(savedsizes) };

  middlewareTrack(payload);
};

const ameSavedFilterDelete = () => {
  middlewareTrack({
    resetSavedSizeClick: {
      saved_sizes: []
    }
  });
};

const ameSavedFilterToggle = ({ filters: { applySavedFilters } }) => {
  const toggleOff = !applySavedFilters;
  const toggleOn = !toggleOff;

  middlewareTrack({
    savedSizeToggle: {
      toggle_on: toggleOn,
      toggle_off: toggleOff
    }
  });
};

export const evSearchAdClick = ({ slotLocation, searchTerm, advertisementType, endpoint }) => {
  const adDetails = {
    keyword: searchTerm,
    adLocation: slotLocation,
    endpoint
  };

  return {
    [TOP_BANNER_AD_CLICK]: {
      pageType: 'SEARCH_PAGE',
      advertisementType,
      adDetails
    }
  };
};

export const evSearchAdView = ({ slotLocation, searchTerm, advertisementType, endpoint }) => {
  const adDetails = {
    keyword: searchTerm,
    adLocation: slotLocation,
    endpoint
  };

  return {
    [TOP_BANNER_AD_IMPRESSION]: {
      advertisementImpression: [
        {
          pageType: 'SEARCH_PAGE',
          advertisementType,
          adDetails
        }
      ]
    }
  };
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SearchProductInteraction.proto
 *
 * @param {string} mainStyleId - Main product style id (first style displayed on the initial page load)
 * @param {object} interactedProduct - Interacted product (clicked or hovered)
 * @param {string} interactionType - How the prodct was interacted ("HOVERED")
 */
export const evProductInteract = ({ mainStyleId, interactedProduct, interactionType }) => {
  const { productId, styleId, colorId } = interactedProduct;

  return {
    [SEARCH_PRODUCT_INTERACTION]: {
      mainStyleId,
      interactedProduct: {
        productId,
        styleId,
        colorId: colorId
      },
      interactionType
    }
  };
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SwatchInteraction.proto
 *
 * @param {string} mainStyleId - Main swatch product style id (first style displayed on the initial page load)
 * @param {object} interactedProduct - Interacted swatch product (clicked or hovered)
 * @param {integer} interactedSwatchIndex - Interacted swatch index
 * @param {string} interactionType - How the prodct was interacted ("HOVERED")
 */
export const evSwatchInteract = ({ mainStyleId, interactedProduct, interactedSwatchIndex, interactionType }) => {
  const { productId, styleId, color, colorId, swatchUrl } = interactedProduct;

  return {
    [SEARCH_SWATCH_INTERACTION]: {
      mainStyleId,
      interactedSwatch: {
        productId,
        styleId,
        colorId: colorId,
        supplementalData: {
          swatchType: nameSwatchType(color, swatchUrl),
          swatchIndex: interactedSwatchIndex
        }
      },
      interactionType
    }
  };
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SwatchImpressionWrapper.proto
 *
 * @param {array} swatchImpressions  - array of swatch impression events to send together
 **/

export const evSwatchImpressionWrapper = swatchImpressions => ({
  [SEARCH_SWATCH_IMPRESSION_WRAPPER]: {
    swatchImpressions
  }
});

/**
 * @param {*} originalStyleId The style ID for the style returned by the API
 * @param {*} swappedStyleId The style ID for the swapped lowest price style
 * @param {*} productIdentifiers The impressed products before swap occurred
 * @returns Object representing the Amethyst event
 */

export default {
  pageEvent: SEARCH_PAGE_VIEW,
  events: {
    [UPDATE_SAVED_FILTERS]: [ameSavedFilterChange],
    [CLEAR_SAVED_FILTERS]: [ameSavedFilterDelete],
    [TOGGLE_SAVED_FILTERS]: [ameSavedFilterToggle],
    [TOGGLE_FACET_GROUP_SHOW_MORE]: [evShowMoreToggle]
  }
};
