import { SET_KILLSWITCHES } from 'store/ducks/killswitch/types';

const killswitch = (state = {}, action) => {
  switch (action.type) {
    case SET_KILLSWITCHES:
      const {
        payload: { airplaneAllowList = [], ...rest }
      } = action;
      const nextState = {
        ...state,
        ...rest,
        airplaneAllowList
      };
      return nextState;
    default:
      return state;
  }
};

export default killswitch;
