export const API_ERROR_UNKNOWN = 'UNKNOWN_API_ERROR_TYPE';
export const API_ERROR_NOT_AUTHORIZED = 'NOT_AUTHORIZED';
export const API_ERROR_PURCHASE_NOT_FOUND = 'PURCHASE_NOT_FOUND';
export const API_ERROR_EMPTY_CART = 'EMPTY_CART';
export const API_ERROR_REQUEST_VALIDATION = 'REQUEST_VALIDATION';
export const API_ERROR_REDEEMABLE_REWARDS_NOT_FOUND = 'REDEEMABLE_REWARDS_NOT_FOUND';
export const API_ERROR_CANNOT_CONFIRM_PURCHASE_OOS = 'CANNOT_CONFIRM_PURCHASE_OOS';
export const API_ERROR_CANNOT_CONFIRM_PURCHASE_OTHER = 'CANNOT_CONFIRM_PURCHASE_OTHER';
export const API_ERROR_QUANTITY_CHANGE_VALIDATION = 'QUANTITY_CHANGE_VALIDATION';
export const API_ERROR_INVALID_GIFT_OPTIONS = 'INVALID_GIFT_OPTIONS';
export const API_ERROR_EDIT_INACTIVE_ADDRESS = 'EDIT_INACTIVE_ADDRESS';
export const API_ERROR_CANNOT_UPDATE_VIP_PREFERENCE = 'ERROR_CANNOT_UPDATE_VIP_PREFERENCE';
