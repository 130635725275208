import realAbReducer from 'react-redux-hydra/lib/reducer';

import { logError } from 'middleware/logger';
import { SERIALIZE_STATE } from 'constants/reduxActions';

const RECEIVE_TEST_ASSIGNMENT_TYPE = '@@hydra/RECEIVE_TEST_ASSIGNMENT';

const addAbReducer = reducersObj => {
  const abReducer = (state = { tests: [], assignments: {}, queue: [] }, action) => {
    if (action.type === SERIALIZE_STATE && action.cached) {
      // clear assignments and queue so that we can cache the page :(
      return Object.assign({}, state, { assignments: {}, queue: [] });
    }

    if (action?.type === RECEIVE_TEST_ASSIGNMENT_TYPE && !action?.assignment) {
      logError(`There was an issue with an ab test, assignment was null. This usually happens when an ab test's assignments do not add up to 100%`);
      return state;
    }

    // dispatch to the real ab reducer from rrh.
    return realAbReducer(state, action);
  };

  return Object.assign({}, reducersObj, { ab: abReducer });
};

export default addAbReducer;
