import React, { useState } from 'react';

import { cn } from 'helpers/classnames';
import Link from 'components/hf/HFLink';
import { trackEvent } from 'helpers/analytics';
import { track } from 'apis/amethyst';
import { evNavigationClick, evTopLevelNavigationClick } from 'events/headerFooter';
import type { ZawNavItem } from 'containers/hf/zappos/ZAWHeader';

import css from 'styles/components/hf/zappos/ZAWNav.scss';

const DEFAULT_NAV = [
  {
    title: 'FAQs',
    link: 'https://www.zappos.com/c/zaw-business-faq',
    subNav: []
  },
  {
    title: 'Workwear Blog',
    link: 'https://www.zappos.com/c/zaw-blog-home',
    subNav: [
      {
        title: 'Safety Footwear Trends',
        link: 'https://www.zappos.com/c/zaw-safety-footwear-trends'
      },
      {
        title: 'Workplace Hazards',
        link: 'https://www.zappos.com/c/zaw-workplace-hazards'
      },
      {
        title: 'Occupational Safety',
        link: 'https://www.zappos.com/c/zaw-occupational-safety-and-health'
      }
    ]
  }
];

export const ZAWNav = ({ isAlternateStyling = false, navData = DEFAULT_NAV }) => {
  const [currentlyOpen, setCurrentlyOpen] = useState('');
  return (
    <ul className={css.navContainer}>
      {navData.map((topLevelNavLink, index: number) => {
        const { title: topLevelTitle, link: topLevelLink } = topLevelNavLink;
        const hasDropDown = topLevelNavLink.subNav.length > 0;
        const isCurrentlyOpen = currentlyOpen === topLevelTitle;
        const handleTopNavClick = (e: React.MouseEvent) => {
          if (hasDropDown) {
            e.preventDefault();
            if (currentlyOpen === topLevelTitle) {
              setCurrentlyOpen('');
            } else {
              setCurrentlyOpen(topLevelTitle);
            }
          }

          trackEvent('TE_HEADERFOOTER_MAIN_NAV', topLevelTitle);
          track(() => [
            evTopLevelNavigationClick,
            {
              valueClicked: topLevelTitle
            }
          ]);
        };

        return (
          <li key={`${topLevelTitle}-${index}`} className={css.topLevelNavItem} onMouseEnter={handleTopNavClick} onMouseLeave={handleTopNavClick}>
            <Link
              className={cn(css.topNav, { [css.noArrow]: !hasDropDown, [css.alternative]: isAlternateStyling })}
              aria-expanded={isCurrentlyOpen}
              to={topLevelLink}
            >
              {topLevelNavLink.title}
            </Link>

            {hasDropDown && (
              <div className={css.category}>
                <h3 className={css.subcategoryHeading}>Top Blog Posts</h3>
                <ul className={css.subcategory}>
                  {topLevelNavLink.subNav.map((subNavLink: ZawNavItem, index: number) => (
                    <li key={`${subNavLink.title}-${index}`}>
                      <Link
                        onClick={() => {
                          trackEvent('TE_HEADERFOOTER_MAIN_NAV', subNavLink.title);
                          track(() => [
                            evNavigationClick,
                            {
                              valueClicked: subNavLink.title,
                              parentDropdown: topLevelTitle
                            }
                          ]);
                        }}
                        to={subNavLink.link}
                      >
                        {subNavLink.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};
