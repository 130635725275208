import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ProductBadge, type ProductBadgeProps } from '@mweb/zappos-ui/ProductBadge';

import { cn } from 'helpers/classnames';
import type { BadgeStyles } from 'types/badges';
import useWindowSize from 'hooks/useWindowSize';
import type { AppState } from 'types/app';
import { selectBadgeMetadata } from 'selectors/badge';

import css from 'styles/components/common/badge.scss';

export interface BadgeProps {
  id: string;
  badgeName?: string;
  badgeStyles?: BadgeStyles[];
  category?: string;
  url?: string;
  classNameWrapper?: string;
  setLabel?: (label: string | HTMLElement) => void;
  productLink?: string;
  onBadgeCategoryClick?: () => void;
}

const Badge = ({ id, badgeName, category, badgeStyles, url, classNameWrapper, setLabel, productLink, onBadgeCategoryClick }: BadgeProps) => {
  const { styles, name, tooltip } = useSelector((state: AppState) => selectBadgeMetadata(state, id)) || {
    name: badgeName,
    styles: badgeStyles
  };

  const { width: windowWidth } = useWindowSize(500); // debounceWait=500

  useEffect(() => {
    if (setLabel) {
      const label = category ? name + ' ' + category : name;
      setLabel(label);
    }
  }, [category, setLabel, name]);

  if (!styles || !name) {
    return null;
  }

  const badgeCategory = category && (
    <p className={css.category} title={category}>
      {category}
    </p>
  );

  const badgeCategoryLink = windowWidth && windowWidth <= 650 ? productLink : url;

  const badgeProps = {
    ['aria-label']: `${name} badge. ${tooltip}`,
    background: styles[0].backgroundColor,
    children: name,
    fontColor: styles[0].foregroundColor,
    helpText: tooltip
  } satisfies ProductBadgeProps;

  return (
    <div className={cn(css.badgeWrapper, classNameWrapper)}>
      {url && badgeCategory ? (
        <a href={badgeCategoryLink || url} className={css.badgeLink} onClick={onBadgeCategoryClick}>
          <div className="mt-4">
            <ProductBadge {...badgeProps} />
          </div>
          {badgeCategory}
        </a>
      ) : (
        <a href={productLink} className="relative">
          <div className="mt-4">
            <ProductBadge {...badgeProps} />
          </div>
        </a>
      )}
    </div>
  );
};

export default Badge;
