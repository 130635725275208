// @ts-ignore
import { store } from 'entrypoints/bootstrapOnClient';
import { getAmethystPageType } from 'helpers/analytics';
import { getFirstFiveWords, isShoppableLink } from 'events/symphony';
import type { UserSearchTerm } from 'helpers/searchInputUtils';
import {
  EXPLICIT_SEARCH,
  NAVIGATION_CLICK,
  SEARCH_AUTO_COMPLETE_IMPRESSION,
  SEARCH_AUTO_COMPLETE_INTERACTION,
  SEARCH_CLEAR_FILTERS,
  SEARCH_FILTER_INTERACTION,
  SEARCH_ZERO_STATE_IMPRESSION,
  SEARCH_ZERO_STATE_INTERACTION,
  SEARCH_ZERO_STATE_TRUSTED_SITE_INTERACTION,
  STORE_SELECTOR_EVENT,
  TOP_LEVEL_NAV_CLICK
} from 'constants/amethyst';
import { guid } from 'helpers/guid';

/**
  https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SearchAutoCompleteInteraction.proto
* @param {string} termSearched
* @param {string} termSelected
* @param {int} termPosition
**/
export const evSearchAutoCompleteInteraction = ({ termSearched, termSelected, termPosition }: any) => ({
  [SEARCH_AUTO_COMPLETE_INTERACTION]: {
    termSearched,
    termSelected,
    termPosition
  }
});

/**
  https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SearchFilterInteraction.proto
* @param {string} filter
**/
export const evSearchFilterInteraction = ({ filter }: { filter: string }) => ({
  [SEARCH_FILTER_INTERACTION]: {
    filter
  }
});

/**
  https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SearchClearFilters.proto
**/
export const evSearchClearFilters = () => ({
  [SEARCH_CLEAR_FILTERS]: {}
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SearchAutoCompleteImpression.proto
 * @param {string} termSearched
 * @param {int} resultCount
 * @param {string[{result: string}]} results
 **/
export const evSearchAutoCompleteImpression = ({ termSearched, resultCount, results }: any) => ({
  [SEARCH_AUTO_COMPLETE_IMPRESSION]: {
    termSearched,
    resultCount,
    results
  }
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/TopLevelNavigationClick.proto
 *
 * @param {string} valueClicked - Event value, could be description of link or text value
 * @param {string} recommendationImpression - only send if there are recos inside the dropdown. Not applicable currently as we never do this. See evRecommendationImpression func.
 */
export const evTopLevelNavigationClick = ({ valueClicked, recommendationImpression }: any) => ({
  [TOP_LEVEL_NAV_CLICK]: {
    valueClicked,
    recommendationImpression
  }
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/NavigationClick.proto
 *
 * @param {string} valueClicked - Event value, could be description of link or text value
 * @param {string} parentDropdown - parent dropdown, ex: 'Kids', 'Mens', etc.
 * @param {bool} searchOccurred - for when search form submitted inside dropdown
 * @param {object} explicitSearch (required when searchOccurred = true) - See evExplicitSearch
 */
export const evNavigationClick = ({ isFooter, valueClicked, parentDropdown, searchOccurred, explicitSearchTerm: term }: any) => ({
  [NAVIGATION_CLICK]: {
    valueClicked,
    parentDropdown,
    searchOccurred,
    isFooter,
    ...(term ? evExplicitSearch({ term }) : {})
  }
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ExplicitSearch.proto
 *
 * @param {string} term - string searched, ex: 'nike red shoes'
 * Note: searchType is always 'TYPED'. Only other is VOICE which we don't do.
 */
export const evExplicitSearch = ({
  term,
  autosuggestionShown = false,
  autosuggestionClicked = false,
  timestamp = Date.now(),
  viewId = guid().replace(/-/g, '')
}: any) => ({
  [EXPLICIT_SEARCH]: {
    term,
    searchType: 'TYPED',
    sourcePage: getAmethystPageType(store.getState().pageView.pageType),
    autosuggestionShown,
    autosuggestionClicked
  },
  timestamp,
  viewId
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/DynamicBannerImpression.proto
 *
 * @param {object} content - This contains the text, imageUrl, and link for the banner.
 */
export const evDynamicBannerImpression = ({ content }: any) => ({
  dynamicBannerImpression: {
    content
  }
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/DynamicBannerClick.proto
 *
 * @param {object} content - This contains the text, imageUrl, and link for the banner.
 */
export const evDynamicBannerClick = ({ content }: any) => ({
  dynamicBannerClick: {
    content
  }
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/GlobalBannerImpression.proto
 *
 * @param {object} globalBanner - Object we get from Symphony.
 * @param {string} text - String of text rendered in the container.
 */

export const evGlobalBannerImpression = ({ globalBanner, text }: any) => {
  const {
    data: { gae, href }
  } = globalBanner;
  return {
    globalBannerImpression: {
      bodySnippet: getFirstFiveWords(text),
      shoppableLink: isShoppableLink(href),
      sourcePage: getAmethystPageType(store.getState().pageView.pageType),
      symphonyDetails: {
        identifier: gae
      }
    }
  };
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/BannerImpression.proto
 *
 * @param {object} slotData - Object we get from Symphony.
 * @param {string} text - String of text rendered in the container.
 * @param {string} slotName - String of slotName to which text is rendered
 */
export const evBannerImpression = ({ slotData, text, slotName, pageType }: any) => {
  const { href, linktext } = slotData;
  return {
    bannerImpression: {
      slot: slotName,
      pageType,
      messageText: text,
      hasCta: href && linktext ? true : false
    }
  };
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/GlobalBannerClick.proto
 *
 * @param {object} globalBanner - Object we get from Symphony.
 * @param {string} text - String of text rendered in the container.
 * @param {string} linkText - String of link clicked on.
 * @param {bool} shoppableLink - If the URL is shoppable(PDP or search).
 * @param {string} linkUrl - href of link.
 * @param {bool} isDismiss - If this was a button to dismiss the banner.
 */
export const evGlobalBannerClick = ({ globalBanner, text, linkText, shoppableLink, linkUrl, isDismiss }: any) => {
  const {
    data: { gae }
  } = globalBanner;
  return {
    globalBannerClick: {
      bodySnippet: getFirstFiveWords(text),
      linkText,
      linkUrl,
      shoppableLink: typeof shoppableLink === 'boolean' ? shoppableLink : isShoppableLink(linkUrl),
      isDismiss,
      sourcePage: getAmethystPageType(store.getState().pageView.pageType),
      symphonyDetails: {
        identifier: gae
      }
    }
  };
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/GlobalBannerClick.proto
 *
 * @param {number} bannerAction - PREVIOUS_MESSAGE = 0; NEXT_MESSAGE = 1; HOVER_PAUSE = 2; CTA_CLICK = 3;.
 * @param {string} linkUrl - href of link.
 * @param {string} slotName - String of slotName to which link is clicked
 * @param {string} bannerText - String of text contains by slot on which activity performed
 */
export const evBannerClick = ({ bannerAction, linkUrl, slotName, bannerText, pageType }: any) => ({
  bannerClick: {
    slot: slotName,
    pageType,
    bannerAction,
    messageText: bannerText,
    ctaUrl: linkUrl
  }
});

export const evStoreSelectorEvent = ({ storeName }: any) => ({
  [STORE_SELECTOR_EVENT]: {
    storeName
  }
});
/* https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SearchZeroStateIdentifiers.proto */
interface ZeroStateIdentifiers {
  value: UserSearchTerm;
  index: number;
  isSelected: boolean;
  isDeleted: boolean;
}

/* https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SearchZeroStateImpression.proto */
export const evSearchZeroStateImpression = (userSearchTerms: UserSearchTerm[]) => ({
  [SEARCH_ZERO_STATE_IMPRESSION]: {
    rows: userSearchTerms.map(
      (value, index): ZeroStateIdentifiers => ({
        index,
        value,
        isSelected: false,
        isDeleted: false
      })
    ),
    total: userSearchTerms.length
  }
});

/* https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SearchZeroStateInteraction.proto */
export const evSearchZeroStateInteraction = ({ value, index, isSelected = false, isDeleted = false }: ZeroStateIdentifiers) => ({
  [SEARCH_ZERO_STATE_INTERACTION]: {
    row: {
      index,
      value,
      isSelected,
      isDeleted
    }
  }
});

interface SearchZeroStateTrustedSite {
  trustedSiteUrl: string;
}

/* https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/SearchZeroStateTrustedSiteInteraction.proto */
export const evSearchZeroStateTrustedSiteInteraction = (trustedSiteUrl: SearchZeroStateTrustedSite) => ({
  [SEARCH_ZERO_STATE_TRUSTED_SITE_INTERACTION]: {
    trustedSiteUrl
  }
});
