import type { AppState } from 'types/app';
import type { HolmesState } from 'types/holmes';

export const selectHolmes = (state: AppState): HolmesState => state?.holmes;

export const selectDirectedId = (state: AppState) => selectHolmes(state)?.directedId;

export const selectHolmesDetermined = (state: AppState) => selectHolmes(state)?.isHolmesDetermined;

export const selectHolmesLoaded = (state: AppState) => selectHolmes(state)?.isHolmesLoaded;
