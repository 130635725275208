import React from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import type { CurrencyObj } from 'helpers/DataFormatUtils';

import css from 'styles/components/common/card/cardPrice.scss';

interface CardPriceProps {
  price: CurrencyObj;
  msrp: CurrencyObj;
  onSale: boolean;
  prefix?: string;
  url: string;
  isAvailable: boolean;
}

const CardPrice = (props: CardPriceProps) => {
  const { testId } = useMartyContext();
  const { price, msrp, onSale, prefix, isAvailable, url } = props;
  const isOnSale = onSale && !prefix;
  return (
    <>
      <dt>Price</dt>
      <dd className={cn({ [css.onSale]: isOnSale })} itemProp="offers" itemScope itemType="https://schema.org/Offer">
        <meta itemProp="priceCurrency" content="USD" />
        <span className={css.price} itemProp="price" content={price?.int} data-test-id={testId('price')}>
          {prefix}
          {price?.string}
        </span>
        {isOnSale && (
          <span className={css.msrpLabelPrice}>
            <span className={css.msrpLabel}>MSRP: </span>
            <span className={css.originalPrice} data-test-id={testId('originalPrice')}>
              {msrp.string}
            </span>
          </span>
        )}

        {isAvailable ? (
          <meta itemProp="availability" content="https://schema.org/InStock" />
        ) : (
          <meta itemProp="availability" content="https://schema.org/OutOfStock" />
        )}
        <meta itemProp="url" content={url} />
      </dd>
    </>
  );
};

export default CardPrice;
