import React from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import type { AirplaneCacheUnitOption } from 'types/AirplaneCache';

import css from 'styles/components/productdetail/sizeUnitTabs.scss';

interface Props {
  onChange: (option: AirplaneCacheUnitOption) => void;
  options: AirplaneCacheUnitOption[];
  selectedOption: AirplaneCacheUnitOption;
}

export default function SizeUnitTabs(props: Props) {
  const { onChange, options, selectedOption } = props;
  const { testId } = useMartyContext();

  return (
    <div className={css.container} data-test-id={testId('sizingUnitPicker')}>
      {options.map(option => (
        <button
          data-test-id={testId('sizingUnitOption')}
          className={cn(css.tab, {
            [css.selected]: option.id === selectedOption.id
          })}
          key={option.id}
          onClick={() => onChange(option)}
          type="button"
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}
