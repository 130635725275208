import {
  CLEAR_POST_RETURN_INFO,
  CLEAR_RETURN_ERROR,
  CLEAR_RETURN_STATES,
  CLEAR_SUBMIT_RETURN_ERROR,
  INITIATE_RETURN_BEGIN,
  RECEIVE_CHANGE_SHIPPING_ADDRESS_ERROR,
  RECEIVE_CONTRACT_RETURN_INFO,
  RECEIVE_MULTIPLE_PRE_RETURN_INFO,
  RECEIVE_POST_RETURN_INFO,
  RECEIVE_PRE_RETURN_INFO,
  RECEIVE_RETURN_LABEL_INFO,
  RECEIVE_RETURN_LABEL_INFO_ERROR,
  RECEIVE_RETURN_LABEL_INFO_START,
  RECEIVE_RETURN_LABEL_PRODUCT_INFO,
  RESET_RETURNS_IN_STATE,
  RETURN_CANCELLATION_CONFIRMED,
  RETURN_CANCELLATION_ERROR,
  RETURN_CANCELLATION_REQUESTED,
  RETURN_PREVENTING_SUBMIT_ERROR,
  RETURN_SUBMIT_COMPLETE,
  RETURN_SUBMIT_ERROR,
  RETURN_SUBMIT_INITIATE,
  SET_RETURN_ERROR,
  STEP_SELECTION,
  SUBMIT_RETURN_ERROR,
  UPDATE_BOX_INFO
} from 'constants/reduxActions';

const initialState = {
  preReturnInfo: [],
  contractIds: [],
  step: 'SELECT_RETURN_ITEMS',
  postReturnInfo: [],
  isReturnSubmitting: false,
  labelInfo: {},
  isReturnError: false,
  isReturnPreventingSubmitError: false,
  returnErrorType: null,
  multiPreReturnInfo: null,
  initiateReturnLoading: false,
  contractReturnInfo: {},
  isReturnLabelLoading: false,
  isReturnSubmitSuccess: false,
  returnSuccessMessage: '',
  returnCancelledSuccessMessage: '',
  submitReturnError: null
};

export default function returns(state = initialState, action) {
  const { type, preReturnInfo, step, boxInfo, postReturnInfo, labelInfo, productInfo, returnErrorType, multiPreReturnInfo, contractReturnInfo } =
    action;
  switch (type) {
    case RECEIVE_PRE_RETURN_INFO:
      const newPreReturnInfo = state.preReturnInfo.concat([preReturnInfo]);
      const newContractIds = state.contractIds.concat([preReturnInfo.contractId]);
      return {
        ...state,
        preReturnInfo: newPreReturnInfo,
        contractIds: newContractIds
      };

    case STEP_SELECTION:
      return { ...state, step };

    case UPDATE_BOX_INFO: // called during both box initialization and update
      return { ...state, boxInfo };

    case RECEIVE_POST_RETURN_INFO:
      const newPostReturnInfo = state.postReturnInfo.concat([postReturnInfo]);
      return { ...state, postReturnInfo: newPostReturnInfo };

    case RETURN_SUBMIT_INITIATE:
      return { ...state, isReturnError: false, isReturnSubmitting: true, isReturnSubmitSuccess: false, returnSuccessMessage: '' };

    case RETURN_SUBMIT_COMPLETE:
      return { ...state, isReturnSubmitting: false, step: 'DONE', isReturnSubmitSuccess: true, returnSuccessMessage: 'Your return is confirmed' };

    case RECEIVE_CHANGE_SHIPPING_ADDRESS_ERROR: // for snail mail form errors
      return { ...state, isReturnSubmitting: false };

    case RECEIVE_RETURN_LABEL_INFO:
      return { ...state, labelInfo, isReturnLabelLoading: false };

    case RECEIVE_RETURN_LABEL_INFO_START:
      return { ...state, isReturnLabelLoading: true };

    case RECEIVE_RETURN_LABEL_INFO_ERROR:
      return { ...state, isReturnLabelLoading: false };

    case RECEIVE_RETURN_LABEL_PRODUCT_INFO:
      const newLabelInfo = { ...state.labelInfo };
      const products = newLabelInfo.products || [];
      newLabelInfo.products = products.concat(productInfo);
      return { ...state, labelInfo: newLabelInfo };

    case RETURN_SUBMIT_ERROR:
      return { ...state, isReturnError: true, isReturnSubmitting: false, isReturnSubmitSuccess: false, returnSuccessMessage: '' };

    case RETURN_PREVENTING_SUBMIT_ERROR:
      return { ...state, isReturnPreventingSubmitError: true };

    case SET_RETURN_ERROR:
      return {
        ...state,
        returnErrorType
      };

    case CLEAR_RETURN_ERROR:
      return {
        ...state,
        returnErrorType: initialState.returnErrorType,
        isReturnPreventingSubmitError: initialState.isReturnPreventingSubmitError
      };

    case RESET_RETURNS_IN_STATE:
      return { ...initialState };

    case RETURN_CANCELLATION_REQUESTED:
      return {
        ...initialState,
        isCancellationLoading: true,
        isCancellationConfirmed: false,
        returnCancelledSuccessMessage: ''
      };

    case RETURN_CANCELLATION_CONFIRMED:
      return {
        ...initialState,
        isCancellationLoading: false,
        isCancellationConfirmed: true,
        returnCancelledSuccessMessage: 'Your return has been canceled.'
      };

    case RETURN_CANCELLATION_ERROR:
      return {
        ...initialState,
        isCancellationLoading: false,
        isCancellationError: true,
        isCancellationConfirmed: false,
        returnCancelledSuccessMessage: ''
      };

    case INITIATE_RETURN_BEGIN:
      return {
        ...state,
        initiateReturnLoading: true
      };

    case RECEIVE_MULTIPLE_PRE_RETURN_INFO:
      return {
        ...state,
        multiPreReturnInfo,
        initiateReturnLoading: false
      };

    case RECEIVE_CONTRACT_RETURN_INFO:
      return { ...state, contractReturnInfo };

    case CLEAR_RETURN_STATES:
      return {
        ...state,
        isReturnSubmitSuccess: false,
        returnSuccessMessage: '',
        isCancellationConfirmed: false,
        isCancellationError: false,
        returnCancelledSuccessMessage: '',
        contractReturnInfo: {}
      };

    case CLEAR_POST_RETURN_INFO:
      return {
        ...state,
        postReturnInfo: []
      };

    case SUBMIT_RETURN_ERROR:
      return {
        ...state,
        submitReturnError: true
      };

    case CLEAR_SUBMIT_RETURN_ERROR:
      return {
        ...state,
        submitReturnError: null
      };

    default:
      return state;
  }
}
