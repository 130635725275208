import { PHONE_CHARS, POSTAL_CODE_CHARS } from 'common/regex';

export const ADDRESS_FIELDS = {
  COUNTRY_CODE: {
    fieldName: 'countryCode',
    maxLength: 2,
    autoComplete: 'country'
  },
  FULL_NAME: { fieldName: 'fullName', maxLength: 50, autoComplete: 'name' },
  PHONE_NUMBER: {
    fieldName: 'primaryVoiceNumber',
    maxLength: 20,
    type: 'tel',
    autoComplete: 'tel'
  },
  ADDRESS_LINE_1: {
    fieldName: 'addressLine1',
    maxLength: 60,
    autoComplete: 'address-line1'
  },
  ADDRESS_LINE_2: {
    fieldName: 'addressLine2',
    maxLength: 60,
    autoComplete: 'address-line2'
  },
  POSTAL_CODE: {
    fieldName: 'postalCode',
    maxLength: 20,
    autoComplete: 'postal-code'
  },
  NEW_POSTAL_CODE: {
    fieldName: 'postalCode',
    maxLength: 10,
    autoComplete: 'postal-code'
  }, // todo make this `POSTAL_CODE` when `cafo` is made permanent
  STATE_OR_REGION: {
    fieldName: 'stateOrRegion',
    maxLength: 50,
    autoComplete: 'address-level1'
  },
  CITY: { fieldName: 'city', maxLength: 50, autoComplete: 'address-level2' }
} as const;

export const PAYMENT_FIELDS = {
  CC: { fieldName: 'cc', maxLength: 23, autoComplete: 'cc-number' },
  CC_EXPIRATION: { fieldName: 'expiration', maxLength: 7 },
  CC_EXPIRATION_MELODY: { fieldName: 'expirationDate', maxLength: 7 },
  CC_EXPIRATION_MONTH: {
    fieldName: 'expirationMonth',
    maxLength: 2,
    autoComplete: 'cc-exp-month'
  },
  CC_EXPIRATION_YEAR: {
    fieldName: 'expirationYear',
    maxLength: 4,
    autoComplete: 'cc-exp-year'
  },
  CC_EXPIRATION_MONTH_YEAR: {
    fieldName: 'expirationMonthYear',
    maxLength: 7,
    autoComplete: 'cc-month-year-exp'
  },
  CC_CVV: { fieldName: 'cvv', maxLength: 4, autoComplete: 'cc-cvv' },
  NAME_ON_CARD: { fieldName: 'name', maxLength: 50, autoComplete: 'cc-name' }
} as const;

export const EMAIL_ADDRESS = {
  maxLength: 100,
  autoComplete: 'email'
} as const;

export const CUSTOM_INPUT_PROPS = {
  US: {
    postalCode: {
      inputMode: 'tel',
      pattern: '^[0-9]{5}(-[0-9]{4})?$',
      maxLength: ADDRESS_FIELDS.NEW_POSTAL_CODE.maxLength
    },
    phoneNumber: {
      'inputMode': 'tel',
      'pattern': '[0-9()]{5} [0-9]{3}-[0-9]{4}',
      'data-area-code-auto': 'true',
      'data-hyphen-auto': 'true'
    }
  }
} as const;

export const CUSTOM_INPUT_VALIDATION = {
  US: {
    validPostalValue: (val: string) => POSTAL_CODE_CHARS.test(val),
    validPhoneValue: (val: string) => PHONE_CHARS.test(val)
  }
} as const;

export const DATE_FIELD = {
  oneDayInMs: 86400000,
  dateFormat: 'YYYY-MM-DD'
} as const;
