export const BANNER_TYPES = {
  PDP: 'pdpbanners',
  SEARCH: 'searchbanners',
  STANDARD: 'standardbanners'
};

export const BANNER_PAGES = [BANNER_TYPES.PDP, BANNER_TYPES.SEARCH, BANNER_TYPES.STANDARD];

export type BannerType = 'product' | 'search' | 'standard' | '';

export const PAGE_TYPE_BANNERS = {
  product: BANNER_TYPES.PDP,
  search: BANNER_TYPES.SEARCH,
  get: function (pageType: BannerType): string {
    return this[pageType as keyof object] || BANNER_TYPES.STANDARD;
  }
};
