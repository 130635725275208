import React from 'react';
import { useSelector } from 'react-redux';
import { shallowEqual } from 'fast-equals';

import { cn } from 'helpers/classnames';
import Heart from 'components/common/Heart';
import { makeHandleHeartButtonClick } from 'helpers/HeartUtils';
import { selectHeartStyleInfoFactory } from 'selectors/hearts';

import css from 'styles/components/common/hearts.scss';

const Hearts = ({ onHeartClick, showFavoriteHeart, isDisplayCount, styleId, productId }) => {
  const { hearted: isHearted, count } = useSelector(selectHeartStyleInfoFactory(styleId), shallowEqual);
  if (!showFavoriteHeart) {
    return null;
  }

  const compactCount = (count > 1000 ? `${Math.floor(count / 1000)}k` : count) || 0;

  const handleHeartClick = () =>
    makeHandleHeartButtonClick({
      isHearted,
      onHeartClick,
      productId,
      style: { styleId }
    });

  const heartProps = {
    cssHeartContainer: cn(css.heart, css.searchHeart, {
      [css.noCountHeart]: !isDisplayCount
    }),
    cssHeartActive: css.heartActive,
    extraRecoStyle: null,
    handleHeartClick,
    productId,
    showFavoriteHeart,
    style: { styleId },
    isDisplayCount,
    testId: 'heartButton',
    isHearted,
    count: compactCount
  };

  return (
    <div
      className={cn(css.heartContainer, css.searchHeartContainer, {
        [css.noCountContainer]: !isDisplayCount
      })}
    >
      <Heart {...heartProps} />
    </div>
  );
};

export default Hearts;
