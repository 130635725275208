// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeSearchMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M21.7474 21.1631L27.9782 27.3939M9.9331 22.4035C5.21387 19.6789 3.59957 13.6526 6.32422 8.93333C7.00522 7.64621 9.26981 4.8981 12.8802 4.20265C15.1612 3.73367 17.6166 4.06709 19.7939 5.32414C24.4983 8.04025 26.1283 14.0749 23.4036 18.7941C20.679 23.5134 14.6375 25.1196 9.9331 22.4035Z"
      stroke="currentcolor"
      strokeLinecap="round"
    />
  </Icon>
);

export default UtilityStrokeSearchMediumIcon;
