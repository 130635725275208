import type { ReactNode } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import { cn } from 'helpers/classnames';
import { isLeftClickEvent, isModifiedEvent } from 'helpers/EventHelpers';
import useMartyContext from 'hooks/useMartyContext';
import { ABSOLUTE_URL_RE } from 'common/regex';

import css from 'styles/components/common/martyLink.scss';

export interface MartyLinkProps {
  children?: ReactNode;
  className?: string;
  target?: string;
  to: string;
  isLinkDisabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => any;
  [key: string]: any;
}

export const MartyLink = (props: MartyLinkProps) => {
  const { onClick, to = '', isLinkDisabled = false, ...rest } = props;

  const { router } = useMartyContext();

  if (isLinkDisabled) {
    const disableLinkProps = {
      'role': 'link',
      'aria-disabled': true,
      'tabIndex': -1
    };
    const { children, className } = rest;

    return (
      <a className={cn(css.disableLink, className)} {...disableLinkProps}>
        {children}
      </a>
    );
  }

  // react-router Link doesn't except absolute urls as "to", so we do that here as an <a/>.
  if (to?.match(ABSOLUTE_URL_RE)) {
    const { innerRef, forwardRef, children, ...externalRest } = rest;
    return (
      <a ref={innerRef || forwardRef} href={to} onClick={onClick} {...externalRest}>
        {children}
      </a>
    );
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { target, to = '' } = props;

    if (onClick) {
      onClick(e);
    }

    if (!isLeftClickEvent(e) || isModifiedEvent(e) || e.defaultPrevented || target) {
      return;
    }

    e.preventDefault();
    router.pushPreserveAppRoot?.(to);
  };

  return <Link to={to} onClick={handleClick} {...rest} />;
};

export default MartyLink;
