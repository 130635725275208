/**
 * Wrapper Icon Button for the design library button to pass our router specific link
 */

import type { ExoticComponent } from 'react';
import { Link } from 'react-router-dom';
import { IconButton as DesignLibraryIconButton, type IconButtonProps } from '@mweb/zappos-ui/IconButton';

const IconButton = ({ href, ...rest }: Omit<IconButtonProps, 'linkComponent' | 'linkProps'>) => (
  <DesignLibraryIconButton {...rest} href={href} linkComponent={Link as ExoticComponent} linkProps={{ to: href }} />
);

export default IconButton;
