import { LOCATION_CHANGE } from 'connected-react-router';

import { ADD_AD_TO_QUEUE, RESET_AD_QUEUE, SET_AD_CUSTOMER_INFO } from 'constants/reduxActions';

export const defaultState = {
  queuedAds: [],
  adCustomerId: null,
  adEmailHash: null
};

export default function appAdvertisement(state = defaultState, action) {
  const { type, ads, adCustomerId, adEmailHash } = action;
  switch (type) {
    case ADD_AD_TO_QUEUE:
      return { ...state, queuedAds: state.queuedAds.concat(ads) };
    case RESET_AD_QUEUE:
    case LOCATION_CHANGE:
      return { ...state, queuedAds: [] };
    case SET_AD_CUSTOMER_INFO:
      return { ...state, adCustomerId, adEmailHash };
    default:
      return state;
  }
}
