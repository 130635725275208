import React, { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import { onEvent } from 'helpers/EventHelpers';
import { trackEvent } from 'helpers/analytics';
import type { AppState } from 'types/app';

import styles from 'styles/components/productdetail/recommendedSizeTooltip.scss';

interface State {
  hidden: boolean;
}

interface OwnProps {
  id: string;
  openModal: () => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

export class RecommendedSizeTooltip extends Component<Props, State> {
  state: State = {
    hidden: true
  };
  componentDidMount() {
    const { sessionId } = this.props;
    const sessionLastDismissedSizeTooltip = window.localStorage.getItem(this.localStorageKey);
    if (!sessionLastDismissedSizeTooltip || sessionId !== sessionLastDismissedSizeTooltip) {
      this.setState({ hidden: false });
    }

    onEvent(document, 'click', this.hide, undefined, this); // allow the tooltip to be dismissed when screen clicked
    onEvent(document, 'keydown', this.onKeyDown, undefined, this); // allow the tooltip to be dismissed on esc press
  }
  localStorageKey = 'sessionLastDismissedSizeTooltip';
  onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      this.hide();
    }
  };
  hide = () => {
    const { sessionId } = this.props;
    this.setState({ hidden: true });
    window.localStorage.setItem(this.localStorageKey, sessionId);
  };
  onClick = () => {
    const { openModal } = this.props;
    openModal();
  };
  render() {
    const { id } = this.props;
    const { hidden } = this.state;
    /*
      https://www.w3.org/TR/wai-aria-practices-1.1/#tooltip
      This is already not a properly accessible tooltip (It is more of a modal that looks like a tooltip)
      because it doesn't show on user input and we can't have the focus on the button this tooltip describes.
      This is because we show this tooltip on page load so having the page scroll to the tooltip is jarring for the user

      buttttt it does everything else right!
    */
    return (
      <div
        id={id}
        role="tooltip"
        className={cn(styles.recommendedSizeTooltip, {
          [styles.hideTooltip]: hidden
        })}
      >
        <div className={cn(styles.fadeIn, styles.delay)}>
          <p>We can help you find your size!</p>
          <p>
            Tell us which shoes fit you well in our
            <button type="button" onClick={this.onClick}>
              size calculator
            </button>
            and we can recommend a size that fits you best.
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  sessionId: state.cookies['session-id'] as string
});

const mapDispatchToProps = {
  trackEvent
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(RecommendedSizeTooltip);
