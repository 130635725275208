import type { ImgHTMLAttributes } from 'react';
import { memo, useEffect, useRef } from 'react';
import { deepEqual } from 'fast-equals';

import { cn } from 'helpers/classnames';

import css from 'styles/components/common/image.scss';

const defaultProps = {
  placeholder: <div className={cn(css.lazyLoadSpinner)} />
};

// remove placeholder global html attribute from the type so its not mistaken as the placeholder for the LazyImage
export type ImageWithoutPlaceholderProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'placeholder'> & {
  fetchPriority?: 'high' | 'low' | 'auto';
};

export type WebpImageProps = { webp: { src: string; srcSet: string } };
type PicturePropsType = {
  pictureProps?: JSX.Element[] | null;
  hasAspect?: boolean;
  isFullWidth?: boolean;
};
export type ImageProps = ImageWithoutPlaceholderProps & Partial<typeof defaultProps> & Partial<WebpImageProps> & PicturePropsType;

// you can use this if your component is memoized already
export const NonMemoImage = ({
  id,
  className,
  isFullWidth,
  hasAspect,
  pictureProps = null,
  loading = 'lazy',
  alt,
  fetchPriority,
  ...rest
}: ImageProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);

  // would prefer useLayoutEffect, but it causes hydration issues :(
  useEffect(() => {
    const img = imgRef.current;
    if (!img) {
      return;
    }
    if (!img?.complete) {
      if (placeholderRef.current) {
        placeholderRef.current.style.display = 'block';
      }
      img.style.opacity = '0.2';
      // install the handler to turn off the spinner when finished.
      const { onload } = img;
      img.onload = ev => {
        // image finished loading turn off the
        img.style.opacity = '1';
        if (placeholderRef.current) {
          placeholderRef.current.style.display = 'none';
        }
        onload && onload.call(img, ev);
      };
    } else {
      // image already loaded  ensure the image is shown
      // and that the spinner is off.
      img!.style.opacity = '1';
      if (placeholderRef.current) {
        placeholderRef.current.style.display = 'none';
      }
    }
  }, [imgRef.current, placeholderRef.current]);

  const { webp: { srcSet: webpSrcSet } = {}, ...restProps } = rest;

  return (
    <div id={id} className={cn(className, css.image, { [css.fullWidth]: isFullWidth, [css.hasStyledAspectRatio]: hasAspect })}>
      <div className={cn(css.lazyLoadSpinner)} ref={placeholderRef} />
      <picture className={cn(css.lazyLoadPicture)}>
        {webpSrcSet && <source srcSet={webpSrcSet} type="image/webp" />}
        {pictureProps}
        <img
          ref={imgRef}
          loading={loading}
          className={cn(css.lazyLoadImage)}
          alt={alt}
          {...restProps}
          // @ts-ignore
          fetchpriority={fetchPriority}
        />
      </picture>
    </div>
  );
};

export default memo(NonMemoImage, deepEqual);
