import { LOW_STOCK_LABEL_LIMIT } from 'constants/appConstants';

interface GetCardFlagInput {
  isSponsored: boolean;
  isCouture: boolean;
  storeName: string;
  styleId: string;
}

export const getCardFlag = (args: GetCardFlagInput) => {
  const { isSponsored, isCouture, storeName, styleId } = args;

  const isTrustedRetailer = !isSponsored && !isCouture && storeName && !!styleId;

  switch (true) {
    case isSponsored:
      return 'sponsored';
    case isTrustedRetailer:
      return 'trusted retailer';
    default:
      return null;
  }
};

interface GetLowStockLabelStatusInput extends GetCardFlagInput {
  onHand: number;
}

export const getLowStockLabelStatus = (args: GetLowStockLabelStatusInput) =>
  getCardFlag({ ...args }) !== 'trusted retailer' && args.onHand < LOW_STOCK_LABEL_LIMIT;
