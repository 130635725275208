import { REMOVE_COOKIE, SET_COOKIE } from 'constants/reduxActions';
import {
  GENERIC_SESSION_ID,
  GENERIC_SESSION_TOKEN,
  GENERIC_UBID_MAIN,
  MAFIA_SESSION_ID,
  MAFIA_SESSION_ID_LOWER,
  MAFIA_SESSION_TOKEN,
  MAFIA_SESSION_TOKEN_LOWER,
  MAFIA_UBID_MAIN,
  MAFIA_UBID_MAIN_LOWER
} from 'constants/apis';
import marketplace from 'cfg/marketplace.json';

const { cookieDomain } = marketplace;

export const sessionRequestHeader = {
  'X-Session-Requested': 1
};

export function sessionExpiration() {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 20);
  date.setMonth(0);
  date.setDate(1); // the only 1-indexed setter..
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export const setSessionCookies =
  (dispatch, getState, skipIfMissingCookie = false) =>
  responseHeaders => {
    const state = getState();
    const responseUbidMain =
      responseHeaders.get(MAFIA_UBID_MAIN) || responseHeaders.get(MAFIA_UBID_MAIN_LOWER) || responseHeaders.get(GENERIC_UBID_MAIN);
    const responseSessionId =
      responseHeaders.get(MAFIA_SESSION_ID) || responseHeaders.get(MAFIA_SESSION_ID_LOWER) || responseHeaders.get(GENERIC_SESSION_ID);
    const responseSessionToken =
      responseHeaders.get(MAFIA_SESSION_TOKEN) || responseHeaders.get(MAFIA_SESSION_TOKEN_LOWER) || responseHeaders.get(GENERIC_SESSION_TOKEN);

    if (state.cookies && responseUbidMain && responseSessionId && responseSessionToken) {
      const {
        cookies: { 'session-id': sessionId, 'ubid-main': ubidMain, 'session-token': sessionToken }
      } = state;

      // mafia and marty sessions match - so don't set cookies
      if (ubidMain === responseUbidMain && sessionId === responseSessionId && sessionToken === responseSessionToken) {
        return;
      }
    }

    if (skipIfMissingCookie && (!responseSessionId || !responseSessionToken || !responseUbidMain)) {
      return;
    }

    const expiration = sessionExpiration();
    responseSessionId && dispatch(setAndStoreCookie('session-id', responseSessionId, expiration));
    responseSessionToken && dispatch(setAndStoreCookie('session-token', responseSessionToken, expiration));
    responseUbidMain && dispatch(setAndStoreCookie('ubid-main', responseUbidMain, expiration));
  };

// This is the action creator, to do a raw cookie set see helpers#setCookie
export function setAndStoreCookie(cookieName, cookieValue, expires) {
  return {
    type: SET_COOKIE,
    cookie: {
      name: cookieName,
      value: cookieValue,
      options: {
        domain: cookieDomain,
        expires
      }
    }
  };
}

// This is the action creator.  It's tied to browserMetadataMiddleware's REMOVE_COOKIE
export function removeFromStoredCookies(cookieName) {
  return {
    type: REMOVE_COOKIE,
    cookie: {
      name: cookieName,
      domain: cookieDomain
    }
  };
}

/**
 * @param config - Config object for fetch
 */
export const setSessionRequestedHeaders = (config = {}) => {
  const { headers } = config || {};
  const finalHeaders = { headers: { ...headers, ...sessionRequestHeader } };
  return { ...config, ...finalHeaders };
};
