import React from 'react';
import { useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import type { AirplaneButtonDimension, AirplaneCacheDimensionOption, SetItemSelection } from 'types/AirplaneCache';
import { defaultIsInStock, defaultIsSelected, makeOptionGroupDataTestId } from 'utils/airplaneCache';
import { selectProductAirplaneCache } from 'selectors/product';
import useMartyContext from 'hooks/useMartyContext';
import AirplaneButton from 'components/productdetail/stylepicker/AirplaneButton';

import css from 'styles/components/productdetail/stylepicker/airplaneFieldsetOptions.scss';

interface AirplaneFieldsetOptionsProps {
  displayedOptions: AirplaneCacheDimensionOption[];
  isInStockFn?: (option: AirplaneCacheDimensionOption) => boolean;
  isSelectedFn?: (option: AirplaneCacheDimensionOption) => boolean;
  radioButtonName: string;
  setItemSelection: SetItemSelection;
  typeKey: AirplaneButtonDimension;
  useNarrowOptionButtons?: boolean;
  namespace: string;
}

const AirplaneFieldsetOptions = ({
  displayedOptions,
  isInStockFn,
  isSelectedFn,
  radioButtonName,
  setItemSelection,
  typeKey,
  useNarrowOptionButtons,
  namespace
}: AirplaneFieldsetOptionsProps) => {
  const { testId } = useMartyContext();

  const airplaneCache = useSelector(selectProductAirplaneCache);

  if (!airplaneCache) {
    return null;
  }

  if (!(displayedOptions.length > 0)) {
    return <p className={css.noOptions}>No options available. Please modify the selection above.</p>;
  }

  const optionGroupTestId = testId(makeOptionGroupDataTestId(typeKey));
  const optionsClassName = cn(css.airplaneSizingContainer, {
    [css.narrowOptionButtons]: useNarrowOptionButtons
  });

  return (
    <div className={optionsClassName} data-test-id={optionGroupTestId}>
      {displayedOptions.map(option => {
        const isInStock = isInStockFn ? isInStockFn(option) : defaultIsInStock(typeKey, airplaneCache, option);
        const isSelected = isSelectedFn ? isSelectedFn(option) : defaultIsSelected(typeKey, airplaneCache, option);

        return (
          <AirplaneButton
            isInStock={isInStock}
            isSelected={isSelected}
            key={option.id}
            option={option}
            radioButtonName={radioButtonName}
            setItemSelection={setItemSelection}
            typeKey={typeKey}
            namespace={namespace}
          />
        );
      })}
    </div>
  );
};

export default AirplaneFieldsetOptions;
