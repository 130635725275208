import { track } from 'apis/amethyst';
import { evTopLevelNavigationClick } from 'events/headerFooter';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import useMartyContext from 'hooks/useMartyContext';
import HeaderIconLink from 'components/hf/zappos/HeaderIconLink';
import UtilityDottedHeartOutlineMediumIcon from 'tailwind/components/Icons/UtilityDottedHeartOutlineMediumIcon';

export const FavoritesIcon = ({ headerFavoritesTestId = 'headerFavorites' }: { headerFavoritesTestId?: string }) => {
  const { testId } = useMartyContext();

  return (
    <HeaderIconLink
      label="Favorites"
      href="/account/favorites"
      data-test-id={testId(headerFavoritesTestId)}
      onClick={() => {
        trackEvent('TE_HEADER_ACCOUNT_FAVORITES');
        trackLegacyEvent('Main-Nav', 'Account', 'Favorites');
        track(() => [
          evTopLevelNavigationClick,
          {
            valueClicked: 'Favorites'
          }
        ]);
      }}
    >
      <UtilityDottedHeartOutlineMediumIcon size={32} />
    </HeaderIconLink>
  );
};

export default FavoritesIcon;
