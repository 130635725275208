import React, { cloneElement, SVGProps, ReactNode, HTMLAttributes, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { deepMap } from 'helpers/childrenUtilities';
import useClientGuid from 'hooks/useClientGuid';
import { cn } from 'helpers/classnames';

interface ISVGUniqueId { id: string; children: ReactNode };
// Uses the passed guid to ensure all ids in the svg are unique so multiple instances of an icon do not clash/icons with the same ids
export const SVGUniqueId = ({ children, id }: ISVGUniqueId) => {
  return <>
    {
      deepMap(children, child => {
        if (!isValidElement(child)) {
            return child;
        } else if(child?.props) {
          const childProps = child.props;
          // add id to all props with a url(#...) values
          const fixedUrlProps = Object.entries(childProps)
            .filter(([_, value]) => typeof value === 'string' && value.includes('url(#')) // filter out props without urls
            .map(([key, value]) => [key, `${(value as string).slice(0,-1)}${id})`]) // append id inside url parens
            .reduce( (obj, [key, value]) => key ? { ...obj, [key] : value } : {}, {}); // turn back to object

          return cloneElement(child, {
            id : childProps.id ? `${childProps.id}${id}` : undefined,
            xlinkHref : childProps.xlinkHref && /^#/.test(childProps.xlinkHref) ? `${childProps.xlinkHref}${id}` : childProps.xlinkHref, // only append id to links that start with # since that means it is a reference to an id
            ...fixedUrlProps
          } as HTMLAttributes<unknown>);
        } else {
          return '';
        }
      })
    }
  </>;
};

export type IIconBaseProps = Partial<{
    title: string;
    description: string;
    size: string;
    hasCustomTooltip: boolean;
  } & SVGProps<SVGSVGElement>
>;

const IconBase = ({ children, className, size = '1em', title = '', description = '', hasCustomTooltip=false, ...props }: IIconBaseProps) => {
  const id = useClientGuid() || '';
  const titleId = `icon-title-${id}`;
  const descriptionId = `icon-description-${id}`;
  return (
    <svg
      className={cn('inline-block', className)}
      preserveAspectRatio="xMidYMid meet"
      height={size}
      width={size}
      aria-hidden={hasCustomTooltip ? "true" : undefined}
      aria-labelledby={!hasCustomTooltip && title ? titleId : undefined}
      aria-describedby={!hasCustomTooltip && description ? descriptionId : undefined}
      {...props}>
      {!hasCustomTooltip && title && <title id={titleId}>{title}</title>}
      {!hasCustomTooltip && description && <desc id={descriptionId}>{description}</desc>}
        <SVGUniqueId id={id} > {children} </SVGUniqueId>
    </svg>
  );
};

IconBase.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  title: PropTypes.string,
  description: PropTypes.string
};

export default IconBase;
