// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeArrowRightMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M26.3434 16.3485C26.5387 16.1532 26.5387 15.8367 26.3434 15.6414L19.807 9.10494C19.6117 8.90968 19.2951 8.90968 19.0999 9.10494C18.9046 9.3002 18.9046 9.61679 19.0999 9.81205L24.7828 15.495L5.94824 15.495C5.6721 15.495 5.44824 15.7189 5.44824 15.995C5.44824 16.2712 5.6721 16.495 5.94824 16.495L24.7827 16.495L19.0999 22.1779C18.9046 22.3731 18.9046 22.6897 19.0999 22.885C19.2951 23.0802 19.6117 23.0802 19.807 22.885L26.3434 16.3485Z"
      fill="currentcolor"
    />
  </Icon>
);

export default UtilityStrokeArrowRightMediumIcon;
