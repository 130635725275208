export default [
  'Applebot',
  'Googlebot',
  'AdsBot-Google',
  'Mediapartners-Google',
  'Bingbot',
  'Slurp',
  'DuckDuckBot',
  'Baiduspider',
  'YandexBot',
  'Sogou.*spider',
  'Exabot',
  'facebot',
  'facebookexternalhit',
  'ia_archiver',
  'botify',
  'Screaming Frog SEO Spider'
];
