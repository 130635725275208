import { capitalize } from 'helpers/index';
import type { AirplaneButtonDimension, AirplaneCache, AirplaneCacheDimensionOption } from 'types/AirplaneCache';

/**
 * Creates the data-test-id for the gender, size, and width option containers.
 *
 * Tags for people grepping the codebase:
 * airplaneSeatSizeOptionGroupForAgeGroup, airplaneSeatSizeOptionGroupForGender,
 * airplaneSeatSizeOptionGroupForSingleShoeSide,
 * airplaneSeatSizeOptionGroupForSize, airplaneSeatSizeOptionGroupForWidth
 */
export const makeOptionGroupDataTestId = (typeKey: AirplaneButtonDimension) => {
  const suffix = capitalize(typeKey);
  return `airplaneSeatSizeOptionGroupFor${suffix}`;
};

export const defaultIsInStock = (
  typeKey: AirplaneButtonDimension,
  airplaneCache: Pick<AirplaneCache, 'available'>,
  { constraintValue }: AirplaneCacheDimensionOption
) => {
  const availableOptionsKey = `${typeKey}Options` as keyof AirplaneCache['available'];
  const availableOptions: (string | undefined)[] = airplaneCache.available[availableOptionsKey];

  return availableOptions.includes(constraintValue);
};

export const defaultIsSelected = (
  typeKey: AirplaneButtonDimension,
  airplaneCache: Pick<AirplaneCache, 'constraints'>,
  { constraintValue }: AirplaneCacheDimensionOption
) => {
  const constraintName = typeKey as keyof AirplaneCache['constraints'];
  return constraintValue === airplaneCache.constraints[constraintName];
};
