const setIfNotUndefined = (value = '') => value;

type MetaInfo = {
  name: string;
  content: string;
};

type SeoData = {
  optimizedH1Tag?: string;
  optimizedTitleTag?: string;
  optimizedMetaDescription?: string;
  optimizedSeoCopy?: string;
  titleTag?: string;
  honeTag?: string;
  h1Tag?: string;
  metaInfo?: MetaInfo[];
};

type Response = {
  seoOptimizedData?: SeoData;
  seoData: SeoData;
  titleTag?: string;
  honeTag?: string;
  metaInfo?: MetaInfo[];
};

export function isSeoDataAvailable({ seoData }: Response) {
  if (seoData) {
    const { optimizedH1Tag, optimizedTitleTag, optimizedMetaDescription, optimizedSeoCopy } = seoData;
    if (optimizedH1Tag || optimizedTitleTag || optimizedMetaDescription || optimizedSeoCopy) {
      return true;
    }
  }

  return false;
}

export function retrieveSeoData(response: Response) {
  return {
    copy: setIfNotUndefined(response.seoOptimizedData?.optimizedSeoCopy),
    h1Tag: retrieveH1Tag(response)
  };
}

export function retrieveTitleTag({ seoData, titleTag, seoOptimizedData }: Response) {
  return setIfNotUndefined(seoOptimizedData?.optimizedTitleTag || seoData?.titleTag || titleTag);
}

function retrieveH1Tag({ seoData, honeTag, seoOptimizedData }: Response) {
  return setIfNotUndefined(seoOptimizedData?.optimizedH1Tag || seoData?.honeTag || honeTag);
}

export function retrieveH1TagFromFilters({ honeTag, seoData }: Response) {
  return setIfNotUndefined(seoData?.h1Tag || honeTag);
}

export function retrieveMetaInfo({ seoData, metaInfo, seoOptimizedData }: Response) {
  const metaTags = (metaInfo?: MetaInfo[]) => (metaInfo || []).reduce((acc, { name, content }) => ({ ...acc, [name]: content }), {});
  const newMetaInfo = metaTags(seoData?.metaInfo || metaInfo);

  return seoOptimizedData?.optimizedMetaDescription ? { ...newMetaInfo, description: seoOptimizedData.optimizedMetaDescription } : newMetaInfo;
}
