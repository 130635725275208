const COLOR_SWATCH_MAP: { [key: string]: string } = {
  'black': '#000000',
  'blue': '#5694F8',
  'white': '#FFFFFF',
  'gray': '#808080',
  'brown': '#6C3E19',
  'multi': 'conic-gradient(from 0.26turn, #e15a13, #adc236 0.19turn, #78a65c 0.39turn, #5c82a6 0.61turn, #7255a3 0.84turn, #e15a13)',
  'navy': '#000080',
  'pink': '#FFC0CB',
  'red': '#BE1515',
  'beige': '#F5F5DC',
  'green': '#008000',
  'tan': '#D2B48C',
  'gold': '#FFD700',
  'silver': '#C0C0C0',
  'purple': '#800080',
  'yellow': '#FFE300',
  'orange': '#FFA500',
  'taupe': '#9D8B7D',
  'olive': '#808000',
  'khaki': '#F0E68C',
  'burgundy': '#800020',
  'animal print': 'radial-gradient(circle at 25% 12%, #b77e4c, #9b5d2e 25%, #000000 32%, #b1743d 47%, #bd8046 57%, #000000 67%, #a67945 78%)',
  'neutral': '#E8E8DB',
  'bone': '#FBF8ED',
  'pewter': '#6B6B63',
  'metallic': 'linear-gradient(243deg, #d0ccc9, #cfcbc8, #ffffff, #e8e1db, #d4cdcc, #ccc8c5)',
  'unknown color': '',
  'bronze': '#CD7F31',
  'coral': '#FF7F50',
  'mahogany': '#5D0709',
  'clear': 'linear-gradient(to bottom, #e9e9e9, #ececec 23%, #fafafa 45%, #ffffff 46%, #ededed 84%, #efefef)'
};

export default COLOR_SWATCH_MAP;
