import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInterval } from 'hooks/useInterval';
import { fetchEmergencyBanner } from 'store/ducks/emergencyBanner/actions';
import type { AppState } from 'types/app';

import css from 'styles/components/emergencyBanner.scss';

export const POLLING_INTERVAL = 1000;

function EmergencyBanner() {
  const dispatch = useDispatch();
  const { data } = useSelector((state: AppState) => state.emergencyBanner);

  // on mount, poll for the banner data. null banner data causes component to unmount.
  useInterval(() => {
    dispatch(fetchEmergencyBanner());
  }, POLLING_INTERVAL);

  if (data?.message) {
    return (
      <div className={css.ebanner}>
        <p>{data.message}</p>
      </div>
    );
  }

  return null;
}

export default EmergencyBanner;
