import type { TagSize, TagVariant } from 'tailwind/components/Tag/Tag.types';

export const baseClasses = 'border-box inline-flex justify-center items-center select-none rounded-full whitespace-nowrap';

const strokeClasses = 'bg-button-transparent border-solid border-x border-y';

export const variantClasses: Record<TagVariant, string> = {
  'white': 'bg-default-minimal text-primary',
  'charcoal': 'bg-default-subtle text-primary',
  'black': 'bg-default-contrast text-reversed',
  'blue 100': 'bg-primary-minimal text-primary',
  'blue 400': 'bg-primary-strong text-reversed',
  'info': 'bg-info text-info',
  'warning': 'bg-warning text-warning',
  'alert': 'bg-alert text-alert-inline',
  'success': 'bg-success text-success-inline',
  'gray stroke': `${strokeClasses} text-primary border-moderate`,
  'blue stroke': `${strokeClasses} text-accent border-primary`
};

export const sizeClasses: Record<TagSize, string> = {
  small: 'py-1 px-2 text-xs',
  medium: 'py-1 px-3 text-sm',
  large: 'py-1 px-3 text-base'
};
