export const PLEASE_ENTER_VALID_EMAIL = 'Please Enter A Valid Email Address';

export const PLEASE_CHECK_THIS_BOX = 'Please check this box to sign up for emails';

export const EMAIL_STAR = 'Email*';

export const SIGN_UP_WITH_YOUR_EMAIL = 'Sign up with your email';

export const EMAIL_ADDRESS = 'Email address';

export const INVALID_FIRST_TIME_SUBSCRIBER_MSG = "Hi friend! Looks like you're already subscribed";

export const SIGN_UP_FOR_ZAPPOS_EMAILS = 'Sign Up for Zappos Emails';

export const EMAIL_SIGNUP_GENERAL_ERROR = 'There was an issue signing up. Please try again';

export const EMAIL_VALIDATION_DELAY = 650;
export const FIRST_TIME_SUBSCRIBER_DELAY = 1000;

// MAXIMIZED REFERS TO FULL DISPLAY SIGNUP COMPONENT, MINIMIZED REFERS TO TOGGLE BUTTON ON LEFT BOTTOM CORNER
export const DESKTOP_MAXIMIZED_MOBILE_MINIMIZED = 'treatment-desktop-maximized-mobile-minimized';
export const DESKTOP_MOBILE_MAXIMIZED = 'treatment-desktop-mobile-maximized';
export const DESKTOP_MOBILE_MINIMIZED = 'treatment-desktop-mobile-minimized';
