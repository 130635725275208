import { UNLEASH_BOOTSTRAP_LOADED } from 'constants/reduxActions';
import type { UnleashBootstrapToggles } from 'containers/UnleashFlagProvider';
import type { FeaturesAction } from 'actions/features';

type UnleashReducerState = {
  toggles: UnleashBootstrapToggles;
};

const initialState = { toggles: [] };

// The ssr sets preloaded toggles here for the UnleashFlagProvider client to bootstrap with that state, rather than async load feature flags which causes flashes.
export default function unleashReducer(state: Readonly<UnleashReducerState> = initialState, action: FeaturesAction): UnleashReducerState {
  switch (action.type) {
    case UNLEASH_BOOTSTRAP_LOADED:
      return { toggles: action.payload.toggles };
    default:
      return state;
  }
}
