export const PROVIDE_LABEL_OPTIONS = {
  PRINT: 'PRINT',
  EMAIL: 'EMAIL',
  LABELLESS: 'UPS_LABELLESS',
  MAIL: 'MAIL',
  PICKUP: 'UPS_PICKUP',
  ANY_CARRIER: 'ANY_CARRIER',
  UPS_PACKAGELESS: 'UPS_PACKAGELESS',
  WFM_PACKAGELESS: 'WFM_PACKAGELESS'
};

export const GET_ORDER_ID_ATTEMPTS = {
  INITIAL_ATTEMPTS: 0,
  MAX_ATTEMPTS: 10
};

export const UPS_FIND_STORE_URL = 'https://www.theupsstore.com/tools/find-a-store';

export const UPS_DROPOFF_LOCATION_URL = 'https://www.ups.com/dropoff/?loc=en_US';

export const ABSOLUTE_URL_RE = /\/\/([^/,\s]+\.[^/,\s]+?)(?=\/|,|\s|$|\?|#)/;
export const DASH_UNDERSCORE_WITH_FOLLOWING_LETTER_RE_GEN = (): RegExp => /[_.-]+(\w|$)/g;
export const IS_GIFT_CARD = /Gift Cards$/;
export const NUMBER_SANS_COMMAS_RE_GEN = (): RegExp => /\B(?=(\d{3})+(?!\d))/g;
export const POPUP_CLASS_RE = /^popup-(\d+)-(\d+)$/;

export const REDIRECT_URL = '/account';
