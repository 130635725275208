// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityDottedTrueToSizeMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M7.86103 15.8965V9.2987C7.86103 7.47687 9.3379 6 11.1597 6H30.0002V12.5978H23.1752M7.86103 15.8965C7.86103 17.7185 9.33802 19.1955 11.16 19.1955M7.86103 15.8965C7.86103 14.0746 9.33802 12.5976 11.16 12.5976L23.1752 12.5978M23.1752 12.5978V22.4946M11.16 19.1955H19.8763C21.6982 19.1955 23.1752 20.6726 23.1752 22.4946M11.16 19.1955L1.3335 19.1955V25.7933H19.8764C21.6983 25.7933 23.1752 24.3164 23.1752 22.4946M26.4314 6V8.9739M22.8628 6V8.9739M19.2941 6V8.9739M15.7254 6V8.9739M12.1566 6V8.9739M15.6083 22.8194V25.7932M19.177 22.8194V25.7932M12.0396 22.8194V25.7932M8.47094 22.8194V25.7932M4.90222 19.1955V25.7933"
      stroke="currentcolor"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </Icon>
);

export default UtilityDottedTrueToSizeMediumIcon;
