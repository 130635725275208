import React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect, useSelector } from 'react-redux';
import type { LinkProps } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { stringify } from 'query-string';

import type { AppState } from 'types/app';
import useMartyContext from 'hooks/useMartyContext';
import { getAbsoluteMarketplaceUrl } from 'helpers/MarketplaceUtils';
import { setFederatedLoginModalVisibility } from 'actions/headerfooter';

interface OwnProps {
  // use to add query params to the return_to url. (see HeartLoginPrompt.jsx)
  queryAddOns?: { [key: string]: string };
}

type PropsFromRedux = ConnectedProps<typeof connector>;
/**
 * Use to render an appropriate login element based on marketplace.json configurations
 * - if delegateLoginTo exists:
 *   - build an external link using an anchor tag w/ the absolute returnTo qs for the current page.
 *   - additional params can be set on the returnTo by supplying queryAddOns object through props. (see HeartLoginPrompt.jsx)
 * - elif hasFederatedLogin:
 *   - build a button that triggers opening of the federatedLogin modal.
 * - otherwise:
 *   - builds the relative link to login.
 * all LinkProps are passed and overrideable including to and onClick.
 * use onClick appropriately and do not block external navigations.
 */
export const LoginLink = (props: OwnProps & PropsFromRedux & Partial<LinkProps>) => {
  const {
    marketplace: { hasFederatedLogin, delegateLoginTo, domain }
  } = useMartyContext();
  const { to, queryAddOns, onClick, setFederatedLoginModalVisibility, ...rest } = props;

  // build the base marketplace url for correct env (dev, preprod, etc).
  const { host } = useSelector((state: AppState) => state.url);
  const marketplaceUrl = getAbsoluteMarketplaceUrl(domain, host);

  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const encodedPathWithParams = encodeURIComponent(pathname + '?' + stringify({ ...Object.fromEntries(searchParams), ...queryAddOns }));

  function handleLoginClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (hasFederatedLogin) {
      e.preventDefault();
      setFederatedLoginModalVisibility(true, {
        returnTo: encodedPathWithParams
      });
    }
    onClick && onClick(e);
  }

  // delegate login to a different domain, supply an absolute url back as a return_to param.
  if (delegateLoginTo) {
    return (
      /* content comes from props.children */
      /* eslint-disable-next-line jsx-a11y/anchor-has-content */
      <a href={`${delegateLoginTo}?openid.return_to=${encodeURIComponent(marketplaceUrl)}${encodedPathWithParams}`} {...rest} />
    );
  }

  // relative login link w/ returnToParam, allows passing `to` prop to override path.
  const loginPath = to || (hasFederatedLogin ? '/federated-login' : '/zap/preAuth/signin');
  return <Link to={`${loginPath}?openid.return_to=${encodedPathWithParams}`} onClick={handleLoginClick} {...rest} />;
};

const mapDispatchToProps = {
  setFederatedLoginModalVisibility
};

const connector = connect(null, mapDispatchToProps);
export default connector(LoginLink);
