import { marketplace } from 'helpers/MarketplaceSetupUtils';

export const SEARCH_LAYOUT_COOKIE = 'searchLayout';
export const NEVER_EXPIRE_COOKIE_TIME = '01-01-2100 00:00:00';
export const EXPIRE_IMMEDIATE_COOKIE_TIME = '01-01-1970 00:00:00';
export const NEWLY_REGISTERED_COOKIE = 'newlyRegistered';
export const OPAL_PROFILE_COOKIE = 'profile';
export const SEARCH_BEST_FOR_YOU_COOKIE = '__bfu';
export const DROP_COOKIE = 'zd';
export const SAVI_COOKIE = 'savi';
export const SAVI_SIZE_COOKIE = 'ss_savi';
export const SINGLE_SHOE_COOKIE = 'sisc';
export const HEADER_FALLBACK_COOKIE = 'hfc';
export const KRATOS_CHECKOUT_COOKIE = 'kratos-checkout';
export const GUEST_TOKEN = 'ac-main';
export const GUEST_CUSTOMER_ID = 'Guest-Customer-Id';
export const SESSION_ID = 'session-id';
export const SESSION_TOKEN = 'session-token';
export const UBID_MAIN = 'ubid-main';
export const EMAIL_TOKEN_COOKIE = 'emt-main';
export const EMAIL_TOKEN_SEARCH_PARAM = 'auth_token';
export const AT_MAIN = 'at-main';
export const SUPPRESS_MARKETING_POPUPS_COOKIE = 'suppressMarketing';
export const UNLEASH_FLAG_OVERRIDES = 'flags';

export const DEFAULT_COOKIE_PARAMS = {
  domain: `.${marketplace()}.com`,
  path: '/'
};

export const GUEST_COOKIE_PARAMS = {
  ...DEFAULT_COOKIE_PARAMS,
  httpOnly: true,
  secure: true
};
