import ExecutionEnvironment from 'exenv';

import botList from 'constants/bots';

const isAndroid = (ua: string) => /android/i.test(ua);
const isAndroidMobile = (ua: string) => /android.*mobile/i.test(ua);
const isIPad = (ua: string) => /ipad/i.test(ua);
const isIPhone = (ua: string) => /iphone/i.test(ua);
const isSupportedBlackBerryMobile = (ua: string) => /BB10.+Mobile/i.test(ua);

const SUPPORTED_MOBILE_DEVICES = [isAndroidMobile, isIPhone, isSupportedBlackBerryMobile];

const botListRegex = new RegExp(botList.join('|'), 'i');

export const isBotUser = (ua = '') => botListRegex.test(ExecutionEnvironment.canUseDOM ? navigator.userAgent : ua);

/**
 * Returns boolean if is mobile device based on user agent
 * @param  {string} ua
 * @return {boolean}
 */
export const isSupportedMobileDevice = (ua: string) => SUPPORTED_MOBILE_DEVICES.some(isSupported => isSupported(ua));

/*
  Returns one of ['android', 'iphone', 'ipad', undefined] given a user agent
  string.
*/
export function userAgentStringToPlatform(userAgent?: string) {
  userAgent = userAgent || (typeof navigator === 'undefined' ? '' : navigator.userAgent);
  if (isAndroid(userAgent)) {
    return 'android';
  } else if (isIPhone(userAgent)) {
    return 'iphone';
  } else if (isIPad(userAgent)) {
    return 'ipad';
  }
  return;
}
