import { call, put, select } from 'redux-saga/effects';

import { deletePaymentInstrument, getPaymentTypeList, savePaymentInstrument, setPrimaryPayment, verifyCard } from 'apis/checkout';
import { GET_PAYMENTS_SUCCESS, SET_PAYMENT_DATA_LOADING } from 'store/ducks/payment/types';
import { withSession } from 'store/ducks/session/sagas';
import { workSetPrimaryAddressIfNone } from 'store/ducks/address/sagas';
import { getMafiaAndCredentials } from 'store/ducks/readFromStore';

export function* workVerifyCreditCard(params) {
  const { mafiaConfig, credentials } = yield select(getMafiaAndCredentials);
  yield put({ type: SET_PAYMENT_DATA_LOADING, payload: true });
  const {
    payload: { status }
  } = yield call(withSession, [verifyCard, mafiaConfig, params, credentials]);
  return status;
}

export function* workRequestPayments(shippingAddressId) {
  const { mafiaConfig, credentials } = yield select(getMafiaAndCredentials);
  yield put({ type: SET_PAYMENT_DATA_LOADING, payload: true });
  const { payload } = yield call(withSession, [getPaymentTypeList, { mafiaConfig, shippingAddressId }, credentials]);
  yield put({ type: GET_PAYMENTS_SUCCESS, payload });
}

export function* workDeletePaymentInstrument(paymentInstrumentId) {
  const { mafiaConfig, credentials } = yield select(getMafiaAndCredentials);
  yield call(withSession, [deletePaymentInstrument, mafiaConfig, paymentInstrumentId, credentials]);
}

export function* workSavePaymentInstrument(params) {
  yield put({ type: SET_PAYMENT_DATA_LOADING, payload: true });
  const { instrument, clearPurchaseDoc } = params;
  const { mafiaConfig, credentials } = yield select(getMafiaAndCredentials);
  const { payload } = yield call(withSession, [savePaymentInstrument, mafiaConfig, params, credentials]);
  const { paymentInstrumentId, isPrimarySuccess, response } = payload;

  // if adding a new card, payload.paymentInstrumentId will be the paymentInstrumentId
  // if editing a card, payload.response will be the paymentInstrumentId
  if (paymentInstrumentId) {
    // if no primary ship address, then this setting to primary failed and we must set a primary address first
    if (!clearPurchaseDoc && instrument.isPrimary && !isPrimarySuccess) {
      yield call(workSetPrimaryAddressIfNone);
      yield call(withSession, [setPrimaryPayment, mafiaConfig, paymentInstrumentId, credentials]);
    }

    yield put({ type: SET_PAYMENT_DATA_LOADING, payload: false });
    return paymentInstrumentId;
  } else {
    yield put({ type: SET_PAYMENT_DATA_LOADING, payload: false });
    return response;
  }
}

export default [];
