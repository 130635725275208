// We converted this to typescript to workaround some build issues we were experiencing while upgrading to react 18
// This has a couple of global window and titanite types that need to be updated to remove the no check here.
// @ts-nocheck
import { createRoot, hydrateRoot } from 'react-dom/client';
import Modal from 'react-modal';
import cookie from 'cookie';
import { ConnectedRouter } from 'connected-react-router';
import ab from 'react-redux-hydra';
import ExecutionEnvironment from 'exenv';

import { NEVER_EXPIRE_COOKIE_TIME } from 'constants/cookies';
import { REACT_CONTAINER_ID } from 'constants/appConstants';
import { RENDER_ERROR, SET_COOKIE, STORE_ZFC_SESSION_ID } from 'constants/reduxActions';
import configureStore from 'store/configureStore';
import connectStoreToLocalStorage, { saveStoreToLocalStorage } from 'helpers/connectStoreToLocalStorage';
import { setupDataAttributeAnalytics } from 'helpers/analytics';
import { pageLoaded } from 'actions/pageLoad';
import marketplace from 'cfg/marketplace.json';
import { flushServerSideQueue, initTitanite, registerGetAssignmentsFn } from 'apis/amethyst';
import allShims from 'helpers/shims';
import historyFactory from 'history/historyFactory';
import { generateTid } from 'helpers/GenerateTid';
import { matchRouteConfig } from 'helpers/route/MatchRouteConfig';
import { listenForPageChange } from 'actions/pageview/listenForPageChange';
import { injectZfcSeoMetadata } from 'helpers/boostrapUtils';

const { cookieDomain } = marketplace;

const defaultOpts = {
  elementId: REACT_CONTAINER_ID,
  storeOptions: {
    rootSaga: undefined,
    reducers: {}
  }
};

export let store;

export default function bootstrapOnClient(RootComponentType, makeRoutes, opts = {}) {
  const initialState = window.__INITIAL_STATE__;
  initialState.cookies = cookie.parse(document.cookie);
  const { environmentConfig } = initialState;
  const { titaniteConfig } = environmentConfig;

  const appOptions = { ...defaultOpts, ...opts };
  const smartHistory = historyFactory();
  store = configureStore(initialState, smartHistory, appOptions.storeOptions);

  if (ExecutionEnvironment.canUseDOM) {
    // Gotta make sure shims are in place before any components mount
    allShims();
    injectZfcSeoMetadata(store);
  }

  /**
   * restore the ab test assignments in the state, from the cookie.
   * this is needed for cached pages since we're stripping out assignments before
   * serving the page from the server but shouldn't hurt on non-cached pages
   * so better to be consistent.
   */
  store.dispatch(ab.actions.fetchCookieAssignments());

  // restore zfcSessionId
  const zfcSessionId = ab.zfcSessionId();
  store.dispatch({ type: STORE_ZFC_SESSION_ID, zfcSessionId });

  initTitanite(titaniteConfig);

  /**
   * fire the server-side Amethyst event queue.
   * This must happen AFTER cookies have been loaded
   * */
  flushServerSideQueue(store);

  registerGetAssignmentsFn(() => {
    const {
      ab: { assignments }
    } = store.getState();
    return assignments;
  });

  store.runSaga();

  // ensure that tid cookie is set for janus recommendations
  let tidCookie = initialState.cookies.tid;
  if (!tidCookie) {
    const tidValue = generateTid();
    tidCookie = {
      name: 'tid',
      value: tidValue,
      options: {
        domain: cookieDomain,
        expires: new Date(NEVER_EXPIRE_COOKIE_TIME)
      }
    };
    store.dispatch({
      type: SET_COOKIE,
      cookie: tidCookie
    });
  }

  if (initialState.cookies.renderTestLocators) {
    window.renderTestLocators = true;
    disableBeacon();
  }

  const rootElement = document.getElementById(appOptions.elementId);

  const {
    storeOptions: { routesConfig = null, AppNode = null }
  } = appOptions;

  if (routesConfig) {
    const {
      location: { pathname }
    } = smartHistory;
    const renderProps = matchRouteConfig(routesConfig, pathname);
    const RootComponent = (
      <RootComponentType
        environmentConfig={initialState.environmentConfig}
        history={smartHistory}
        store={store}
        routes={makeRoutes(AppNode, routesConfig)}
        serverState={initialState}
      >
        <ConnectedRouter history={smartHistory}>{makeRoutes(AppNode, routesConfig)}</ConnectedRouter>
      </RootComponentType>
    );
    if (renderProps) {
      renderProps.component.load().then(comp => {
        renderProps.loadedComponent = comp?.default;
        if (initialState.error?.type === RENDER_ERROR) {
          // throw out the existing root node and render clean on the client.
          while (rootElement.lastChild) {
            rootElement.removeChild(rootElement.lastChild);
          }
          const root = createRoot(rootElement);
          root.render(RootComponent);
          connectStoreToLocalStorage(store);
        } else {
          const doHydrate = () => {
            hydrateRoot(rootElement, RootComponent);
            connectStoreToLocalStorage(store);
          };
          if (renderProps.loadedComponent.preHydrate) {
            renderProps.loadedComponent.preHydrate(store).then(doHydrate);
          } else {
            doHydrate();
          }
        }
      });
    }
  } else {
    // remote header footer
    const RootComponent = (
      <RootComponentType
        environmentConfig={initialState.environmentConfig}
        history={smartHistory}
        store={store}
        serverState={initialState}
      ></RootComponentType>
    );

    hydrateRoot(rootElement, RootComponent);

    connectStoreToLocalStorage(store);
  }

  setupDataAttributeAnalytics();

  smartHistory.listen(listenForPageChange(store));

  if (initialState.cookies.renderTestLocators) {
    window.store = store;
  }

  Modal.setAppElement(rootElement);

  window.addEventListener('load', () => {
    const { dispatch } = store;
    dispatch(pageLoaded());
  });

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      saveStoreToLocalStorage(store); // Save local storage before user leaves page
    }
  });
}

export type AppDispatch = typeof store.dispatch;

/**
 * This overrides XHR, beacon, and fetch request methods.
 * While mainting the original behavior this adds listeners to
 * record the relevent request data.
 * Note: browser.requestListener(); will need to be reinitialized every
 * hard page load so the window variables are overidden again.
 */
const disableBeacon = () => {
  // If there is a global titanite instance we can disable sendBeacon requests.
  // This way we can see what the resulting status code is.
  if (window.titanite && window.titanite.config) {
    window.titanite.config.enableSendBeacon = false;
  }
};
