import { createSelector } from 'reselect';

import { emptyObject } from 'selectors/constants';
import type { AppState } from 'types/app';
import type { Benefits } from 'types/rewards';

export const selectRewards = (state: AppState) => state.rewards || emptyObject;

export const selectRewardsInfo = createSelector(selectRewards, rewards => rewards?.rewardsInfo || emptyObject);

export const selectRewardsDetermined = (state: AppState) => selectRewards(state)?.isRewardsDetermined;

export const selectIsRewardsInfoLoaded = (state: AppState) => selectRewards(state)?.isRewardsInfoLoaded;

export const selectIsVip = (state: AppState) => selectRewardsInfo(state).isVipOrConsented;

export const selectRewardsBenefits = (state: AppState): Partial<Benefits> => selectRewardsInfo(state).benefits || emptyObject;

export const selectIsEnrolled = (state: AppState) => selectRewardsInfo(state)?.enrolled;

export const selectSharedRewards = (state: AppState) => state.sharedRewards;

export const selectIsEnrollingRewards = createSelector(selectSharedRewards, state => state.isEnrollingRewards);

export const selectIsVipOrEnrolling = createSelector([selectIsVip, selectIsEnrollingRewards], (isVip, isEnrolling) => Boolean(isVip || isEnrolling));
