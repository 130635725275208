// Meta component to not use react-router links when HF is remote
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { ABSOLUTE_URL_RE } from 'common/regex';

export const HFLink = ({ isRemote, to, children, forwardRef, innerRef, ...otherProps }) => {
  // React-router Link doesn't handle external urls as "to" so we do that here and just use an <a/>.
  if (isRemote || to?.match(ABSOLUTE_URL_RE)) {
    return (
      <a href={to || null} ref={forwardRef || innerRef} {...otherProps}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to || ''} ref={forwardRef} {...otherProps}>
      {children}
    </Link>
  );
};

function mapStateToProps(state) {
  return {
    isRemote: state?.headerFooter?.isRemote,
    origin: state?.url?.host
  };
}

const ConnectedHFLink = connect(mapStateToProps, {})(HFLink);
export default withErrorBoundary('HFLink', ConnectedHFLink);
