import React from 'react';
import { Link } from 'react-router-dom';
import HtmlToReact from 'html-to-react';

// @ts-ignore
import { checkIfRelativeUrl, cleanUp } from 'helpers';

const isValidNode = () => true;
const reactParser = new HtmlToReact.Parser();
const { processDefaultNode } = new HtmlToReact.ProcessNodeDefinitions(React);

const defaultParseInstruction = {
  shouldProcessNode() {
    return true;
  },
  processNode: processDefaultNode
};

// TODO : TS - type this
// convert 'a' tags to Link
const reactRouterParseInstruction = {
  shouldProcessNode(node: any) {
    return node.name === 'a' && checkIfRelativeUrl(node.attribs['href']);
  },
  processNode(node: any, children: any, index: number) {
    const { href } = node.attribs;
    // Let processDefaultNode do all the heavy lifting and then we'll steal
    // its props for our Link
    const reactComponent = processDefaultNode(node, children, index);
    return (
      <Link {...reactComponent.props} href={null} key={index} to={href}>
        {children}
      </Link>
    );
  }
};

// TODO : TS - type this
// Consider using HtmlToReact.jsx that leverages this
export function parseHtmlToReact(html: any, isRemote: boolean) {
  // convert gateway html to a react component, so that <a> tags become
  // react-router Links which we can navigate on the client
  const parseInstructions = isRemote ? [defaultParseInstruction] : [reactRouterParseInstruction, defaultParseInstruction];

  return reactParser.parseWithInstructions(cleanUp(html), isValidNode, parseInstructions);
}

/**
 * Plain parsing of nodes to react. This is best used when receiving text from an API that contains escaped html.
 * Using this will allow the display of the true text from the API without react double-encoding the output for display.
 * @param text
 * @returns React friendly content.
 */
export function parseTextToReact(text: string) {
  return reactParser.parse(text);
}

/**
 * Function used to convert style strings into style objects for react
 * @param styles - styles to convert to a styles object ( ex: "color:red;border:none" )
 * @returns {} - Object representation of the style string
 */
export const parseTextToStyle = (styles: string) =>
  styles
    ? styles.split(';').reduce((acc, styleString): {} => {
        const styleMap = styleString?.split(':');
        return { ...acc, ...{ [styleMap[0]?.trim() as string]: styleMap[1]?.trim() } };
      }, {})
    : null;
