/**
 * Wrapper button for the design library button to pass our router specific link
 */

import type { ExoticComponent } from 'react';
import { Link } from 'react-router-dom';
import { type ButtonProps, Button as DesignLibraryButton } from '@mweb/zappos-ui/Button';

const Button = ({ href, ...rest }: Omit<ButtonProps, 'linkComponent' | 'linkProps'>) => (
  <DesignLibraryButton {...rest} href={href} linkComponent={Link as ExoticComponent} linkProps={{ to: href }} />
);

export default Button;
