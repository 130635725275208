import { call, put, takeEvery } from 'redux-saga/effects';

import timedFetch from 'middleware/timedFetch';
import { FETCH_EMERGENCY_BANNER, SET_EMERGENCY_BANNER } from 'store/ducks/emergencyBanner/types';
import marketplace from 'cfg/marketplace.json';

const { emergencyBannerUrl } = marketplace;

export function fetchBannerData(bannerUrl = emergencyBannerUrl, fetcher = timedFetch('emergencyBanner')) {
  return fetcher(bannerUrl)
    .then(res => {
      if (res.status !== 200) {
        return {}; // returning {} causes component to unmount, which it should.
      } else {
        // 200 means we got the banner data, so return it.
        return res.json();
      }
    })
    .catch(() => {});
}

export function* workFetchEmergencyBanner() {
  try {
    const data = yield call(fetchBannerData);
    yield put({ type: SET_EMERGENCY_BANNER, payload: data });
  } catch (error) {
    yield put({ type: SET_EMERGENCY_BANNER, payload: {} });
  }
}
export function* watchFetchEmergencyBanner() {
  yield takeEvery(FETCH_EMERGENCY_BANNER, workFetchEmergencyBanner);
}

export default [watchFetchEmergencyBanner];
