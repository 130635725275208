import { LOCATION_UPDATED } from 'constants/reduxActions';

export function listenForPageChange(store) {
  return function (location, action) {
    // Dispatch a custom locationchange event to tell HF that we are no longer on the same page
    location.action = action;
    const event = new CustomEvent('locationchange', {
      detail: { location: location }
    });
    document.body.dispatchEvent(event);
    // Update store
    store.dispatch({ type: LOCATION_UPDATED, location });
  };
}
