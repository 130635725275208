import { weakMapMemoize } from 'reselect';

import type { AppState } from 'types/app';

function selectHeartStyleInfo(state: AppState, styleId: string) {
  const heartIndex = state?.hearts?.heartsStyleIds?.indexOf(styleId);
  return {
    count: heartIndex > -1 ? state?.products?.heartsList?.[styleId] : (0 as number),
    hearted: heartIndex > -1
  };
}

export const selectHeartStyleInfoFactory = weakMapMemoize((styleId: string) => (state: AppState) => selectHeartStyleInfo(state, styleId));
