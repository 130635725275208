import PropTypes from 'prop-types';
import { deepEqual } from 'fast-equals';
import React from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import MelodyModal from 'components/common/MelodyModal';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/hf/signUpModal.scss';

const emailheadingFallback = 'Be the first to know about exclusive offers and new arrivals!';
const emailcopyFallback = 'Sign me up for Zappos emails.';
const headingFallback = 'Thanks for coming to our party!';

export const SignUpModal = ({ data, setSignUpModal, isSignUpModalOpen, handleSignUpModalSubmit }) => {
  const { testId } = useMartyContext();

  if (data?.componentName === 'melodySignUpModal') {
    const { cta, emailheading, heading, emailcopy } = data;

    return (
      <MelodyModal
        buttonTestId="closeModal"
        className={cn(css.modalContent, { [css.fade]: true })}
        heading={heading || headingFallback}
        isOpen={isSignUpModalOpen}
        onRequestClose={() => setSignUpModal(false)}
        wrapperTestId={testId('headerFooterSignUpModal')}
      >
        <form method="post" className={css.mSignUpModal} onSubmit={handleSignUpModalSubmit}>
          <div className={css.content}>
            {
              <div className={css.selectionBlock}>
                <input defaultChecked={true} id="signupModalEmail" name="email" type="checkbox" />
                <label htmlFor="signupModalEmail">
                  <div>
                    <p className={css.contentHeading}>{emailheading || emailheadingFallback}</p>
                    <p className={css.contentCopy}>{emailcopy || emailcopyFallback}</p>
                  </div>
                </label>
              </div>
            }
          </div>
          <div className={css.footer}>
            <button className={css.btn} type="submit" data-test-id={testId('signupBtn')}>
              {cta || 'Submit'}
            </button>
          </div>
        </form>
      </MelodyModal>
    );
  }
  return null;
};

SignUpModal.propTypes = {
  data: PropTypes.object,
  setSignUpModal: PropTypes.func.isRequired,
  isSignUpModalOpen: PropTypes.bool.isRequired,
  handleSignUpModalSubmit: PropTypes.func.isRequired
};

export default withErrorBoundary('SignUpModal', React.memo(SignUpModal, deepEqual));
