import { SET_HF_LAYOUT, SET_LAYOUT, SET_RESIZE, SET_TOUCH_DETECTED } from './actions';

import type { DevicePropsState } from 'store/ducks/deviceProps/types';

const initialState: DevicePropsState = {
  viewportWidth: null,
  viewportHeight: null,
  screenWidth: null,
  screenHeight: null,
  layout: null,
  hfLayout: null,
  touchDetected: false
};

// Reducer
const reducer = (state = initialState, action: any): DevicePropsState => {
  switch (action.type) {
    case SET_RESIZE:
      return {
        ...state,
        viewportWidth: action.payload.viewportWidth,
        viewportHeight: action.payload.viewportHeight,
        screenWidth: action.payload.screenWidth,
        screenHeight: action.payload.screenHeight
      };
    case SET_TOUCH_DETECTED:
      return {
        ...state,
        touchDetected: action.payload.touchDetected
      };
    case SET_LAYOUT:
      return {
        ...state,
        layout: action.payload
      };
    case SET_HF_LAYOUT:
      return {
        ...state,
        hfLayout: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
