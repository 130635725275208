import type { MutableRefObject } from 'react';
import React, { useMemo } from 'react';
import { Content, Group, Item, Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';

import useMartyContext from 'hooks/useMartyContext';
import AccountIcon from 'components/hf/zappos/AccountIcon';
import { useAccountMenu } from 'hooks/useAccountMenu';
import type { AccountMenuItem } from 'types/navigation';
import useMyAccountRedesignEnabled from 'hooks/useMyAccountRedesignEnabled';

const contentClasses = `
  relative
  right-0
  isolate
  z-50
  overflow-hidden
  rounded-2xl
  bg-default-minimal
  p-1
  shadow-[2px_8px_16px_rgba(0,0,0,0.12)]
`;

export const DropdownItem = ({ dataTestId, Icon, link, text, onClick }: AccountMenuItem) => {
  const { testId } = useMartyContext();

  return (
    <Item asChild key={text}>
      <a
        aria-disabled
        aria-label={text}
        className="flex w-40 items-center justify-between p-2 transition-colors hover:bg-default-subtle"
        data-test-id={testId(dataTestId)}
        href={link}
        onClick={onClick}
      >
        <span>{text}</span>
        {Icon && <Icon size={24} />}
      </a>
    </Item>
  );
};

interface AccountDropdownProps {
  container: MutableRefObject<HTMLDivElement>;
}

const AccountDropdown = ({ container }: AccountDropdownProps) => {
  const { testId } = useMartyContext();
  const accountMenu = useAccountMenu();
  const accountDropdownMenu = useMemo(
    () => accountMenu.map(menuItem => <DropdownItem key={menuItem.dataTestId} {...menuItem} />),
    [accountMenu.length]
  );
  useMyAccountRedesignEnabled();
  return (
    <Root>
      <Trigger asChild>
        <AccountIcon />
      </Trigger>
      <Portal container={container.current}>
        <Content className={contentClasses} align="end" avoidCollisions={false} side="bottom">
          <Group className="flex flex-col gap-y-1" data-test-id={testId('headerMyAccountDropdown')}>
            {accountDropdownMenu}
          </Group>
        </Content>
      </Portal>
    </Root>
  );
};

export default AccountDropdown;
