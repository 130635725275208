import { call, put, select, takeLatest } from 'redux-saga/effects';
import { stringify } from 'query-string';

import { trackError } from 'helpers/ErrorUtils';
import {
  BEGIN_FETCH_SYMPHONY_SEARCH_COMPONENTS,
  FETCH_SYMPHONY_SEARCH_COMPONENTS_ERROR,
  RECEIVE_SYMPHONY_SEARCH_COMPONENTS
} from 'store/ducks/search/types';
import { selectZcsConfig } from 'selectors/environment';
import timedFetch from 'middleware/timedFetch';
import { addClientHeaders, fetchOpts } from 'apis/mafia/common';

export function getSymphonySearchComponents(
  { url },
  { term, path },
  credentials = {},
  request = {},
  fetcher = timedFetch('getSymphonySearchComponents')
) {
  const queryObj = {
    pageName: term,
    pageLayout: 'search'
  };

  if (path) {
    if (path.includes('.zso')) {
      queryObj['ProductSearchZso'] = path;
    } else {
      queryObj['ProductSearchTerm'] = path;
    }
  }
  const query = stringify(queryObj);
  const reqUrl = `${url}/zcs/getSlots?${query}`;
  const headers = addClientHeaders(request);
  return fetcher(reqUrl, fetchOpts({ headers }, credentials));
}

export function* workFetchSymphonySearchComponents() {
  try {
    const {
      client: { request },
      cookies,
      filters: { term, executedSearchUrl }
    } = yield select();
    const zcsConfig = yield select(selectZcsConfig);
    const response = yield call(getSymphonySearchComponents, zcsConfig, { term: term || 'null', path: executedSearchUrl }, cookies, request);
    const data = yield response.json();
    yield put({
      type: RECEIVE_SYMPHONY_SEARCH_COMPONENTS,
      payload: { data, term }
    });
  } catch (error) {
    yield put({
      type: FETCH_SYMPHONY_SEARCH_COMPONENTS_ERROR,
      payload: { error }
    });
    yield call(trackError, 'NON-FATAL', 'Could not retrieve symphony search components', error);
  }
}

export function* watchFetchSymphonySearchComponents() {
  yield takeLatest(BEGIN_FETCH_SYMPHONY_SEARCH_COMPONENTS, workFetchSymphonySearchComponents);
}

export default [watchFetchSymphonySearchComponents];
