import ExecutionEnvironment from 'exenv';
import queryString from 'query-string';

import { pluralize } from '.';

import { DELIVERY_PROMISE_TYPE } from 'common/regex';
import marketplace from 'cfg/marketplace.json';
import { toUSD } from 'helpers/NumberFormats';
import { toArray } from 'helpers/lodashReplacement';
import { MS_IN_HR, MS_IN_MIN } from 'helpers/TimeUtils';
import { isDesktop, removeFromSessionStorage } from 'helpers/ClientUtils';
import { track } from 'apis/amethyst';
import { REVIEW_STEP } from 'constants/checkoutFlow';
import { ADDRESS_FIELDS, PAYMENT_FIELDS } from 'constants/formFields';
import { CV_MISSING_SHIPPING_LIST, CV_WHITE_LIST, USE_PROMO_BALANCE_SESSION_STORAGE_KEY } from 'constants/appConstants';
import { MAFIA_CLIENT_DESKTOP_PREFIX, MAFIA_CLIENT_MOBILE_PREFIX, MAFIA_CLIENT_ZPOT_PREFIX } from 'constants/mafiaClient';
import {
  ADDRESS,
  ADDRESS_ASSOCIATION,
  AMAZON_PAY_CONFIRMATION_REQUIRED,
  BAD_PROMO_CODE_OR_GIFT_CARD,
  CART_QUANTITY_LIMIT,
  CROSS_BORDER_EXPORT,
  DELIVERY_INFORMATION_MISSING,
  DELIVERY_OPTION,
  DESTINATION_INFORMATION_MISSING,
  DROP_PRODUCT_DIRECT_FULFILLMENT,
  DROP_PRODUCT_NOT_AVAILABLE,
  DROP_PRODUCT_PURCHASE_AUTHORIZATION_MISSING,
  DROP_PRODUCT_QUANTITY_LIMIT,
  EXPIRED_INSTRUMENT,
  EXPORT_OFFER_BUYABILITY_RESTRICTION,
  FULLFILMENT_NETWORK_MISSING,
  GC_PAYMENT_METHOD_NOT_ALLOWED,
  GC_QUANTITY,
  GIFTCARD_CODE_ALREADY_REDEMEED,
  GIFTCARD_CODE_CANCELLED,
  GIFTCARD_CODE_EXPIRED,
  GIFTCARD_CODE_INVALID,
  INACTIVE_INSTRUMENT,
  INACTIVE_INSTRUMENT_BILLING_ADDRESS_VIOLATION,
  INSUFFICIENT_COVERAGE,
  INVALID_COUPON_BALANCE,
  INVALID_GIFT_OPTION,
  INVALID_SELECTED_DELIVERY_OPTION_FOR_LINE_ITEM,
  ITEM_IN_CART_WITH_NO_SHIP_OPTIONS,
  ITEM_IN_CART_WITH_PROBLEM,
  ITEM_QUANTITY_UNAVAILABLE,
  JURISDICTION,
  LEGACY_ORDER_LEVEL_BILLING_ADDRESS_MISSING,
  MISSING_DIGITAL_DELIVERY_INFORMATION,
  NO_SHIP_OPTIONS,
  OFFER_LISTING_AND_OFFER_SKU_DIFFER,
  OFFER_LISTING_NOT_AVAILABLE_CONSTRAINT_VIOLATION,
  PAYMENT_METHOD,
  PAYMENT_METHODS_COMBINATION,
  PAYMENT_PLAN_MISSING,
  PRODUCT_MISSING,
  PROMOTIONAL_CODE_ALREADY_REDEEMED,
  PROMOTIONAL_CODE_BAD,
  PROMOTIONAL_CODE_EXPIRED,
  PROMOTIONAL_CODE_INVALID_FOR_PURCHASE,
  PROMOTIONAL_CODE_MISSING,
  PROMOTIONAL_CODE_USED_BEFORE_START_DATE,
  QUANTITY_LIMITS,
  SHIP_ADDRESS_DEACTIVATED,
  SHIPPING_ENGINE_FILTER_BASED,
  SHIPPING_ENGINE_PROVIDER_BASED,
  SHIPPING_ENGINE_REMOVER_BASED,
  SHIPPING_ENGINE_SHIPPING_OFFERING_NOT_SET,
  UNDELIVERABLE,
  VALID_PAYMENT_METHOD_REQUIRED
} from 'constants/constraintViolations';
import {
  ZAPPOS_FREE_HOLIDAY_SHIPPING,
  ZAPPOS_LEGACY_VIP,
  ZAPPOS_LWA_PLUS_PRIME,
  ZAPPOS_LWA_PRIME_NO_VIP_AT_CHECKOUT,
  ZAPPOS_MOBILE_PROMO,
  ZAPPOS_NEW_VIP,
  ZAPPOS_PLAIN,
  ZAPPOS_PLAIN_NO_VIP_AT_CHECKOUT,
  ZAPPOS_REWARDS_ELITE,
  ZAPPOS_REWARDS_GOLD,
  ZAPPOS_REWARDS_PLATINUM,
  ZAPPOS_REWARDS_SILVER,
  ZAPPOS_REWARDS_SILVER_AS_GOLD,
  ZAPPOS_VIP_AT_CHECKOUT
} from 'constants/shippingBenefitReasons';
import {
  DIGITAL_GC_ONLY_CART,
  MIXED_WITH_BOTH_GC,
  MIXED_WITH_EGC,
  MIXED_WITH_PHYSICAL,
  NON_MIXED_WITH_BOTH_GC,
  PHYSICAL_GC_ONLY_CART,
  RETAIL_ONLY_CART
} from 'constants/cartTypes';
import { AMAZON_PAY, CREDIT_CARD } from 'constants/paymentMethodTypes';
import { evCheckoutClientError } from 'events/checkout';
import { CHECKOUT_ERROR_FIELD } from 'constants/amethystEnums';
import type { Marketplace } from 'types/app';
import { inIframe } from 'helpers/InIframe';

const {
  checkout: { shippingCountriesWhitelist },
  subsiteId: { desktop: desktopSubsiteId, mobile: mobileSubsiteId }
} = marketplace as Marketplace;

export function getEventCvFromCv(cv: string) {
  switch (cv) {
    case PAYMENT_METHODS_COMBINATION:
      return 'PAYMENT_METHODS_COMBINATION';
    case CROSS_BORDER_EXPORT:
      return 'CROSS_BORDER_EXPORT';
    case EXPORT_OFFER_BUYABILITY_RESTRICTION:
      return 'EXPORT_OFFER_BUYABILITY_RESTRICTION';
    case ITEM_IN_CART_WITH_NO_SHIP_OPTIONS:
      return 'ITEM_IN_CART_WITH_NO_SHIP_OPTIONS';
    case ITEM_IN_CART_WITH_PROBLEM:
      return 'ITEM_IN_CART_WITH_PROBLEM';
    case BAD_PROMO_CODE_OR_GIFT_CARD:
      return 'BAD_PROMO_CODE_OR_GIFT_CARD';
    case NO_SHIP_OPTIONS:
      return 'NO_SHIP_OPTIONS';
    case INVALID_GIFT_OPTION:
      return 'INVALID_GIFT_OPTION';
    case DELIVERY_OPTION:
      return 'DELIVERY_OPTION';
    case GC_PAYMENT_METHOD_NOT_ALLOWED:
      return 'GC_PAYMENT_METHOD_NOT_ALLOWED';
    case ADDRESS_ASSOCIATION:
      return 'ADDRESS_ASSOCIATION';
    case DESTINATION_INFORMATION_MISSING:
      return 'DESTINATION_INFORMATION_MISSING';
    case DELIVERY_INFORMATION_MISSING:
      return 'DELIVERY_INFORMATION_MISSING';
    case INACTIVE_INSTRUMENT_BILLING_ADDRESS_VIOLATION:
      return 'INACTIVE_INSTRUMENT_BILLING_ADDRESS_VIOLATION';
    case INSUFFICIENT_COVERAGE:
      return 'INSUFFICIENT_COVERAGE';
    case INVALID_SELECTED_DELIVERY_OPTION_FOR_LINE_ITEM:
      return 'INVALID_SELECTED_DELIVERY_OPTION_FOR_LINE_ITEM';
    case PROMOTIONAL_CODE_ALREADY_REDEEMED:
      return 'PROMOTIONAL_CODE_ALREADY_REDEEMED';
    case PROMOTIONAL_CODE_EXPIRED:
      return 'PROMOTIONAL_CODE_EXPIRED';
    case PROMOTIONAL_CODE_USED_BEFORE_START_DATE:
      return 'PROMOTIONAL_CODE_USED_BEFORE_START_DATE';
    case PROMOTIONAL_CODE_INVALID_FOR_PURCHASE:
      return 'PROMOTIONAL_CODE_INVALID_FOR_PURCHASE';
    case PROMOTIONAL_CODE_BAD:
      return 'PROMOTIONAL_CODE_BAD';
    case SHIPPING_ENGINE_REMOVER_BASED:
      return 'SHIPPING_ENGINE_REMOVER_BASED';
    case LEGACY_ORDER_LEVEL_BILLING_ADDRESS_MISSING:
      return 'LEGACY_ORDER_LEVEL_BILLING_ADDRESS_MISSING';
    case ITEM_QUANTITY_UNAVAILABLE:
      return 'ITEM_QUANTITY_UNAVAILABLE';
    case OFFER_LISTING_NOT_AVAILABLE_CONSTRAINT_VIOLATION:
      return 'OFFER_LISTING_NOT_AVAILABLE_CONSTRAINT_VIOLATION';
    case SHIPPING_ENGINE_FILTER_BASED:
      return 'SHIPPING_ENGINE_FILTER_BASED';
    case PAYMENT_PLAN_MISSING:
      return 'PAYMENT_PLAN_MISSING';
    case INACTIVE_INSTRUMENT:
      return 'INACTIVE_INSTRUMENT';
    case EXPIRED_INSTRUMENT:
      return 'EXPIRED_INSTRUMENT';
    case JURISDICTION:
      return 'JURISDICTION';
    case PAYMENT_METHOD:
      return 'PAYMENT_METHOD';
    case QUANTITY_LIMITS:
      return 'QUANTITY_LIMITS';
    case ADDRESS:
      return 'ADDRESS';
    case SHIPPING_ENGINE_PROVIDER_BASED:
      return 'SHIPPING_ENGINE_PROVIDER_BASED';
    case SHIPPING_ENGINE_SHIPPING_OFFERING_NOT_SET:
      return 'SHIPPING_ENGINE_SHIPPING_OFFERING_NOT_SET';
    case FULLFILMENT_NETWORK_MISSING:
      return 'FULLFILMENT_NETWORK_MISSING';
    case INVALID_COUPON_BALANCE:
      return 'INVALID_COUPON_BALANCE';
    case OFFER_LISTING_AND_OFFER_SKU_DIFFER:
      return 'OFFER_LISTING_AND_OFFER_SKU_DIFFER';
    case PRODUCT_MISSING:
      return 'PRODUCT_MISSING';
    case MISSING_DIGITAL_DELIVERY_INFORMATION:
      return 'MISSING_DIGITAL_DELIVERY_INFORMATION';
    case GIFTCARD_CODE_ALREADY_REDEMEED:
      return 'GIFTCARD_CODE_ALREADY_REDEMEED';
    case GIFTCARD_CODE_CANCELLED:
      return 'GIFTCARD_CODE_CANCELLED';
    case GIFTCARD_CODE_EXPIRED:
      return 'GIFTCARD_CODE_EXPIRED';
    case GIFTCARD_CODE_INVALID:
      return 'GIFTCARD_CODE_INVALID';
    case VALID_PAYMENT_METHOD_REQUIRED:
      return 'VALID_PAYMENT_METHOD_REQUIRED';
    case SHIP_ADDRESS_DEACTIVATED:
      return 'SHIP_ADDRESS_DEACTIVATED';
    case PROMOTIONAL_CODE_MISSING:
      return 'PROMOTIONAL_CODE_MISSING';
    case GC_QUANTITY:
      return 'GC_QUANTITY';
    case CART_QUANTITY_LIMIT:
      return 'CART_QUANTITY_LIMIT';
    case DROP_PRODUCT_NOT_AVAILABLE:
      return 'DROP_PRODUCT_NOT_AVAILABLE';
    case DROP_PRODUCT_PURCHASE_AUTHORIZATION_MISSING:
      return 'DROP_PRODUCT_PURCHASE_AUTHORIZATION_MISSING';
    case DROP_PRODUCT_QUANTITY_LIMIT:
      return 'DROP_PRODUCT_QUANTITY_LIMIT';
    case DROP_PRODUCT_DIRECT_FULFILLMENT:
      return 'DROP_PRODUCT_DIRECT_FULFILLMENT';
    case UNDELIVERABLE:
      return 'UNDELIVERABLE';
    default:
      return 'UNKNOWN_CONSTRAINT_VIOLATION_TYPE';
  }
}

export function isZapposShipping(shippingBenefitReason: number) {
  return (
    shippingBenefitReason === ZAPPOS_PLAIN ||
    shippingBenefitReason === ZAPPOS_PLAIN_NO_VIP_AT_CHECKOUT ||
    shippingBenefitReason === ZAPPOS_MOBILE_PROMO ||
    shippingBenefitReason === ZAPPOS_REWARDS_SILVER ||
    shippingBenefitReason === ZAPPOS_FREE_HOLIDAY_SHIPPING ||
    shippingBenefitReason === ZAPPOS_LWA_PLUS_PRIME ||
    shippingBenefitReason === ZAPPOS_LWA_PRIME_NO_VIP_AT_CHECKOUT ||
    shippingBenefitReason === ZAPPOS_REWARDS_GOLD ||
    shippingBenefitReason === ZAPPOS_LEGACY_VIP ||
    shippingBenefitReason === ZAPPOS_REWARDS_PLATINUM ||
    shippingBenefitReason === ZAPPOS_REWARDS_ELITE ||
    shippingBenefitReason === ZAPPOS_REWARDS_SILVER_AS_GOLD ||
    shippingBenefitReason === ZAPPOS_VIP_AT_CHECKOUT ||
    shippingBenefitReason === ZAPPOS_NEW_VIP
  );
}

export function isBaseZapposShipping(shippingBenefitReason: number) {
  return (
    shippingBenefitReason === ZAPPOS_PLAIN ||
    shippingBenefitReason === ZAPPOS_PLAIN_NO_VIP_AT_CHECKOUT ||
    shippingBenefitReason === ZAPPOS_MOBILE_PROMO ||
    shippingBenefitReason === ZAPPOS_REWARDS_SILVER
  );
}

const FREE_SHIPPING_SPEEDS = new Set(['next-wow', 'std-us-non48', 'std-n-us', 'std-us-military', 'std-us-protect', 'std-us']);
export const isFreeShippingSpeed = (shipmentSpeed: string) => FREE_SHIPPING_SPEEDS.has(shipmentSpeed);

const NEXT_BUSINESS_SHIPPING_SPEEDS = new Set(['next', 'next-business']);
export const isNextBusinessShippingSpeed = (shipmentSpeed: string) => NEXT_BUSINESS_SHIPPING_SPEEDS.has(shipmentSpeed);

export function isFreeHolidayShipping(shippingBenefitReason: number) {
  return shippingBenefitReason === ZAPPOS_FREE_HOLIDAY_SHIPPING;
}

export function isTwoDayPrimeShippingPerk(shippingBenefitReason: number) {
  return shippingBenefitReason === ZAPPOS_LWA_PLUS_PRIME || shippingBenefitReason === ZAPPOS_LWA_PRIME_NO_VIP_AT_CHECKOUT;
}

export function isOneDayVipShippingPerk(shippingBenefitReason: number) {
  return (
    shippingBenefitReason === ZAPPOS_REWARDS_GOLD ||
    shippingBenefitReason === ZAPPOS_LEGACY_VIP ||
    shippingBenefitReason === ZAPPOS_REWARDS_PLATINUM ||
    shippingBenefitReason === ZAPPOS_REWARDS_ELITE ||
    shippingBenefitReason === ZAPPOS_REWARDS_SILVER_AS_GOLD ||
    shippingBenefitReason === ZAPPOS_VIP_AT_CHECKOUT ||
    shippingBenefitReason === ZAPPOS_NEW_VIP
  );
}

export function isVipAtCheckoutSelected(shippingBenefitReason: number, shipmentSpeed: string) {
  return !inIframe() && !!shipmentSpeed && shippingBenefitReason === ZAPPOS_VIP_AT_CHECKOUT;
}

export function determineShippingBenefitReason(reason: number) {
  switch (reason) {
    case ZAPPOS_PLAIN:
      return 'ZAPPOS_PLAIN';
    case ZAPPOS_LEGACY_VIP:
      return 'ZAPPOS_LEGACY_VIP';
    case ZAPPOS_MOBILE_PROMO:
      return 'ZAPPOS_MOBILE_PROMO';
    case ZAPPOS_REWARDS_SILVER:
      return 'ZAPPOS_REWARDS_SILVER';
    case ZAPPOS_REWARDS_GOLD:
      return 'ZAPPOS_REWARDS_GOLD';
    case ZAPPOS_REWARDS_PLATINUM:
      return 'ZAPPOS_REWARDS_PLATINUM';
    case ZAPPOS_REWARDS_ELITE:
      return 'ZAPPOS_REWARDS_ELITE';
    case ZAPPOS_REWARDS_SILVER_AS_GOLD:
      return 'ZAPPOS_REWARDS_SILVER_AS_GOLD';
    case ZAPPOS_LWA_PLUS_PRIME:
      return 'ZAPPOS_LWA_PLUS_PRIME';
    case ZAPPOS_NEW_VIP:
      return 'ZAPPOS_NEW_VIP';
    case ZAPPOS_PLAIN_NO_VIP_AT_CHECKOUT:
      return 'ZAPPOS_PLAIN_NO_VIP_AT_CHECKOUT';
    case ZAPPOS_LWA_PRIME_NO_VIP_AT_CHECKOUT:
      return 'ZAPPOS_LWA_PRIME_NO_VIP_AT_CHECKOUT';
    case ZAPPOS_VIP_AT_CHECKOUT:
      return 'ZAPPOS_VIP_AT_CHECKOUT';
    case ZAPPOS_FREE_HOLIDAY_SHIPPING:
      return 'ZAPPOS_FREE_HOLIDAY_SHIPPING';
    default:
      return 'UNKNOWN_SHIPPING_BENEFIT_REASON';
  }
}

export function buildCheckoutErrorQueryString(params: object) {
  return buildErrorQueryString('martyCheckoutError', params);
}

export function buildErrorQueryString(type: string, params: object) {
  return queryString.stringify({ type, ...params });
}

type ConstraintViolation<T = unknown> = Record<string, T>;

export const isAmazonPayConfirmationRequired = (cvs: ConstraintViolation[]) =>
  Array.isArray(cvs) && !!cvs.find(cv => cv.name === AMAZON_PAY_CONFIRMATION_REQUIRED);

export const makeDeliveryMessage = (deliveryPromise: string) =>
  deliveryPromise?.length ? deliveryPromise : "We're experiencing shipping delays. We'll email when your order ships.";

export function hasCartQuantityLimit(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(CART_QUANTITY_LIMIT);
}

export function hasGcQuantity(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(GC_QUANTITY);
}

export function hasFraudCheckAroundPromoCode(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(INVALID_COUPON_BALANCE);
}

export function hasExpiredInstrument(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(EXPIRED_INSTRUMENT);
}

export function hasInactiveInstrument(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(INACTIVE_INSTRUMENT);
}

export function hasGenericProblemWithPayment(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(PAYMENT_METHOD);
}

export function hasInvalidShippingOptions(cvs: ConstraintViolation) {
  return (
    cvs.hasOwnProperty(INVALID_SELECTED_DELIVERY_OPTION_FOR_LINE_ITEM) ||
    cvs.hasOwnProperty(SHIPPING_ENGINE_FILTER_BASED) ||
    cvs.hasOwnProperty(DELIVERY_OPTION) ||
    cvs.hasOwnProperty(SHIPPING_ENGINE_SHIPPING_OFFERING_NOT_SET) ||
    cvs.hasOwnProperty(DROP_PRODUCT_DIRECT_FULFILLMENT) ||
    cvs.hasOwnProperty(UNDELIVERABLE)
  );
}

export function isShippableAddress(countryCode: string) {
  return shippingCountriesWhitelist.includes(countryCode);
}

export function needsShippingOptions(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(DELIVERY_INFORMATION_MISSING);
}

export function isMissingFulfillmentNetwork(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(FULLFILMENT_NETWORK_MISSING);
}

export function needsValidShippingAddress(cvs: ConstraintViolation) {
  return (
    cvs.hasOwnProperty(DESTINATION_INFORMATION_MISSING) ||
    cvs.hasOwnProperty(ADDRESS) ||
    cvs.hasOwnProperty(EXPORT_OFFER_BUYABILITY_RESTRICTION) ||
    cvs.hasOwnProperty(CROSS_BORDER_EXPORT) ||
    cvs.hasOwnProperty(SHIP_ADDRESS_DEACTIVATED) ||
    cvs.hasOwnProperty(SHIPPING_ENGINE_REMOVER_BASED)
  );
}

export function hasInvalidGiftOptions(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(INVALID_GIFT_OPTION);
}

export function hasInvalidGiftOptionsFromConstraintArray(cvs: ConstraintViolation[] = []) {
  return !!cvs.find(item => item.name === INVALID_GIFT_OPTION);
}

export function isGeneralAddressContstraintPresent(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(ADDRESS);
}

export function isMissingShippingDestination(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(DESTINATION_INFORMATION_MISSING);
}

export function needsValidPayment(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(INSUFFICIENT_COVERAGE) || cvs.hasOwnProperty(PAYMENT_PLAN_MISSING) || cvs.hasOwnProperty(VALID_PAYMENT_METHOD_REQUIRED);
}

export function needsValidBillingAddress(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(INACTIVE_INSTRUMENT_BILLING_ADDRESS_VIOLATION) || cvs.hasOwnProperty(LEGACY_ORDER_LEVEL_BILLING_ADDRESS_MISSING);
}

export function needsToReAssociatePaymentToAddressForPaymentForPurchaseCvs(cvs: ConstraintViolation, paymentInstrumentId: string) {
  return Array.isArray(cvs) && !!cvs.find(cv => cv.name === ADDRESS_ASSOCIATION && cv.paymentInstrumentId === paymentInstrumentId);
}

export function needsToReAssociatePaymentToAddress(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(ADDRESS_ASSOCIATION);
}

export function needsToReAssociatePaymentToAddressForPayment(cvs: ConstraintViolation, paymentInstrumentId: string) {
  return (
    needsToReAssociatePaymentToAddress(cvs) &&
    (cvs as { [ADDRESS_ASSOCIATION]: { paymentInstrumentId: string } })[ADDRESS_ASSOCIATION].paymentInstrumentId === paymentInstrumentId
  );
}

export function needsDigitalDeliveryInformation(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(MISSING_DIGITAL_DELIVERY_INFORMATION);
}

export function hasAmazonCatalogIssue(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(OFFER_LISTING_AND_OFFER_SKU_DIFFER);
}

export function hasProblemWithItemInCart(cvs: ConstraintViolation) {
  return (
    cvs.hasOwnProperty(JURISDICTION) ||
    cvs.hasOwnProperty(ITEM_QUANTITY_UNAVAILABLE) ||
    cvs.hasOwnProperty(OFFER_LISTING_NOT_AVAILABLE_CONSTRAINT_VIOLATION) ||
    cvs.hasOwnProperty(QUANTITY_LIMITS) ||
    cvs.hasOwnProperty(SHIPPING_ENGINE_PROVIDER_BASED) ||
    cvs.hasOwnProperty(GC_QUANTITY) ||
    cvs.hasOwnProperty(DROP_PRODUCT_NOT_AVAILABLE) ||
    cvs.hasOwnProperty(DROP_PRODUCT_PURCHASE_AUTHORIZATION_MISSING) ||
    cvs.hasOwnProperty(DROP_PRODUCT_QUANTITY_LIMIT) ||
    isMissingFulfillmentNetwork(cvs)
  );
}

export function isMissingProducts(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(PRODUCT_MISSING);
}

export function hasAsinLevelProblem(cvs: ConstraintViolation) {
  return hasProblemWithItemInCart(cvs) || hasInvalidShippingOptions(cvs);
}

export function isTryingToBuyGiftCardWithGiftCard(cvs: ConstraintViolation) {
  return cvs.hasOwnProperty(GC_PAYMENT_METHOD_NOT_ALLOWED);
}

export function isPlacingOrderBlocked(cvs: ConstraintViolation | ConstraintViolation[]) {
  return (cvs ? toArray(cvs) : []).some((cv: any) => !CV_WHITE_LIST.includes(cv.name));
}

export function isPlacingOrderBlockedByShipping(cvs: ConstraintViolation) {
  const cvArray = cvs ? toArray(cvs) : [];
  const filteredCvs = cvArray.filter((cv: any) => CV_MISSING_SHIPPING_LIST.includes(cv.name));
  const expectCvsSet = new Set(filteredCvs.map(cv => cv.name));
  const expectedCvs = [...expectCvsSet];
  return CV_MISSING_SHIPPING_LIST.length === expectedCvs.length;
}

export function canPurchaseHavePromos(cartType: string) {
  return cartType === MIXED_WITH_EGC || cartType === MIXED_WITH_PHYSICAL || cartType === MIXED_WITH_BOTH_GC || cartType === RETAIL_ONLY_CART;
}

export function isDigitalDeliveryOnlyCart(cartType: string) {
  return cartType === DIGITAL_GC_ONLY_CART;
}

export function isDigitalCart(cartType: string) {
  return cartType === DIGITAL_GC_ONLY_CART || cartType === MIXED_WITH_EGC || cartType === MIXED_WITH_BOTH_GC || cartType === NON_MIXED_WITH_BOTH_GC;
}

export function isRetailOnlyCart(cartType: string) {
  return cartType === RETAIL_ONLY_CART;
}

export function isNonRetailOnlyCart(cartType: string) {
  return cartType !== RETAIL_ONLY_CART;
}

export function isGiftCardOnlyCart(cartType: string) {
  return cartType === PHYSICAL_GC_ONLY_CART || cartType === DIGITAL_GC_ONLY_CART || cartType === NON_MIXED_WITH_BOTH_GC;
}

export function isDigitalChallenge(cvs: ConstraintViolation) {
  return (
    cvs.hasOwnProperty(ADDRESS_ASSOCIATION) &&
    (cvs as { [ADDRESS_ASSOCIATION]: { challengeType: string } })[ADDRESS_ASSOCIATION].challengeType === 'DigitalChallenge'
  );
}

type PurchaseStatusData = {
  purchaseStatus: {
    constraintViolations: ConstraintViolation[];
  };
};

export function refetchShipOptionsBasedOnCheckoutData(data: PurchaseStatusData) {
  return checkoutDataHasShipEngineFilterBaseCV(data) || checkoutDataHasShipEngineRemoverBaseCV(data);
}

export function checkoutDataHasShipEngineFilterBaseCV(data: PurchaseStatusData) {
  const {
    purchaseStatus: { constraintViolations: cvs }
  } = data;
  return !!cvs.find(cv => cv.name === SHIPPING_ENGINE_FILTER_BASED);
}

export function checkoutDataHasShipEngineRemoverBaseCV(data: PurchaseStatusData) {
  const {
    purchaseStatus: { constraintViolations: cvs }
  } = data;
  return !!cvs.find(cv => cv.name === SHIPPING_ENGINE_REMOVER_BASED);
}

export function hasBadPromoCodeOrGiftCardFromCVList(cvs: ConstraintViolation<string>[]) {
  const constraintViolations: ConstraintViolation = {};
  cvs.forEach(cv => {
    if (cv.name) {
      constraintViolations[cv.name] = cv;
    }
  });
  return hasBadPromoCodeOrGiftCard(constraintViolations);
}

export function hasBadPromoCodeOrGiftCard(cvs: ConstraintViolation) {
  return (
    cvs.hasOwnProperty(PROMOTIONAL_CODE_ALREADY_REDEEMED) ||
    cvs.hasOwnProperty(PROMOTIONAL_CODE_EXPIRED) ||
    cvs.hasOwnProperty(PROMOTIONAL_CODE_USED_BEFORE_START_DATE) ||
    cvs.hasOwnProperty(PROMOTIONAL_CODE_INVALID_FOR_PURCHASE) ||
    cvs.hasOwnProperty(PROMOTIONAL_CODE_BAD) ||
    // must be added in, but can't until this is complete: https://jira.zappos.net/browse/MAFIA-1132
    // || cvs.hasOwnProperty(GC_PAYMENT_METHOD_NOT_ALLOWED)
    cvs.hasOwnProperty(GIFTCARD_CODE_ALREADY_REDEMEED) ||
    cvs.hasOwnProperty(GIFTCARD_CODE_CANCELLED) ||
    cvs.hasOwnProperty(GIFTCARD_CODE_EXPIRED) ||
    cvs.hasOwnProperty(GIFTCARD_CODE_INVALID)
  );
}

export function hasSingleCV(cvs: ConstraintViolation[], cvName: string) {
  return cvs.length > 0 && cvs.every(cv => cv.name === cvName);
}

type PaymentMethods = { [x: string]: string };

export function hasAfterpayCard(paymentMethods: PaymentMethods[]) {
  return !!paymentMethods.find(({ name }) => name === 'Afterpay');
}

export function hasPayPalCard(paymentMethods: PaymentMethods[]) {
  return !!paymentMethods.find(({ paymentInstrumentId }) => paymentInstrumentId?.includes('paypal'));
}

export function isReadyToSubmitCheckout({
  constraintViolations,
  currentStep,
  purchaseType,
  hasNoBalanceFromSavedDiscounts = false
}: {
  constraintViolations: ConstraintViolation[];
  currentStep: number;
  purchaseType: string;
  hasNoBalanceFromSavedDiscounts?: boolean;
}) {
  const isOnReviewStep = currentStep === REVIEW_STEP;
  const isBlockedByCVs = isPlacingOrderBlocked(constraintViolations);

  if (purchaseType === AMAZON_PAY) {
    return !isBlockedByCVs;
  }

  const isNonCreditCardType = purchaseType !== CREDIT_CARD;

  return (isOnReviewStep || isNonCreditCardType || hasNoBalanceFromSavedDiscounts) && !isBlockedByCVs;
}

export function hasOutOfStockCV(cvs: ConstraintViolation[]) {
  if (!cvs || !cvs.length) {
    return false;
  }

  return !!cvs.find(cv => cv.name === ITEM_QUANTITY_UNAVAILABLE || cv.name === OFFER_LISTING_NOT_AVAILABLE_CONSTRAINT_VIOLATION);
}

export const formatShippingPrice = (price?: number) => {
  if (typeof price === 'undefined') {
    return '';
  }
  return price === 0 ? '(FREE!)' : `- ${toUSD(price)}`;
};

export const getShipOptionPromise = ({ displayString, isBusinessUnknown }: { displayString: string; isBusinessUnknown?: boolean }) => {
  if (isBusinessUnknown) {
    return displayString;
  }

  return getEndDateFromRange(dateFromPromise(displayString));
};

export const dateFromPromise = (displayString: string) => (displayString ? displayString.replace(DELIVERY_PROMISE_TYPE, '') : '');

export const getEndDateFromRange = (displayString: string = '') => {
  const parts = displayString.split(' ');
  return parts.length > 3 ? `${parts[4]} ${parts[5]} ${parts[6]}` : displayString;
};

export const getMafiaClientHeaderPrefix = ({
  isClient = ExecutionEnvironment.canUseDOM,
  win = window
}: Partial<{ isClient: boolean; win: Window }> = {}) => {
  if (!isClient) {
    return;
  }

  const isDesktopDevice = isDesktop();

  switch (true) {
    case inIframe(win):
      return MAFIA_CLIENT_ZPOT_PREFIX;
    case isDesktopDevice:
      return MAFIA_CLIENT_DESKTOP_PREFIX;
    default:
      return MAFIA_CLIENT_MOBILE_PREFIX;
  }
};

export const buildPaginationRange = (currentPage: number, numPages: number) => {
  const current = currentPage,
    delta = 2,
    last = numPages,
    left = current - delta,
    rangeWithDots = [],
    right = current + delta + 1;

  let l: number | undefined;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }
  }

  return rangeWithDots;
};

/** @todo - Type the params once the checkout duck gets typed. */
export function buildOrderConfirmationEventData(params: any) {
  const subsiteId = isDesktop() ? desktopSubsiteId : mobileSubsiteId;

  const {
    orderId: amazonPhysicalOrderId,
    digitalOrderId: amazonDigitalOrderId,
    purchaseId: amazonPurchaseId,
    chargeSummary: { shippingCharge, estimatedTax, subTotal, total },
    productList
  } = params;

  const orderItem = productList.map((item: any) => {
    const { productId, stockId, asin } = item;
    return { asin, productId, stockId };
  });

  const checkout = {
    amazonPurchaseId,
    amazonDigitalOrderId,
    amazonPhysicalOrderId,
    shipping: +shippingCharge.toFixed(2),
    tax: +estimatedTax.toFixed(2),
    subtotal: +subTotal.toFixed(2),
    total: +total.toFixed(2),
    orderItem
  };

  return {
    type: 'Checkout',
    checkout,
    subsiteId
  };
}

export function getGivenNameFromNameOnAccount(nameOnAccount = '') {
  const parts = nameOnAccount.split(' ');
  return parts.length > 1 ? parts.slice(0, -1).join(' ') : parts[0];
}

export function getSurnameFromNameOnAccount(nameOnAccount: string) {
  const parts = nameOnAccount.split(' ');
  return parts.length > 1 ? parts[parts.length - 1] : '';
}

export function formatPhoneNumber(number: string, countryCode = 'US') {
  number = number.replace(/[^0-9]/g, '');

  switch (countryCode) {
    case 'US':
      if (number.length >= 7) {
        return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
      }

      if (number.length >= 4) {
        return `(${number.slice(0, 3)}) ${number.slice(3, 6)}`;
      }

      return number;
    default:
      return number;
  }
}

const clientErrorsMap = new Map([
  ['name', { field: CHECKOUT_ERROR_FIELD.NAME_ON_CARD, fieldName: PAYMENT_FIELDS.NAME_ON_CARD.fieldName }],
  ['cc', { field: CHECKOUT_ERROR_FIELD.CARD_NUMBER, fieldName: PAYMENT_FIELDS.CC.fieldName }],
  [
    'addressLine1',
    {
      field: CHECKOUT_ERROR_FIELD.SHIPPING_ADDRESS,
      billingField: CHECKOUT_ERROR_FIELD.BILLING_ADDRESS,
      fieldName: ADDRESS_FIELDS.ADDRESS_LINE_1.fieldName
    }
  ],
  ['city', { field: CHECKOUT_ERROR_FIELD.SHIPPING_CITY, billingField: CHECKOUT_ERROR_FIELD.BILLING_CITY, fieldName: ADDRESS_FIELDS.CITY.fieldName }],
  [
    'fullName',
    { field: CHECKOUT_ERROR_FIELD.SHIPPING_NAME, billingField: CHECKOUT_ERROR_FIELD.BILLING_NAME, fieldName: ADDRESS_FIELDS.FULL_NAME.fieldName }
  ],
  [
    'postalCode',
    { field: CHECKOUT_ERROR_FIELD.SHIPPING_ZIP, billingField: CHECKOUT_ERROR_FIELD.BILLING_ZIP, fieldName: ADDRESS_FIELDS.POSTAL_CODE.fieldName }
  ],
  [
    'primaryVoiceNumber',
    {
      field: CHECKOUT_ERROR_FIELD.SHIPPING_PHONE,
      billingField: CHECKOUT_ERROR_FIELD.BILLING_PHONE,
      fieldName: ADDRESS_FIELDS.PHONE_NUMBER.fieldName
    }
  ],
  [
    'stateOrRegion',
    {
      field: CHECKOUT_ERROR_FIELD.SHIPPING_STATE,
      billingField: CHECKOUT_ERROR_FIELD.BILLING_STATE,
      fieldName: ADDRESS_FIELDS.STATE_OR_REGION.fieldName
    }
  ]
]);

export function trackClientErrors(formErrors: { [x: string]: unknown }, isBilling = false) {
  for (const [key] of Object.entries(formErrors)) {
    const { field, billingField, fieldName } = clientErrorsMap.get(key) || {
      field: CHECKOUT_ERROR_FIELD.UNKNOWN_CHECKOUT_ERROR_FIELD
    };

    const error: { [x: string]: unknown } = {
      field: isBilling ? billingField : field
    };

    if (fieldName) {
      error.message = formErrors[fieldName];
    }

    track(() => [evCheckoutClientError, error]);
  }
}

export function convertExpirationTimeToString(date: number, now = new Date().getTime()) {
  const ms = date * 1000 - now;
  const hours = Math.floor(ms / MS_IN_HR);
  const minutes = Math.floor((ms % MS_IN_HR) / MS_IN_MIN);

  const message = [];

  if (hours) {
    message.push(`${hours} ${pluralize('hour', hours)}`);
  }

  if (hours && minutes) {
    message.push(' and ');
  }

  if (minutes) {
    message.push(`${minutes} ${pluralize('minute', minutes)}`);
  }

  return message.join('');
}

export const clearPromoBalanceSessionStorage = () => {
  removeFromSessionStorage(USE_PROMO_BALANCE_SESSION_STORAGE_KEY);
};

export const getTodaysDateFormatted = () => new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });

export const getEGCDeliveryText = (egcDeliveryDate: string | undefined) => {
  const deliveryToday = `Delivery Today, ${getTodaysDateFormatted()}`;

  // it seems that the delivery date is not returned when the date is the same as todays. This covers that.
  if (!egcDeliveryDate) {
    return deliveryToday;
  }

  const egcDate = new Date(egcDeliveryDate);
  const todaysDate = new Date();

  if (egcDate.toDateString() === todaysDate.toDateString()) {
    return deliveryToday;
  }

  return `Delivery On ${egcDeliveryDate}`;
};
