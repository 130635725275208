import React, { useMemo } from 'react';
import { deepEqual } from 'fast-equals';

import { cn } from 'helpers/classnames';
import { sortStringToObject } from 'helpers/SearchUtils';
import { FEATURE_PERCENT_DISCOUNT_SORT } from 'constants/features';
import { ZAPPOS_MERCHANTID } from 'constants/crossSiteMerchantIdMapping';
import { useVariant } from 'containers/UnleashFlagProvider';
import useMartyContext from 'hooks/useMartyContext';

import styles from 'styles/components/search/sort.scss';

interface SortProps {
  sortOptions: SortOptionProps[];
  sortId?: string;
  testId?: string;
  className?: string;
  accordion?: boolean;
  filters?: any;
  selectedSort?: SortOptionProps;
  onSortSelected?: React.ChangeEventHandler<HTMLSelectElement>;
}

export interface SortOptionProps {
  label: string;
  value: string;
  eventLabel?: string;
  ariaLabel?: string;
}

/**
 * Given a list of sort options, return the option which matches
 * the passed in criteria.
 * @param  {object[]} sortOptions array of sort options
 * @param  {object} criteria      object of sort criteria
 * @return {object}               matching sort option or undefined
 */
export const getSelectedSortValue = (sortOptions: SortOptionProps[], criteria: SortOptionProps) =>
  sortOptions.find(({ value }) => deepEqual(sortStringToObject(value), criteria)) || sortOptions[0];

/**
 * Given an array of sort options, render option elements
 * @param  {object[]} optionData options to render
 * @return {object[]}            rendered options
 */
export const makeSortOptions = (optionData: SortOptionProps[], showBestForYouSort: boolean) =>
  optionData
    // Filters out Best For You if needed
    .filter(({ label }) => label !== 'Best For You' || (label === 'Best For You' && showBestForYouSort))
    // Renders the options
    .map(({ eventLabel, label, value, ariaLabel }) => (
      <option key={value} value={value} aria-label={ariaLabel || label} data-label={eventLabel || label}>
        {label}
      </option>
    ));

const Sort = (props: SortProps) => {
  const {
    testId,
    className,
    accordion,
    sortOptions,
    selectedSort,
    onSortSelected,
    sortId = 'searchSort',
    filters: { bestForYouSortEligible }
  } = props;
  // === UNLEASH variant PERCENT OFF check (zappos.com only) ===
  const {
    marketplace: { merchantId }
  } = useMartyContext();
  const unleashVariant = useVariant(FEATURE_PERCENT_DISCOUNT_SORT);
  const isFeaturePercentOffEnabled = unleashVariant.enabled && unleashVariant.name === '1';
  const shouldRemovePercentOff = merchantId === ZAPPOS_MERCHANTID && !isFeaturePercentOffEnabled;

  // Parse the options (memoized for perf)
  const parsedSortOptions = useMemo(
    () => (shouldRemovePercentOff ? sortOptions.filter(({ value }) => value !== 'percentOff-desc') : sortOptions),
    [shouldRemovePercentOff]
  );

  // Get the selected value (memoized for perf)
  const selectedValue = useMemo(
    () => (selectedSort ? getSelectedSortValue(parsedSortOptions, selectedSort) : sortOptions[0]),
    [parsedSortOptions, selectedSort]
  );

  // Makes the sort options element
  const SortOptions = makeSortOptions(parsedSortOptions, !!bestForYouSortEligible);

  return (
    <div className={cn(styles.sort, { className })}>
      {accordion ? (
        <div>{SortOptions}</div>
      ) : (
        <span>
          <label htmlFor={sortId}>
            Sort <span> By</span>
          </label>
          <select id={sortId} className={styles.sortSelect} value={selectedValue?.value} onChange={onSortSelected} data-test-id={testId}>
            {SortOptions}
          </select>
        </span>
      )}
    </div>
  );
};

export default Sort;
