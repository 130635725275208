// Better name for this file which represents 6pm/zappos??
import routesConfig, { AppNode } from 'routes/routesConfig';
import makeRoutes from 'routes/Routes';
import rootSaga from 'store/rootSaga';

const storeOptions = {
  reducers: {},
  rootSaga,
  routesConfig,
  AppNode
};

export { makeRoutes, storeOptions };
