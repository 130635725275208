import { useDispatch, useSelector } from 'react-redux';

import { handleTopNavClick, handleTopNavCloseClick } from 'actions/headerfooterNav';
import { selectIsMobile, selectOpenedNav } from 'selectors/headerFooter';
import useMartyContext from 'hooks/useMartyContext';
import { NavMenu } from 'components/hf/zappos/HeaderNav';
import { useAccountMenu } from 'hooks/useAccountMenu';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';

const AccountMenu = () => {
  const { testId } = useMartyContext();
  const isMobile = useSelector(selectIsMobile);
  const openedNav = useSelector(selectOpenedNav);
  const dispatch: AppDispatch = useDispatch();
  const accountMenu = useAccountMenu();

  const onTopNavClick = (e: Event) => dispatch(handleTopNavClick(e));
  const onTopNavClose = (e: Event) => dispatch(handleTopNavCloseClick(e));

  const text = 'My Account';
  const topNavId = 'navAccountMenu';

  const isOpen = (id: string) => id === openedNav;
  const isSubNavOpen = (id: string) => id === openedNav;

  const subNav: { [key: string]: any } = {};

  accountMenu.forEach((menuOption, index) => {
    subNav[`submenu-${index + 1}`] = {
      componentName: 'subNavMenu',
      heading: { ...menuOption, type: 'accountMenuNav' },
      subNavMenu: []
    };
  });

  const subNavProps = {
    isSubNavOpen,
    isMobile,
    parentText: text,
    parentId: topNavId,
    handleSubNavClick: () => {},
    handleSubNavClose: () => {},
    navsThatHaveBeenOpened: [],
    testId
  };

  return (
    <NavMenu
      key={topNavId}
      hasDropDown={true}
      handleTopNavClick={onTopNavClick}
      handleTopNavCloseClick={onTopNavClose}
      isOpen={isOpen}
      link="/account"
      linkClass="!hidden"
      parentId={topNavId}
      subNav={subNav}
      subNavProps={subNavProps}
      text={text}
    />
  );
};

export default AccountMenu;
