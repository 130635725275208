import { LOAD_PIXEL_SERVER } from 'constants/reduxActions';
import { postMartyPixel } from 'apis/martyPixel';
import { getPageLang, getPageTitle } from 'helpers/ClientUtils';
import { fetchMartyPixelErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { middlewareTrack } from 'apis/amethyst';
import { evPixelBrandEvent, evPixelCartEvent, evPixelEvent, evPixelOrderEvent, evPixelPdpEvent, evPixelSearchEvent } from 'events/pixel';
import logger from 'middleware/logger';
// TODO this file has a bunch of references to the pixel server which doesn't exist anymore.
// We should rename and clarify the language to point to the amethyst events and google tag manager tags that are used instead.
/**
 * Invokes the pixel server with the data given via arguments plus some global
 * stuff.
 *
 * @param {String} pageType
 * the "pageType" key from the pixel bucket
 *
 * @param {Object} [trackingPayload={}]
 * additional data to post to the server for this pageType
 *
 * @param {String} [pageId='']
 * an additional identifier when combined with pageType to use when determining
 * when to fire the pixel server message. For instance to identify client
 * routing between different product pages. (not sent to pixel server)
 */
export function firePixelServer(pageType, trackingPayload = {}, pageId = '') {
  return function (dispatch, getState) {
    const action = makePixelServerAction(getState(), pageType, trackingPayload, pageId);
    dispatch(action);
  };
}

/**
 * @internal
 *
 * @param pageType
 * @param trackingPayload
 * @param sessionId
 * @return {Promise<unknown>} promise with event pushed, or null if event not pushed
 */
export const fireGTMEvent = (pageType, trackingPayload, sessionId = '') => {
  let finalTrackingPayload;
  if (pageType === 'confirmation') {
    const event = { pageType, trackingPayload };
    finalTrackingPayload = modifyOrderConfirmationEvent(event).trackingPayload;
  } else {
    finalTrackingPayload = trackingPayload;
  }
  const event = {
    event: pageType,
    sessionId: sessionId,
    ...finalTrackingPayload
  };

  // TODO what should be happening if this executes server side? Should we be queueing events up to fire client side like we do for amethyst?
  return new Promise(resolve => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      const callback = () => {
        window.dataLayer.push(event);
        resolve(event);
      };
      if (window.requestIdleCallback) {
        // if we have request idle callback, do the call then
        window.requestIdleCallback(callback);
        return;
      } else {
        // if we do not have request idle callback, do the call in set timeout to defer it a bit.
        setTimeout(callback, 5);
        return;
      }
    }
    resolve(null);
  });
};

export function firePixelEvent(pageType, trackingPayload = {}, pageId = '') {
  const pageLang = getPageLang();
  const pageTitle = getPageTitle();
  trackPixelEvent(pageType, pageId, pageLang, pageTitle, trackingPayload);
}

// TODO this needs to be renamed since it doesn't just make the action, it also sends the events to Amethyst and Google Tag Manager events
export function makePixelServerAction(state, pageType, trackingPayload = {}, pageId = '') {
  const {
    router: {
      location: { search }
    },
    cookies
  } = state;
  // data.pageId field is used to "cache bust" for pixel pages with no
  // parameters and the same pageType (e.g landing pages).
  const pageLang = getPageLang();
  const pageTitle = getPageTitle();
  const sessionId = cookies['session-id'];
  // Sending pixel events to amethyst
  trackPixelEvent(pageType, pageId, pageLang, pageTitle, trackingPayload);
  // Sending pixel events to GTM
  fireGTMEvent(pageType, trackingPayload, sessionId);

  return {
    type: LOAD_PIXEL_SERVER,
    pageType,
    data: {
      customerCountryCode: extractCustomerCountryCode(cookies),
      pageId,
      pageLang,
      pageTitle,
      sessionId,
      ...trackingPayload
    },
    queryString: search
  };
}

export function extractCustomerCountryCode(cookies) {
  return cookies.geo?.split('/')[0] || null;
}

// TODO This isn't a Marketing pixel and probably should be in a different module entirely.
export function fireMartyPixel(qs, type, endpoints = { postMartyPixel }) {
  return function () {
    return endpoints
      .postMartyPixel(`type=${type}&${qs}`)
      .then(fetchMartyPixelErrorMiddleware)
      .catch(() => {
        logger('Unable to get valid response from /martypixel endpoint, not firing marty pixel server.');
      });
  };
}

// TODO This is actually amethyst should at least be renamed and called correctly
export function trackPixelEvent(pageType, pageId, pageLang, pageTitle, trackingPayload = {}) {
  const event = { pageType, pageId, pageLang, pageTitle, trackingPayload };
  fetchEventTypeForPayload(pageType, event, {
    evPixelOrderEvent,
    evPixelBrandEvent,
    evPixelPdpEvent,
    evPixelSearchEvent,
    evPixelCartEvent,
    evPixelEvent
  });
}

// Addresses get their zip codes truncated due to PII/Data classification policies
export function modifyOrderConfirmationEvent(orderEvent) {
  const updatedOrders = [];
  orderEvent.trackingPayload?.orders.forEach(order => {
    const { billingAddress, shippingAddress } = order;
    const orderObj = {
      ...order,
      billingAddress: {
        ...billingAddress,
        postalCode: billingAddress?.postalCode.slice(0, 5)
      },
      shippingAddress: {
        ...shippingAddress,
        postalCode: shippingAddress?.postalCode.slice(0, 5)
      }
    };
    updatedOrders.push(orderObj);
  });
  const { billingAddress, shippingAddress } = orderEvent?.trackingPayload?.order;
  const event = {
    ...orderEvent,
    trackingPayload: {
      order: {
        ...orderEvent.trackingPayload.order,
        billingAddress: {
          ...billingAddress,
          postalCode: billingAddress?.postalCode.slice(0, 5)
        },
        shippingAddress: {
          ...shippingAddress,
          postalCode: shippingAddress?.postalCode.slice(0, 5)
        }
      },
      orders: updatedOrders
    }
  };
  return event;
}

export function fetchEventTypeForPayload(
  pageType,
  event = {},
  { evPixelOrderEvent, evPixelBrandEvent, evPixelPdpEvent, evPixelSearchEvent, evPixelCartEvent, evPixelEvent }
) {
  switch (pageType) {
    case 'confirmation':
      middlewareTrack(evPixelOrderEvent(modifyOrderConfirmationEvent(event)));
      break;
    case 'brand':
      middlewareTrack(evPixelBrandEvent(event));
      break;
    case 'pdp':
      middlewareTrack(evPixelPdpEvent(event));
      break;
    case 'search':
      middlewareTrack(evPixelSearchEvent(event));
      break;
    case 'cart':
      middlewareTrack(evPixelCartEvent(event));
      break;
    default:
      middlewareTrack(evPixelEvent(event));
  }
}
