import React from 'react';

import { cn } from 'helpers/classnames';
import { useCountdownTimer } from 'hooks/useCountdownTimer';
import { pluralize } from 'helpers/index';
import { convertToUTC } from 'helpers/TimeUtils';

import css from 'styles/components/landing/timer.scss';

interface CountdownTimer {
  d: number;
  h: number;
  m: number;
  s: number;
  timePassed: boolean;
}

interface TimerProps {
  date: string;
  className?: string;
}

const Timer = ({ date, className }: TimerProps) => {
  const timer: CountdownTimer = useCountdownTimer(convertToUTC(date)) as unknown as any;
  const { d, h, m, s, timePassed } = timer || {};

  if (!timer) {
    return <span className={cn(css.timer, className)}></span>;
  }

  if (timePassed) {
    return (
      <span role="timer" className={cn(css.timer, className, css.loaded)}>
        00:00:00
      </span>
    );
  }

  const roundedDays = d > 1 ? (h >= 8 ? d + 1 : d) : d;

  const formatTimeComponent = (c: Number) => ('' + c).padStart(2, '0');
  const formattedTime = [h, m, s].map(formatTimeComponent).join(':');
  const formattedDays = `${roundedDays} ${pluralize('day', Number(roundedDays === 1))}`;
  const formatAsDays = roundedDays > 0;
  const formattedDateTime = `${formattedDays} ${formattedTime}`;
  const timerText = d === 1 && s > 0 ? formattedDateTime : formatAsDays ? formattedDays : formattedTime;

  return (
    <span role="timer" className={cn(css.timer, className, css.loaded)}>
      {timerText}
    </span>
  );
};

export default Timer;
