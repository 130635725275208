import { matchPath } from 'react-router-dom';

import type { RouteConfig } from 'routes/routesConfig';
import type { RouteConfigCombined } from 'helpers/RouteUtils';

export const matchRouteConfig = (routesConfig: RouteConfig[], pathname?: string): RouteConfigCombined | null => {
  let config = null;
  if (!pathname) {
    return null;
  }
  routesConfig.every(route => {
    const matchingRoute = matchPath(pathname, route);
    if (matchingRoute) {
      config = {
        ...route,
        params: matchingRoute.params,
        location: {
          pathname: pathname
        }
      };
      return false;
    }
    return true;
  });
  return config;
};
