import type { Reducer } from 'redux';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import { LOAD_INITIAL_LOCAL_STORAGE } from 'constants/reduxActions';
import debugFlags from 'reducers/debugFlags';
import onDemandSize from 'reducers/onDemandSize';
import lastSelectedSizes from 'reducers/lastSelectedSizes';
import type { LocalStorageAction } from 'helpers/connectStoreToLocalStorage';

const localStorageReducers = combineReducers({
  debugFlags,
  lastSelectedSizes,
  onDemandSize
});

interface LocalStorageState {
  [key: string]: any;
}

function rootLocalStorageReducer(state: Readonly<LocalStorageState> = {}, action: LocalStorageAction): LocalStorageState {
  switch (action.type) {
    case LOAD_INITIAL_LOCAL_STORAGE:
      return {
        ...state,
        ...action.initialLocalStorage
      };
    default:
      return state;
  }
}

export default reduceReducers(localStorageReducers, rootLocalStorageReducer as Reducer<any, any>);
