import type { AnyAction } from 'redux';

import { SET_EMERGENCY_BANNER } from 'store/ducks/emergencyBanner/types';

export type EmergencyBannerState = {
  data: {
    message: string;
  };
};

const INITIAL_STATE = {
  data: true // default to true to force mount of the component so it can perform at least 1 fetch.
};

const emergencyBannerReducer = (state = INITIAL_STATE, action: AnyAction = { type: '' }) => {
  let data = action.payload;

  switch (action.type) {
    case SET_EMERGENCY_BANNER: {
      // if banner message is empty remove it.
      if (!data?.message) {
        data = false;
      }
      return { ...state, data };
    }
    default:
      return state;
  }
};

export default emergencyBannerReducer;
