import { KRATOS_CHECKOUT_COOKIE } from 'constants/cookies';
import type { AppState } from 'types/app';

export const selectCookies = (state: AppState) => state.cookies;

export const selectCookie = (state: AppState, key: string): string => selectCookies(state)?.[key] || '';

export const selectIsCustomer = (state: AppState) => !!selectCookie(state, 'x-main');

export const selectHasKratosCheckoutCookie = (state: AppState) => selectCookie(state, KRATOS_CHECKOUT_COOKIE) === 'true';

export const selectCookiesSessionId = (state: AppState) => selectCookie(state, 'session-id');
