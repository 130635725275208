import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MelodyModal from 'components/common/MelodyModal';
import LoginLink from 'components/common/LoginLink';
import { trackEvent } from 'helpers/analytics';
import { getType } from 'history/historyFactory';
import { MartyContext } from 'utils/context';

import css from 'styles/components/common/heartLoginPrompt.scss';

const LOGIN_PROMPT_TE_BY_PAGETYPE = {
  brand: 'TE_BRAND_PAGE_HEART_LOGIN_PROMPT',
  homepage: 'TE_LANDING_HEART_LOGIN_PROMPT',
  landing: 'TE_LANDING_HEART_LOGIN_PROMPT',
  pdp: 'TE_PDP_HEART_LOGIN_PROMPT',
  search: 'TE_SEARCH_HEART_LOGIN_PROMPT'
};

export class HeartLoginPrompt extends Component {
  trackHeartLoginPrompt = () => {
    trackEvent(LOGIN_PROMPT_TE_BY_PAGETYPE[getType(window.location.pathname)] || 'TE_FOOTER_HEART_LOGIN_PROMPT');
  };

  handleLoginClick = () => {
    const { toggleModal } = this.props;
    this.trackHeartLoginPrompt();
    toggleModal(false);
  };

  render() {
    return (
      <MartyContext.Consumer>
        {({ testId }) => {
          const { isOpen, toggleModal, id } = this.props;
          const modalData = { 'test-id': testId('heartLoginModal') };
          const additionalParams = { heartOnLoad: id };
          // const returnTo = encodeURIComponent(`${pathname}?${stringify({ ...query, ...additionalParams })}`);
          return (
            <MelodyModal
              data={modalData}
              isOpen={isOpen}
              onRequestClose={() => toggleModal(false)}
              className={css.container}
              heading="Sign In to Save Items to Favorites"
              headingTestId="heading"
              wrapperTestId="heartLoginModal"
              buttonTestId="close"
            >
              <p className={css.copy} data-test-id={testId('modalText')}>
                In order to <span className={css.zaprHeart}>heart</span>something, you must be signed in. If you don’t have an account, you will have
                to register to create one.
              </p>
              <div className={css.bottomSection}>
                <LoginLink
                  onClick={this.handleLoginClick}
                  queryAddOns={additionalParams}
                  className={css.signInButton}
                  data-test-id={testId('heartSignIn')}
                >
                  Sign In / Register
                </LoginLink>
              </div>
            </MelodyModal>
          );
        }}
      </MartyContext.Consumer>
    );
  }
}

HeartLoginPrompt.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  id: PropTypes.string
};

export default HeartLoginPrompt;
