import { HOLMES_DETERMINED, STORE_HOLMES } from 'constants/reduxActions';

export default function holmes(state = { isHolmesDetermined: false, isHolmesLoaded: false }, action) {
  const { type, holmes, isDetermined } = action;

  switch (type) {
    case STORE_HOLMES:
      return { ...state, ...holmes, isHolmesLoaded: true };
    case HOLMES_DETERMINED:
      return { ...state, isHolmesDetermined: isDetermined };
    default:
      return state;
  }
}
