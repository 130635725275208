import type { ReactNode } from 'react';
import { createElement } from 'react';

import type { Meta, Tag } from './types';

import logger from 'middleware/logger';

function parseTags(tagName: string, props: Meta['meta'] | Meta['link'] = {}) {
  const tags: Tag[] = [];
  const contentKey = tagName === 'link' ? 'href' : 'content';
  Object.keys(props).forEach(groupKey => {
    const group = props[groupKey];
    if (typeof group === 'string') {
      tags.push({
        tagName,
        [groupKey]: group
      });
      return;
    }
    Object.keys(group as object).forEach(key => {
      const values = (Array.isArray(group?.[key]) ? group?.[key] : [group?.[key]]) as string[];
      values?.forEach((value: any) => {
        if (value !== null) {
          tags.push({
            tagName,
            [groupKey]: key,
            [contentKey]: value
          });
        }
      });
    });
  });
  return tags;
}

function getTags(props: Meta) {
  const tags: Tag[] = [];
  return tags.concat(props.base ? [{ tagName: 'base', href: props.base }] : [], parseTags('meta', props.meta), parseTags('link', props.link));
}

function renderTag(entry: Tag) {
  const { tagName, ...attr } = entry;

  if (tagName === 'meta' || tagName === 'link' || tagName === 'base') {
    const key = Object.values(attr).join('_');
    return createElement(tagName, {
      ...attr,
      key,
      'data-rh': true
    });
  } else {
    logger(`unknown head tag type ${tagName}`);
  }

  return null;
}

export function renderTags(meta: Meta = {}) {
  const tags: ReactNode[] = [];

  if (meta.title) {
    tags.push(<title key="title">{meta.title}</title>);
  }

  if (meta.description) {
    tags.push(<meta key="description" name="description" content={meta.description as string} />);
  }

  if (meta.canonical) {
    tags.push(<link key="canonical" rel="canonical" href={meta.canonical} />);
  }

  return tags.concat(getTags(meta).map(renderTag));
}
