import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import ExecutionEnvironment from 'exenv';

import { setHfLayout, setLayout, setTouchDetected, setViewportSize } from './actions';

import type { DevicePropsState } from 'store/ducks/deviceProps/types';

export function getContent(element: Element, pseudo: string | null | undefined): string {
  return window
    .getComputedStyle(element, pseudo)
    .getPropertyValue('content')
    .replace(/['"]|(none)/gi, '');
}
/**
 * This is to be used globally on the app to listen to resize events.
 */
export const useLayoutListener = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!ExecutionEnvironment.canUseDOM) {
      return undefined;
    }
    let timeout: ReturnType<typeof setTimeout> | null = null;
    const handleResize = () => {
      let layout: string | null = getContent(document.documentElement, ':before');
      let hfLayout: string | null = getContent(document.documentElement, ':after');

      if (layout !== 'desktop' && layout !== 'tablet' && layout !== 'mobile') {
        if (layout) {
          throw new Error(`unknown layout ${layout}`);
        } else {
          layout = null;
        }
      }

      if (hfLayout !== 'hf-desktop' && hfLayout !== 'hf-tablet' && hfLayout !== 'hf-mobile') {
        if (hfLayout) {
          throw new Error(`unknown hfLayout ${layout}`);
        }
        hfLayout = null;
      }

      dispatch(
        setViewportSize({
          viewportWidth: window.innerWidth,
          viewportHeight: window.innerHeight,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height
        })
      );
      dispatch(setLayout(layout as DevicePropsState['layout']));
      dispatch(setHfLayout(hfLayout as DevicePropsState['hfLayout']));
      dispatch(setTouchDetected(window.matchMedia('(any-pointer: coarse)').matches));
      timeout = null;
    };

    window.addEventListener('resize', () => {
      timeout !== null && clearTimeout(timeout);
      timeout = setTimeout(handleResize, 200);
    });
    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(handleResize);
    } else {
      handleResize();
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      timeout !== null && clearTimeout(timeout);
    };
  }, [dispatch]);
};
