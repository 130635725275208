/*
 * This module is needed in babel config and therefore cannot use ES6 syntax
 */
const DEFAULT_MARKETPLACE = 'zappos'; // never forget
module.exports = {
  /*
   * This function will the current marketplace.
   */
  marketplace: function () {
    // ideally this env lookup would be done once, but it makes things more difficult to test and current uses do not call it repeatedly.
    return process.env.MARTY_MARKETPLACE || DEFAULT_MARKETPLACE;
  },
  defaultMarketplace: DEFAULT_MARKETPLACE
};
