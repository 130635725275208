import ExecutionEnvironment from 'exenv';

import useMartyContext from 'hooks/useMartyContext';

const CLASS_THEME_ATTR = 'class';
const THEME_6PM = 'sixpm';
const SHORT_NAME_6PM = '6pm';

const Themer = () => {
  const { marketplace: { shortName } = {} } = useMartyContext();

  if (!ExecutionEnvironment.canUseDOM) {
    return null;
  }

  const root = document.documentElement;

  if (shortName === SHORT_NAME_6PM) {
    root?.setAttribute(CLASS_THEME_ATTR, THEME_6PM);
  } else {
    root?.removeAttribute(CLASS_THEME_ATTR);
  }

  return null;
};

export default Themer;
