import React from 'react';

import { safeJsonLdReplacer } from './JsonLDReplacer';

export interface WithJsonLdProductProps {
  brandName: string;
  color?: string;
  price?: string;
  productName: string;
  productUrl?: string;
  [other: string]: unknown; // Allow additional properties on HoC child components
}

const withJsonLdProduct =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: WithJsonLdProductProps) => {
    const { brandName, color, productName, price, productUrl } = props;

    const jsonLdData = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      'name': productName,
      ...(brandName && {
        brand: {
          name: brandName
        }
      }),
      ...(price && {
        offers: {
          '@type': 'Offer',
          'price': price,
          'priceCurrency': 'USD'
        }
      }),
      color,
      'url': productUrl
    };

    return (
      <>
        <Component {...(props as P)} />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData, safeJsonLdReplacer, 2) }} />
      </>
    );
  };

export default withJsonLdProduct;
