import ExecutionEnv from 'exenv';
import PropTypes from 'prop-types';
import type { ComponentType, ReactNode } from 'react';
import { connect } from 'react-redux';
import withSideEffect from 'react-side-effect';

import { DocumentMeta } from 'components/document-meta';
import { ClientRouteOnlyAriaLive } from 'components/common/AriaLive';
import { firePageView } from 'actions/pageView';
import type { AppState } from 'types/app';

/*
 * DocumentMeta component which updates <head/> based on the redux store (see `meta` reducer)
 */
export const SiteAwareMetadata = (props: any) => {
  const { documentMeta, children } = props;
  return (
    <>
      <DocumentMeta {...documentMeta}>{children}</DocumentMeta>
      {/* Reads the title out to screen readers when it changes */}
      {documentMeta?.title && <ClientRouteOnlyAriaLive>{documentMeta.title}</ClientRouteOnlyAriaLive>}
    </>
  );
};

SiteAwareMetadata.propTypes = {
  documentMeta: PropTypes.object,
  reduxZfcMetadata: PropTypes.string
};

export function reducePropsToState(propsList: any) {
  const props = Object.assign({}, ...propsList);
  // all these props except loading come from `connect()` and are action creators or redux state
  const state = {
    firePageView: props.firePageView,
    pageInfo: props.pageInfo,
    loading: !!props.loading,
    documentMeta: props.documentMeta,
    zfcMetadata: props.zfcMetadata
  };
  return state;
}

export function handleStateChangeOnClient(state: any) {
  const { loading, pageInfo, firePageView, zfcMetadata } = state;
  if (loading === false && pageInfo && pageInfo.needsToFire && pageInfo.routeUpdated) {
    firePageView(pageInfo, zfcMetadata);
  }
}

export const SiteAwareMetadataWithSideEffect = ExecutionEnv.canUseDOM
  ? withSideEffect(reducePropsToState, handleStateChangeOnClient)(SiteAwareMetadata)
  : SiteAwareMetadata;

export function mapStateToProps(state: AppState) {
  const { documentMeta } = state.meta || {};

  return {
    documentMeta,
    zfcMetadata: state.meta?.zfcMetadata,
    pageInfo: state.pageView
  };
}

SiteAwareMetadata.contextTypes = {
  marketplace: PropTypes.object.isRequired
};

const ConnectedSiteAwareMetadata = connect(mapStateToProps, { firePageView })(
  SiteAwareMetadataWithSideEffect as ComponentType<{ children: ReactNode }>
);

const TestSiteAwareMetadata = ({ children }: { children: ReactNode }) => (
  <>
    <div data-test-id="siteAwareMetadata" />
    {children}
  </>
);

export default process.env.NODE_ENV === 'test' ? TestSiteAwareMetadata : ConnectedSiteAwareMetadata;
