import React, { useEffect } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { cn } from 'helpers/classnames';
import SkipLinks from 'components/common/SkipLinks';
import Link from 'components/hf/HFLink';
import useMartyContext from 'hooks/useMartyContext';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import type { AppState } from 'types/app';
import { ZAWNav } from 'components/hf/zappos/ZAWNav';
import ZAWLogoWhite from 'images/zaw/ZAWLogoWhite.svg';
import { getZAWHeaderData } from 'actions/headerfooter';
import { trackEvent } from 'helpers/analytics';

import css from 'styles/containers/hf/zappos/zawHeader.scss';
export interface ZawNavItem {
  title: string;
  link?: string;
  subNavName?: string; // key used to map subNav array to unified object
  subNav?: ZawNavItem[];
}

type HeaderProps = ConnectedProps<typeof connector>;

const ZAW_LINK = '/c/zappos-at-work';
const ZAW_FORM_LINK = '/c/zappos-at-work-form';

export const ZAWHeader = (props: HeaderProps) => {
  const { content, getZAWHeaderData, zawData } = props;
  const { search } = useLocation();
  const { topNav = [], connectButtonText = 'Connect with a Team Member', connectButtonLink = `${ZAW_FORM_LINK}${search}` } = zawData;
  const data = content?.Header?.slotData?.logo?.images?.[0];

  // unify subNavs with topNavs
  const navData = topNav.map((topNavInfo: ZawNavItem) => {
    const { subNavName } = topNavInfo;
    const subNav = subNavName ? zawData[subNavName] : [];
    return {
      ...topNavInfo,
      subNav
    };
  });

  useEffect(() => {
    getZAWHeaderData();
  }, [getZAWHeaderData]);

  const { testId } = useMartyContext();

  const makeSkipLink = () => {
    const links = [{ id: 'main', value: 'Skip to main content' }];
    return <SkipLinks links={links} />;
  };

  const headerButtonClick = () => {
    trackEvent('TE_ZAW_HEADER_BUTTON_CLICK');
  };

  return (
    <header role="banner" data-header-container>
      {makeSkipLink()}
      <div className={cn(css.header, css.blueBackground)}>
        <div className={css.headerContainer}>
          <Link data-test-id={testId('headerLogo')} className={css.hfImagesComponent} to={ZAW_LINK}>
            <img className={css.containedImage} src={ZAWLogoWhite} alt={data.alt} height={data.height} width={data.width} />
          </Link>
          <ZAWNav isAlternateStyling={true} navData={navData} />
          <a className={css.headerButton} onClick={headerButtonClick} href={connectButtonLink} type="button">
            {connectButtonText}
          </a>
        </div>
      </div>
    </header>
  );
};

function mapStateToProps(state: AppState) {
  const {
    headerFooter: { content, zawData }
  } = state;
  return {
    content,
    zawData
  };
}

const connector = connect(mapStateToProps, {
  getZAWHeaderData
});
const ZAWHeaderConnected = connector(ZAWHeader);

const ZAWHeaderConnectedWithErrorBoundary = withErrorBoundary('ZAWHeader', ZAWHeaderConnected);
export default ZAWHeaderConnectedWithErrorBoundary;
