import type { ThunkAction } from 'redux-thunk';
import type { AnyAction } from 'redux';

import { FETCH_RECOS, RECEIVE_RECOS } from 'constants/reduxActions';
import { evRecommendationImpressionWrapper } from 'events/recommendations';
import { getJanusRecos } from 'apis/mafia';
import marketplace from 'cfg/marketplace.json';
import recommenderFilterFormatter from 'helpers/recommenderFilterFormatter';
import { buildProductPageRecoKey, buildRecosKey, buildRequestSlots, getRecosSlot, parseRecommenderFilterValue } from 'helpers/RecoUtils';
import ProductUtils from 'helpers/ProductUtils';
import { track } from 'apis/amethyst';
import type { AppState } from 'types/app';
import type { AllJanusSlotNames, JanusParams, Recos } from 'types/mafia';
import { isDesktop, isMobileRecosAvailable } from 'helpers/ClientUtils';
import { selectMafiaConfig } from 'selectors/environment';

const {
  pdp: { recos, mobileRecos }
} = marketplace;

const DEFAULT_NUMBER_OF_RECOS = 5;

export function fetchingRecos() {
  return {
    type: FETCH_RECOS
  } as const;
}

export function receiveRecos(key: string, source: string, data: Recos, imageHost: string, isCFYSslot?: boolean) {
  return {
    type: RECEIVE_RECOS,
    data,
    source,
    key,
    imageHost,
    isCFYSslot
  } as const;
}

function getImageHost(state: AppState): string {
  const {
    environmentConfig: {
      imageServer: { url: imageHost }
    }
  } = state;
  return imageHost;
}

interface RecoSlotDetails {
  limit: string;
  widget: string;
  filters?: Filter[];
  term?: string;
}
interface Filter {
  name: string;
  value: string[];
}
export function fetchRecos(slotDetails: RecoSlotDetails, janusFetcher = getJanusRecos): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  const { filters = [], limit, widget, term = '' } = slotDetails;
  const parsedFilters: Record<string, string[]> = {};
  filters.forEach(f => {
    parsedFilters[f.name] = parseRecommenderFilterValue(f.value);
  });

  const janusFilter = recommenderFilterFormatter(parsedFilters)?.setState();
  // Apply custom widget parameter if declared
  const nameList = widget || (janusFilter?.getRecommenderName() as string);
  const { filterString } = janusFilter!; // TODO ts get rid of the `!` directive here, recommenderFilterFormatter was a bit of a mess so i didn't type it.
  const recommenderNames = Array.isArray(nameList) ? nameList.join(',') : nameList;
  return (dispatch, getState) => {
    const state = getState();
    const { cookies = {} } = state;
    const mafiaConfig = selectMafiaConfig(state);
    let params: { filter: string; txt?: string } = { filter: filterString };

    if (term) {
      params = { ...params, txt: term };
    }

    const paramObj = {
      params,
      widgets: recommenderNames,
      limit: limit || DEFAULT_NUMBER_OF_RECOS,
      credentials: cookies,
      dispatch,
      getState
    };

    return janusFetcher(mafiaConfig, paramObj).then((resp = {}) => {
      Promise.all(
        (Object.keys(resp) as AllJanusSlotNames[]).map(recoName => {
          dispatch(receiveRecos(buildRecosKey(slotDetails, recoName), 'EP13N', { [recoName]: resp[recoName] }, getImageHost(state)));
        })
      );
    });
  };
}

export function fetchProductPageRecos(
  productId: string,
  styleId: string,
  isCFYSslot: boolean,
  janusFetcher = getJanusRecos
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);
    // default to desktop recos in unsupoorted marketplaces
    const slotDetailMap = !isDesktop() && isMobileRecosAvailable(mobileRecos) ? mobileRecos : recos;

    let params: JanusParams = { item: productId };
    if (styleId) {
      params.teen = styleId;
    }

    const { limits, widgets } = buildRequestSlots(isCFYSslot);
    params = { ...params, ...limits };
    const recoKey = buildProductPageRecoKey(productId, styleId);
    dispatch(fetchingRecos());

    return janusFetcher(mafiaConfig, {
      params,
      widgets,
      limit: DEFAULT_NUMBER_OF_RECOS,
      credentials: cookies,
      dispatch,
      getState
    }).then(resp => {
      dispatch(receiveRecos(recoKey, 'EP13N', resp, getImageHost(state), isCFYSslot));
      if (resp) {
        Object.keys(slotDetailMap).forEach(slot => {
          const shownReco = getRecosSlot(resp, slot as 'slot0' | 'slot1' | 'slot2' | 'slot3' | 'cfys');
          if (shownReco) {
            const recommendationImpression = [
              {
                numberOfRecommendations: shownReco.sims.length,
                recommendationType: 'PRODUCT_RECOMMENDATION',
                recommendationSource: 'EP13N',
                widgetType: ProductUtils.translateRecoTitleToAmethystWidget(shownReco.title)
              }
            ];
            track(() => [evRecommendationImpressionWrapper, { recommendationImpression }]);
          }
        });
      }
    });
  };
}

export interface ProductRecosFetchOpts {
  productIds?: string[];
  styleIds?: string[];
  productId: string;
  styleId: string;
  slot: string;
  limit: number;
  janusFetcher?: typeof getJanusRecos;
  storageKey?: string;
}

/**
 * Used to fetch a single slot for a product and style.  Data is stored in the state under the requested slot name.
 * @param {Object} options
 * @param {String} options.productId
 * @param {Array}  options.productIds list of all unique product ids in cart
 * @param {Array}  options.styleIds list of all unique style ids in cart - this should positionally line up with productIds array
 * @param {String} options.styleId
 * @param {String} options.slot the slot to request.
 * @param {Number} options.limit number
 * @param {function} [options.janusFetcher] api function for fetching recos
 * @param {String} [options.storageKey] optional string for where to store results under. Default to the requested slot name.
 */
export function fetchProductRecos({
  productIds = [],
  styleIds = [],
  productId,
  styleId,
  slot,
  limit,
  janusFetcher = getJanusRecos,
  storageKey
}: ProductRecosFetchOpts): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    dispatch(fetchingRecos());

    interface Params {
      item: string;
      limit: number;
      teen: string;
      items?: string;
      teens?: string;
    }
    let params: Params = { item: productId, limit, teen: styleId };

    if (productIds.length && styleIds.length) {
      params = {
        ...params,
        items: productIds.join(';'),
        teens: styleIds.join(';')
      };
    }

    return janusFetcher(mafiaConfig, {
      params,
      widgets: slot,
      limit,
      credentials: cookies,
      dispatch,
      getState
    }).then(resp => {
      dispatch(receiveRecos(storageKey || slot, 'EP13N', resp, getImageHost(state), false));
    });
  };
}

export type RecoAction = ReturnType<typeof fetchingRecos> | ReturnType<typeof receiveRecos>;
