import React from 'react'
import Icon from '../Icon';

const VipOnlyLogo = ({ width = 39, height = 36, ...otherProps }) => (
  <Icon
    width={width}
    height={height}
    viewBox="0 0 39 36"
    title="Zappos VIP"
    description="Zappos VIP"
    {...otherProps}>
    <title>{'vip logo'}</title>
    <g fill="#01579b" fillRule="nonzero">
      <path d="M34.325 20.424c-1.267 8.022-8.476 14.041-16.684 13.796-9.043-.27-16.179-7.818-15.907-16.825C2.005 8.39 9.583 1.281 18.626 1.552c4.788.143 9.205 2.34 12.224 6.041l1.73-.305C29.276 2.826 24.222.174 18.673.008 8.775-.288.48 7.491.183 17.348c-.297 9.859 7.514 18.12 17.412 18.415 9.37.28 17.394-6.714 18.386-15.967-.515.25-1.069.46-1.656.628" />
      <path d="M17.21 11.672s.83-.257.692.456c-.139.715-4.216 13.658-4.354 14.131-.138.473-.346.83-1.106.968-.76.137-3.179.804-3.662.683-.484-.12-4.078-11.013-4.596-12.086-.519-1.074-.496-1.502-.42-1.649.074-.146 3.668-.724 3.979-.742.31-.018.691.324.864 1.118.173.793 2.28 9.245 2.384 9.054.104-.192 2.211-9.843 2.419-10.707.207-.865 3.445-1.183 3.8-1.226M21.625 10.686s-1.555.33-1.59.75c-.034.419 0 13.043 0 13.524 0 .482.795.653 2.004.388 1.21-.264 1.936-.228 2.004-1.17.07-.942.035-12.705 0-13.387-.034-.682-2.418-.105-2.418-.105M35.826 12.688l-1.795 1.557a.08.08 0 00-.021.091l.933 2.182c.03.07-.048.14-.115.1l-2.042-1.22a.08.08 0 00-.094.01l-1.794 1.557c-.059.05-.148-.003-.13-.078l.532-2.311a.08.08 0 00-.037-.086l-2.042-1.218c-.066-.04-.043-.142.034-.148l2.371-.21a.08.08 0 00.071-.061l.533-2.311c.017-.075.12-.085.151-.014l.933 2.182a.08.08 0 00.08.048l2.372-.21a.08.08 0 01.06.14M33.01 8.685l-5.026.886c-.003.001-1.106.194-1.106 1.35l.009 13.021c0 .215.162.326.366.349.763.084 3.09-.587 3.145-.611.213-.095.19-.59.166-1.11-.019-.406-.017-2.106-.012-2.983.017-.005.033-.011.05-.014l2.086-.368c3.366-.594 6.068-2.863 6.068-6.324v-.041c0-3.055-2.166-4.787-5.746-4.155" />
    </g>
  </Icon>
);

export default VipOnlyLogo;
