import { ADDRESS_FIELDS, PAYMENT_FIELDS } from './formFields';

export const INVALID_ADDRESS = 'invalid-address';

export const REQUEST_ERRORS = {
  'input.invalid': 'Invalid input',
  'required.input.missing': 'Required',
  'validation.exception': 'Invalid Input',
  'max.length.exceeded': 'Input too long'
} as const;

export const ADDRESS_MESSAGES = {
  invalid_phone: 'Invalid phone number',
  missing_value: {
    [ADDRESS_FIELDS.FULL_NAME.fieldName]: 'Enter your full name here. Nice to meet you.',
    [ADDRESS_FIELDS.COUNTRY_CODE.fieldName]: 'Please select a country.',
    [ADDRESS_FIELDS.PHONE_NUMBER.fieldName]: 'Please enter your phone number, in case we need to reach you.',
    [ADDRESS_FIELDS.ADDRESS_LINE_1.fieldName]: 'Enter your address here.',
    [ADDRESS_FIELDS.ADDRESS_LINE_2.fieldName]: '',
    [ADDRESS_FIELDS.POSTAL_CODE.fieldName]: 'Enter a zip code here.',
    [ADDRESS_FIELDS.STATE_OR_REGION.fieldName]: 'Enter a state / province here.',
    [ADDRESS_FIELDS.STATE_OR_REGION.fieldName + '_dropdown']: 'Enter a state / province here.',
    [ADDRESS_FIELDS.STATE_OR_REGION.fieldName + '_text']: 'Enter a state / province here.',
    [ADDRESS_FIELDS.CITY.fieldName]: 'Enter a city name here.'
  }
} as const;

export const PAYMENT_MESSAGES = {
  cc_expired: 'Card has expired.',
  cc_bad_format: 'Card number is not correct.',
  cc_expiration_bad_format: 'Expiration date is not correct.',
  missing_value: {
    [PAYMENT_FIELDS.CC.fieldName]: 'Please enter a card number.',
    [PAYMENT_FIELDS.CC_EXPIRATION.fieldName]: 'Expiration date is not correct.',
    [PAYMENT_FIELDS.CC_EXPIRATION_MONTH.fieldName]: 'Please enter an expiration month.',
    [PAYMENT_FIELDS.CC_EXPIRATION_YEAR.fieldName]: 'Please enter an expiration year.',
    [PAYMENT_FIELDS.NAME_ON_CARD.fieldName]: "Please enter cardholder's name"
  }
} as const;
