/**
 ****************************************************************************************
 * My Account Order Statuses
 * V3 = MyAccount
 * Source: ZapposMyAccount/src/com/amazon/zapposmyaccount/services/amazon/ShipmentServiceFacade.java
 ****************************************************************************************
 **/

export const DELIVERED = 'Delivered';
export const IN_TRANSIT = 'In transit';
export const OUT_FOR_DELIVERY = 'Out for delivery';
export const DELIVERY_ATTEMPTED = 'Delivery attempted';
export const AVAILABLE_FOR_PICKUP = 'Available for pickup';
export const RETURNED = 'Returned to seller';
export const RETURNING = 'Returning to seller';
export const DELAYED = 'Delayed';
export const UNDELIVERABLE = 'Undeliverable';
export const SHIPPING_SOON = 'Shipping Soon';

// compositeStatus Strings
export const CS_IN_TRANSIT = 'IN_TRANSIT';
export const CS_DELAYED = 'DELAYED';
export const CS_OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY';
export const CS_DELIVERY_ATTEMPTED = 'DELIVERY_ATTEMPTED';
export const CS_AVAILABLE_FOR_PICKUP = 'AVAILABLE_FOR_PICKUP';
export const CS_DELIVERED = 'DELIVERED';
export const CS_UNDELIVERABLE = 'UNDELIVERABLE';
export const CS_RETURNING_TO_SELLER = 'RETURNING_TO_SELLER';
export const CS_RETURNED_TO_SELLER = 'RETURNED_TO_SELLER';
export const CS_SHIPPING_SOON = 'SHIPPING_SOON';

// Statuses from /getTracking endpoint
export const TRACKING_STATUS = {
  SHIPPING_SOON: 'shippingsoon',
  SHIPPED: 'shipped',
  IN_TRANSIT: 'intransit',
  OUT_FOR_DELIVERY: 'outfordelivery',
  DELIVERED: 'delivered',
  DELAYED: 'delayed',
  RETURNING: 'returning',
  RETURNED: 'returned',
  UNDELIVERABLE: 'undeliverable',
  DELIVERY_ATTEMPTED: 'deliveryattempted',
  CUSTOMER_ACTION: 'customeraction'
};

export default {
  DELIVERED,
  IN_TRANSIT,
  OUT_FOR_DELIVERY,
  DELIVERY_ATTEMPTED,
  AVAILABLE_FOR_PICKUP,
  RETURNED,
  RETURNING,
  DELAYED,
  UNDELIVERABLE,
  SHIPPING_SOON
};
