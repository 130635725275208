import { middlewareTrack } from 'apis/amethyst';
import { CHECKOUT_POST_PURCHASE_OFFER_CANCEL_CLICK, CHECKOUT_RECEIVE_ORDER, CHECKOUT_RECEIVE_ORDER_INFORMATION } from 'store/ducks/checkout/types';

const ORDER_CONFIRMATION_PAGEVIEW = 'ORDER_CONFIRMATION_PAGEVIEW';

const amethystTrackOrderShippingDetails = (_appstate, { orderId, deliveryDateRangeDisplayMessage = '', earliestArrivalDate, latestArrivalDate }) => {
  // when no deliveryDateRangeDisplayMessage, user is shown shipping delay message
  middlewareTrack({
    checkoutOrderShippingDetails: {
      amazonPhysicalOrderId: orderId,
      earliestArrivalDate,
      latestArrivalDate,
      deliveryDateRangeDisplayMessage
    }
  });
};

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/CheckoutPostPurchaseOfferCancelClick.proto
const amethystPostPurchaseOfferCancelClick = () => middlewareTrack({ checkoutPostPurchaseOfferCancelClick: {} });

export default {
  clientCalled: CHECKOUT_RECEIVE_ORDER_INFORMATION,
  pageEvent: ORDER_CONFIRMATION_PAGEVIEW,
  hasRedirects: true,
  events: {
    [CHECKOUT_RECEIVE_ORDER]: [amethystTrackOrderShippingDetails],
    [CHECKOUT_POST_PURCHASE_OFFER_CANCEL_CLICK]: [amethystPostPurchaseOfferCancelClick]
  }
};
