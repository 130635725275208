import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { evTopLevelNavigationClick } from 'events/headerFooter';
import { track } from 'apis/amethyst';
import SkipLinks from 'components/common/SkipLinks';
import Link from 'components/hf/HFLink';
import { onEvent } from 'helpers/EventHelpers';
import useMartyContext from 'hooks/useMartyContext';
import { ensureClass, ensureNoClass } from 'helpers';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { triggerAssignment } from 'actions/ab';
import { handleDocClickForNav } from 'actions/headerfooterNav';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/containers/hf/zappos/minifiedHeader.scss';

export const MinifiedHeader = props => {
  const { handleDocClickForNav, openedNav, content } = props;

  const { testId } = useMartyContext();

  useEffect(() => {
    // Handle clicks on the body to close any open dropdown
    onEvent(window, 'click', handleDocClickForNav, null, this);
  }, [handleDocClickForNav]);

  useEffect(() => {
    if (openedNav) {
      ensureClass(document.body, 'inactive');
    } else {
      ensureNoClass(document.body, 'inactive');
    }

    // remove class on unmount
    return () => {
      ensureNoClass(document.body, 'inactive');
    };
  }, [openedNav]);

  const makeLogo = () => {
    const data = content?.Header?.slotData?.logo?.images?.[0];

    return (
      data && (
        <Link
          data-test-id={testId('headerLogo')}
          className={css.hfImagesComponent}
          to={data.href}
          onClick={() => {
            trackLegacyEvent('Global-Header-zapposheader', 'Promos', 'header-logo');
            trackEvent('TE_HEADER_LOGO', data.gae || data.alt);
            track(() => [
              evTopLevelNavigationClick,
              {
                valueClicked: 'Header Logo'
              }
            ]);
          }}
        >
          <img src={data.src} alt={data.alt} height={data.height} width={data.width} />
        </Link>
      )
    );
  };

  const makeSkipLink = () => {
    const links = [{ id: 'main', value: 'Skip to main content' }];
    return <SkipLinks links={links} />;
  };

  return (
    <header role="banner" className={css.headerWrapper} data-header-container>
      {/*
        Show hidden fallback msg so we can know we're using the fallback content data and something
        is definitely probably very wrong.
      */}
      {content?.fallback && <p hidden>***Using Fallback Header***</p>}
      {makeSkipLink()}
      <div className={css.header}>
        <div className={css.headerContainer}>{makeLogo()}</div>
      </div>
    </header>
  );
};

function mapStateToProps(state) {
  const {
    headerFooter: { content, openedNav }
  } = state;

  return {
    content,
    openedNav
  };
}

const MinifiedHeaderConnected = connect(mapStateToProps, {
  handleDocClickForNav,
  triggerAssignment
})(MinifiedHeader);

const MinifiedHeaderConnectedWithErrorBoundary = withErrorBoundary('MinifiedHeader', MinifiedHeaderConnected);
export default MinifiedHeaderConnectedWithErrorBoundary;
