// Zoupon Service
import timedFetch from 'middleware/timedFetch';
import { ERROR_CANNOT_RETRIEVE_ZOUPON_PROMOTION_MESSAGING, FetchError } from 'middleware/fetchErrorMiddleware';
import { fetchOpts, withSession } from 'apis/mafia/common';

export async function getPromotionMessaging({ url }, credentials = {}, products = [], fetcher = timedFetch('promotionMessaging')) {
  const reqUrl = `${url}/zoupon/promotion/messaging`;

  if (!products.length) {
    return;
  }

  const data = {
    redeemed: true,
    components: ['CHECKOUT_MESSAGE', 'MEDIUM_LENGTH_MESSAGE'],
    products
  };

  const opts = fetchOpts(
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    },
    credentials
  );

  const response = await fetcher(reqUrl, opts);
  if (response.status !== 200) {
    throw new FetchError(
      reqUrl,
      response.status,
      'Error in retrieving Zoupon promotion messaging',
      ERROR_CANNOT_RETRIEVE_ZOUPON_PROMOTION_MESSAGING
    );
  }
  const json = await response.json();
  return withSession(json, response.headers);
}
