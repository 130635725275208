import type { AppState } from 'types/app';

export const selectCart = (state: AppState) => state?.cart;

export const selectCartAddedItem = (state: AppState) =>
  selectCart(state)?.cartObj.activeItems?.find(({ stockId }) => stockId === selectCart(state)?.addedStockId);

export const selectCartCount = (state: AppState) => selectCart(state)?.cartCount;

export const selectIsCartCountLoaded = (state: AppState) => selectCart(state)?.isCartCountLoaded;
