import { connect } from 'react-redux';

import { track } from 'apis/amethyst';
import { evBannerClick } from 'events/headerFooter';
import BannerItem from 'components/hf/zappos/BannerItem';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import MelodyCarousel from 'components/common/MelodyCarousel';
import { getAmethystPageType } from 'helpers/analytics';

export const BottomBanner = ({ pageType, globalBanner }) => {
  const { data } = globalBanner;

  if (!data) {
    return null;
  }

  const { slotData, slotNames } = data;

  const leftArrowClick = currentIndex => {
    track(() => [
      evBannerClick,
      {
        bannerAction: 0,
        slotName: slotNames[currentIndex],
        bannerText: slotData[slotNames[currentIndex]].content,
        pageType: getAmethystPageType(pageType)
      }
    ]);
  };

  const rightArrowClick = currentIndex => {
    track(() => [
      evBannerClick,
      {
        bannerAction: 1,
        slotName: slotNames[currentIndex],
        bannerText: slotData[slotNames[currentIndex]].content,
        pageType: getAmethystPageType(pageType)
      }
    ]);
  };

  const returnArrowOverrideObj = {
    'top': '50%',
    'height': '18px',
    'backgroundColor': 'transparent',
    'border': 'transparent',
    '--left-arrow-position': 'calc((100vw - var(--layout-max-width, 1440px)) / 2)',
    '--right-arrow-position': 'calc((100vw - var(--layout-max-width, 1440px)) / 2)'
  };
  const returnmCarouselWrapperOverrides = { maxWidth: '100%' };
  return (
    <MelodyCarousel
      enableAutoRotation={true}
      showDots={false}
      padScrollArea={false}
      leftArrowClick={leftArrowClick}
      rightArrowClick={rightArrowClick}
      arrowStyleOverrides={returnArrowOverrideObj}
      mCarouselWrapperOverrides={returnmCarouselWrapperOverrides}
    >
      {slotNames.map((slotName, i) => (
        <BannerItem key={`${slotName}-${i}`} slotData={slotData[slotName]} slotName={slotName} />
      ))}
    </MelodyCarousel>
  );
};

export const mapStateToProps = state => ({
  pageType: state.pageView.pageType
});

const ConnectedBottomBanner = connect(mapStateToProps)(BottomBanner);

const BottomBannerWithErrorBoundary = withErrorBoundary('BottomBanner', ConnectedBottomBanner);
export default BottomBannerWithErrorBoundary;
