import { deleteFromLocalStorage, loadFromLocalStorage, saveToLocalStorage } from 'helpers/localStorageUtilities';
import { track } from 'apis/amethyst';
import { evExplicitSearch } from 'events/headerFooter';
import { guid } from 'helpers/guid';

export const ZERO_STATE_LS_KEY = 'searchZeroState';
export const MAX_SAVED_TERMS = 8;
export const EXPLICIT_SEARCH_EVENT_KEY = 'explicitSearchEvent';

export type UserSearchTerm = string;

export const updateUserSearchTermsFromLocalStorage = (termSearched: UserSearchTerm): UserSearchTerm[] => {
  const userSearchTerms = loadFromLocalStorage(ZERO_STATE_LS_KEY, []);

  if (termSearched === '') {
    return userSearchTerms;
  }
  // push new / existing term to the top but only return distinct values with the max allowed
  const newTerms = [...new Set([termSearched, ...userSearchTerms])].slice(0, MAX_SAVED_TERMS);
  saveToLocalStorage(ZERO_STATE_LS_KEY, newTerms);
  return newTerms;
};

export const deleteUserSearchTermsFromLocalStorage = (deletedTerm: UserSearchTerm): UserSearchTerm[] => {
  const userSearchTerms = loadFromLocalStorage(ZERO_STATE_LS_KEY, []);
  const filteredUserSearchTerms = userSearchTerms.filter((term: UserSearchTerm) => term !== deletedTerm);
  saveToLocalStorage(ZERO_STATE_LS_KEY, filteredUserSearchTerms);
  return filteredUserSearchTerms;
};

export const saveExplicitSearchEventToLocalStorage = (
  explicitSearchTerm: UserSearchTerm,
  autosuggestionShown: boolean,
  autosuggestionClicked: boolean
) => {
  const explicitSearchEvent = {
    explicitSearchTerm,
    autosuggestionShown,
    autosuggestionClicked,
    timestamp: Date.now(),
    viewId: guid().replace(/-/g, '')
  };
  saveToLocalStorage(EXPLICIT_SEARCH_EVENT_KEY, explicitSearchEvent);
  return explicitSearchEvent;
};

export const fireExplicitSearchEventFromLocalStorage = () => {
  const explicitSearchEvent = loadFromLocalStorage(EXPLICIT_SEARCH_EVENT_KEY, []);
  const { explicitSearchTerm, autosuggestionShown, autosuggestionClicked, timestamp, viewId } = explicitSearchEvent;

  if (explicitSearchEvent) {
    track(() => [evExplicitSearch, { term: explicitSearchTerm, autosuggestionShown, autosuggestionClicked, timestamp, viewId }]);
  }

  deleteFromLocalStorage(EXPLICIT_SEARCH_EVENT_KEY);
};
