// mafia
export const MAFIA_SESSION_ID = 'X-Mafia-Session-Id';
export const MAFIA_SESSION_ID_LOWER = 'x-mafia-session-id';
export const MAFIA_SESSION_TOKEN = 'X-Mafia-Session-Token';
export const MAFIA_SESSION_TOKEN_LOWER = 'x-mafia-session-token';
export const MAFIA_UBID_MAIN = 'X-Mafia-Ubid-Main';
export const MAFIA_UBID_MAIN_LOWER = 'x-mafia-ubid-main';
export const MAFIA_AT_MAIN = 'X-Mafia-At-Main';
export const MAFIA_RECOGNIZED_TOKEN = 'X-Mafia-Recognized-Token';
export const MAFIA_EMAIL_TOKEN = 'X-Mafia-Email-Token';
export const MAFIA_AGENT_AUTH_REQ = 'X-Mafia-Agent-Auth-Requested';
export const MAFIA_AUTH_REQ = 'X-Mafia-Auth-Requested';
export const MAFIA_SESSION_REQ = 'X-Mafia-Session-Requested';
export const MAFIA_CAPTCHA_TOKEN = 'X-Mafia-Captcha-Token';
export const MAFIA_CAPTCHA_ANSWER = 'X-Mafia-Captcha-Answer';
export const MAFIA_CAPTCHA_REQ = 'X-Mafia-Captcha-Requested';
export const MAFIA_CAPTCHA_AUDIO_REQ = 'X-Mafia-Captcha-Audio-Requested';
export const MAFIA_CLIENT = 'X-Mafia-Client';
export const MAFIA_RESPONSE_ID = 'x-amz-rid';

export const GENERIC_SESSION_ID = 'X-Session-Id';
export const GENERIC_SESSION_TOKEN = 'X-Session-Token';
export const GENERIC_UBID_MAIN = 'X-Ubid-Main';

export const HEART_DEFAULT_LIST_ID = 'h.';
export const HEART_DEFAULT_LIST_TYPE = 'h';
export const FAVORITES_PATH_PREFIX = '/account/favorites';
export const ERROR_CUSTOMER_NOT_RECOGNIZED = 'Customer not recognized';
