import { PIXEL_EVENT } from 'constants/amethyst';

// order confirmation pixel
export const evPixelOrderEvent = ({ pageType, pageId, pageLang, pageTitle, trackingPayload = {} }) => ({
  [PIXEL_EVENT]: {
    page_type: pageType,
    page_id: pageId,
    page_lang: pageLang,
    page_title: pageTitle,
    order_confirmation_payload: trackingPayload
  }
});

// brand pixel
export const evPixelBrandEvent = ({ pageType, pageId, pageLang, pageTitle, trackingPayload = {} }) => ({
  [PIXEL_EVENT]: {
    page_type: pageType,
    page_id: pageId,
    page_lang: pageLang,
    page_title: pageTitle,
    brand_payload: trackingPayload
  }
});

// pdp pixel
export const evPixelPdpEvent = ({ pageType, pageId, pageLang, pageTitle, trackingPayload = {} }) => ({
  [PIXEL_EVENT]: {
    page_type: pageType,
    page_id: pageId,
    page_lang: pageLang,
    page_title: pageTitle,
    product_display_page_payload: trackingPayload
  }
});

// search pixel
export const evPixelSearchEvent = ({ pageType, pageId, pageLang, pageTitle, trackingPayload = {} }) => ({
  [PIXEL_EVENT]: {
    page_type: pageType,
    page_id: pageId,
    page_lang: pageLang,
    page_title: pageTitle,
    search_payload: trackingPayload
  }
});

// cart pixel
export const evPixelCartEvent = ({ pageType, pageId, pageLang, pageTitle, trackingPayload = {} }) => ({
  [PIXEL_EVENT]: {
    page_type: pageType,
    page_id: pageId,
    page_lang: pageLang,
    page_title: pageTitle,
    cart_payload: trackingPayload
  }
});

// default pixel event with no payload
export const evPixelEvent = ({ pageType, pageId, pageLang, pageTitle }) => ({
  [PIXEL_EVENT]: {
    page_type: pageType,
    page_id: pageId,
    page_lang: pageLang,
    page_title: pageTitle
  }
});
