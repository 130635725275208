import { VALID_DATE } from 'common/regex';

export const MS_IN_SEC = 1000;
export const MS_IN_MIN = 60000; // 60 * 1000
export const MS_IN_HR = 3600000; // 60 * 1000 * 60
export const MS_IN_DAY = 86400000; // 60 * 1000 * 60 * 24

export function getTimerStrings(ms: number) {
  if (isNaN(ms) || ms < 0) {
    ms = 0;
  }
  const days = Math.floor(ms / MS_IN_DAY).toString();
  const hours = Math.floor((ms % MS_IN_DAY) / MS_IN_HR)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((ms % MS_IN_HR) / MS_IN_MIN)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor((ms % MS_IN_MIN) / MS_IN_SEC)
    .toString()
    .padStart(2, '0');
  return { d: days, h: hours, m: minutes, s: seconds, timeRemaining: ms };
}

// Convert date object to secondss
export function dateToSeconds(time: Date) {
  return Math.round(time.getTime() / MS_IN_SEC);
}

// Takes Date obj or number of seconds
// Returns obj of d/h/m/s & timePassed bool flag
// Adapted from https://stackoverflow.com/a/13904120/2368033
export function dateToTimeObj(time: Date | number) {
  const now = new Date();
  // If not a Date()
  if (!(time instanceof Date) || isNaN(time as unknown as number)) {
    return null;
  }
  // Date has passed
  if (time < now) {
    return { d: 0, h: 0, m: 0, s: 0, timePassed: true };
  }
  // Get diff in seconds between both dates
  let delta = dateToSeconds(time) - dateToSeconds(now);
  // Calc days
  const d = Math.floor(delta / 86400); // 60 * 60 * 24 = 86400
  delta -= d * 86400;
  // Calc hrs
  const h = Math.floor(delta / 3600) % 24; // 60 * 60 = 3600
  delta -= h * 3600;
  // Calc mins
  const m = Math.floor(delta / 60) % 60;
  delta -= m * 60;
  // Calc secs
  const s = delta % 60;
  return { d, h, m, s, timePassed: false };
}

export function formatAMPM(date: Date, { trimMins = false, amPmLowerCase = false, trimSpace = false } = {}) {
  const args: Record<string, string> = { hour: 'numeric' };
  if (!trimMins) {
    args.minute = 'numeric';
  }

  let newDate = date.toLocaleTimeString('en-US', args);

  if (amPmLowerCase) {
    newDate = newDate.toLowerCase();
  }
  if (trimSpace) {
    newDate = newDate.replace(' ', '');
  }
  return newDate;
}

export const convertToUTC = (dateTime: string) => {
  try {
    if (dateTime.match(VALID_DATE)) {
      const [date, time] = dateTime.split(' ');
      const [year, month, day] = date?.split('-') || [];
      const [hour, minute, second] = time?.split(':') || [];
      return Date.UTC(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute), Number(second));
    }
    return;
  } catch (err) {
    return;
  }
};
