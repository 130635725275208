import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import UtilityDottedTrueToSizeMediumIcon from 'tailwind/components/Icons/UtilityDottedTrueToSizeMediumIcon';
import { selectIsFeaturePdpPaperCuts } from 'selectors/features';

export interface RecommendedSizingLayoutProps {
  children?: ReactNode;
  isIncludeIcon?: boolean;
}

const RecommendedSizingLayout = ({ children, isIncludeIcon = true }: RecommendedSizingLayoutProps) => {
  const { isInOneOfPdpPaperCutsTreatments } = useSelector(selectIsFeaturePdpPaperCuts);

  if (!isInOneOfPdpPaperCutsTreatments || !isIncludeIcon) {
    return children;
  }

  return (
    <div className="flex h-fit w-fit gap-x-2">
      <UtilityDottedTrueToSizeMediumIcon size={24} className="flex-none" />
      {children}
    </div>
  );
};

export default RecommendedSizingLayout;
