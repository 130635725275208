import ProductGender from 'components/common/ProductGender';

interface CardGenderProps {
  gender: string;
}

function CardGender({ gender }: CardGenderProps) {
  return (
    <>
      <dt>Gender</dt>
      <dd itemProp="gender" className="mb-2">
        <ProductGender gender={gender} />
      </dd>
    </>
  );
}

export default CardGender;
