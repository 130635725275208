import type { ABTestOverrideEvent } from 'types/amethyst';

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ABTestEnrollment.proto
 *
 * @param {string} test
 * @param {string} phase
 * @param {string} group
 */
export const evABTestEnrollment = ({ test, phase, group, variation }: { test: string; phase: string; group: string; variation: string }) => ({
  abTestEnrollment: {
    name: test,
    phase,
    testGroup: group,
    variation
  }
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ABTestOverride.proto
 *
 * @param {string} name
 */
export const evABTestOverride = ({ name }: { name: string }): ABTestOverrideEvent => ({
  abTestOverride: {
    name
  }
});
