import type { IconButtonSize, IconButtonVariant } from 'tailwind/components/IconButton/IconButton.types';

export const baseClasses = `
  appearance-none
  box-border
  enabled:cursor-pointer
  inline-flex
  items-center
  justify-center
  no-underline
  outline-offset-2
  p-0
  rounded-full
  select-none
  transitions
`;

const disabledClasses = 'cursor-not-allowed';
const filledClasses = 'border-none text-icon-reversed';
const outlinedClasses = 'bg-button-transparent border border-solid';

export const variantClasses: Record<IconButtonVariant, { enabled: string; disabled: string }> = {
  filled: {
    enabled: `${filledClasses} bg-button-primary hover:bg-button-hover`,
    disabled: `${filledClasses} ${disabledClasses} bg-button-disabled-subtle`
  },
  outlined: {
    enabled: `${outlinedClasses} border-button-primary text-icon-primary hover:border-button-hover hover:text-icon-hover`,
    disabled: `${outlinedClasses} ${disabledClasses} border-button-disabled text-icon-disabled`
  },
  moderateFilled: {
    enabled: `border-none bg-button-transparent hover:bg-primary-moderate active:bg-primary-bold text-icon-primary`,
    disabled: `border-none bg-button-transparent ${disabledClasses} text-icon-moderate`
  }
};

export const selectedClasses: Record<IconButtonVariant, string> = {
  filled: '',
  outlined: 'border-2',
  moderateFilled: ''
};

export const sizeClasses: Record<IconButtonSize, string> = {
  xsmall: 'h-[24px] w-[24px]',
  small: 'h-[32px] w-[32px]',
  base: 'h-[44px] w-[44px]'
};
