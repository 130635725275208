// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeLogoutMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M28.2918 15.9954H12.0418M28.2918 15.9954L25.1668 12.8704M28.2918 15.9954L25.1668 19.1204M19.9585 11.8287V7.8704C19.9585 5.5691 18.093 3.70374 15.7918 3.70374H7.87516C5.57397 3.70374 3.7085 5.5691 3.7085 7.8704V24.1204C3.7085 26.4216 5.57397 28.2871 7.87516 28.2871H15.7918C18.093 28.2871 19.9585 26.4216 19.9585 24.1204V20.1621"
      stroke="currentcolor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default UtilityStrokeLogoutMediumIcon;
