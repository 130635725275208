import { SET_BADGES_METADATA } from 'constants/reduxActions';
import type { BadgesState } from 'types/badges';
import type { BadgesAction } from 'actions/badges';

const initialState = {
  metadata: []
};

export default function badges(state: Readonly<BadgesState> = initialState, action: BadgesAction): BadgesState {
  switch (action.type) {
    case SET_BADGES_METADATA:
      const { metadata } = action;
      return {
        ...state,
        metadata
      };
    default:
      return state;
  }
}
