import { forwardRef } from 'react';
import cn from 'clsx';

import type { FooterMenuProps } from 'components/hf/zappos/FooterMenuHeading/FooterMenuHeading.types';
import { headingClasses } from 'components/hf/zappos/FooterMenuHeading/FooterMenuHeading.classes';

const FooterMenuHeading = forwardRef<HTMLHeadingElement, FooterMenuProps>((props: FooterMenuProps, ref) => {
  const { text, className, ...forwardProps } = props;

  return (
    <h3 ref={ref} className={cn(headingClasses, className)} {...forwardProps}>
      {text}
    </h3>
  );
});

FooterMenuHeading.displayName = 'FooterMenuHeading';

export default FooterMenuHeading;
