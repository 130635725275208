import { PAYMENT_FIELDS } from 'constants/formFields';
import {
  CLEAR_PAYMENT_FORM_ERRORS,
  CLEAR_PAYMENT_FORM_ITEM,
  GET_PAYMENTS_SUCCESS,
  SET_HAS_VERIFY_CC_ERROR,
  SET_PAYMENT_DATA_LOADING,
  SET_PAYMENT_FORM_ITEM
} from 'store/ducks/payment/types';
import { CONFIGURE_CHECKOUT_SUCCESS, SAVE_CHECKOUT_PAYMENT_METHOD } from 'store/ducks/checkout/types';
import { SET_FORM_ERRORS } from 'store/ducks/types';
import { toFormatted } from 'store/ducks/address/utils';
import { convertExpirationTimeToString, needsToReAssociatePaymentToAddressForPaymentForPurchaseCvs } from 'helpers/CheckoutUtils';
import { isCreditCardExpired } from 'helpers/MyAccountUtils';

const defaultState = {
  formItem: {},
  isLoading: false,
  isLoaded: false,
  savedPayments: []
};

export default function paymentReducer(state = defaultState, action = {}) {
  const { instrument, type, hasVerifyCreditCardError, payload } = action;

  switch (type) {
    case SAVE_CHECKOUT_PAYMENT_METHOD: {
      const { cc } = instrument;
      return { ...state, lastSavedCard: cc };
    }

    case CONFIGURE_CHECKOUT_SUCCESS: {
      const { paymentOptions, purchaseStatus } = payload;
      const { savedPayments: stateSavedPayments, payPalPaymentMethod: statePayPalPaymentMethod } = state;
      const { constraintViolations, shippingAddressId, paymentMethods } = purchaseStatus;
      const savedPayments = paymentOptions ? paymentOptions.paymentInstruments : stateSavedPayments;

      const paymentsWithSelectedIndicator = storeSelectedPayment(savedPayments, paymentMethods);
      let payments = storeExpiredStatus(paymentsWithSelectedIndicator);
      payments = filterNonBasicCreditCards(payments);

      const selectedPaymentBillingAddress = getSelectedPaymentBillingAddress(payments);
      const isBillingSameAsShipping = getIsBillingSameAsShipping(selectedPaymentBillingAddress, shippingAddressId);
      const selectedPaymentNeedsConfirmation = getSelectedPaymentNeedsConfirmation(payments, constraintViolations);
      const payPalPaymentMethod = getPayPalPaymentMethod(savedPayments) || statePayPalPaymentMethod;

      return transformState({
        ...state,
        isBillingSameAsShipping,
        isLoaded: true,
        isLoading: false,
        payPalPaymentMethod,
        savedPayments: payments,
        selectedPaymentBillingAddress,
        selectedPaymentNeedsConfirmation,
        formItem: {}
      });
    }

    case GET_PAYMENTS_SUCCESS: {
      const { paymentInstruments: savedPayments } = payload;
      return { ...state, savedPayments, isLoaded: true, isLoading: false };
    }

    case SET_HAS_VERIFY_CC_ERROR: {
      return { ...state, hasVerifyCreditCardError, isLoading: false };
    }

    case SET_PAYMENT_DATA_LOADING: {
      return { ...state, isLoading: payload };
    }

    case SET_PAYMENT_FORM_ITEM: {
      return { ...state, formItem: payload };
    }

    case CLEAR_PAYMENT_FORM_ITEM: {
      return { ...state, formItem: {}, isLoading: false };
    }

    case SET_FORM_ERRORS: {
      const formErrors = {};
      payload.forEach(error => {
        const key = requestFieldToFormField[error.fieldName]?.formField.fieldName;

        // we get back expirationTime in extraInformation, if this happens - manually set error
        if (error.expirationTime) {
          const key = PAYMENT_FIELDS.CC.fieldName;
          const msg = getError(key, 'ccLimitReached');
          const hoursAndMinutes = convertExpirationTimeToString(error.expirationTime);
          formErrors[key] = msg.replace('hoursAndMinutes', hoursAndMinutes);
        }

        if (key) {
          const msg = getError(key, error.id);
          formErrors[key] = msg;
        }
      });

      return { ...state, formItem: { ...state.formItem, formErrors } };
    }

    case CLEAR_PAYMENT_FORM_ERRORS: {
      return { ...state, formItem: { ...state.formItem, formErrors: {} } };
    }

    default: {
      return state;
    }
  }
}

export function transformState(state) {
  const { savedPayments, selectedPaymentBillingAddress } = state;

  const { addressId: billingAddressId } = selectedPaymentBillingAddress || {};

  return {
    ...state,
    billingAddressId,
    hasSavedPayments: !!savedPayments.length
  };
}

export function getSelectedPaymentNeedsConfirmation(payments, constraintViolations) {
  const paymentDetails = (payments || []).find(item => item.isSelected) || {
    associatedAddress: {}
  };
  const { paymentInstrumentId } = paymentDetails;
  return needsToReAssociatePaymentToAddressForPaymentForPurchaseCvs(constraintViolations, paymentInstrumentId);
}

export function getIsBillingSameAsShipping(selectedPaymentBillingAddress, shippingAddressId) {
  const { addressId } = selectedPaymentBillingAddress || {};
  return addressId === shippingAddressId;
}

export function getSelectedPaymentBillingAddress(payments) {
  const paymentDetails = (payments || []).find(item => item.isSelected) || {
    billingAddress: {}
  };
  const { billingAddress: unFormattedAddress } = paymentDetails;
  return toFormatted(unFormattedAddress || {});
}

export function storeSelectedPayment(payments, paymentMethods) {
  const { paymentInstrumentId: selectedPaymentInstrumentId } = paymentMethods.find(item => item.paymentMethodCode === 'CC') || {};

  const modifiedPayments = payments.map(payment => {
    if (payment.paymentInstrumentId === selectedPaymentInstrumentId) {
      return { ...payment, isSelected: true };
    } else {
      return { ...payment, isSelected: false };
    }
  });

  return modifiedPayments;
}

export function storeExpiredStatus(payments) {
  const modifiedPayments = payments.map(payment => {
    const isExpired = isCreditCardExpired(payment);
    return { ...payment, isExpired };
  });

  return modifiedPayments;
}

export function filterNonBasicCreditCards(payments) {
  return payments.filter(payment => payment.fullName !== 'Afterpay' && payment.paymentCategory !== 'PAYPAL');
}

export function getPayPalPaymentMethod(payments) {
  return payments.find(payment => payment.paymentCategory === 'PAYPAL');
}

export function getError(field, type) {
  if (type === 'required.input.missing') {
    return fieldErrors.missingValue[field] || 'Required';
  }

  if (fieldErrors.hasOwnProperty(type)) {
    return fieldErrors[type];
  }

  return genericErrors[type] || 'Invalid';
}

const requestFieldToFormField = {
  addCreditCardNumber: { form: 'payment', formField: PAYMENT_FIELDS.CC },
  fullName: { form: 'payment', formField: PAYMENT_FIELDS.NAME_ON_CARD },
  expirationDate: {
    form: 'payment',
    formField: PAYMENT_FIELDS.CC_EXPIRATION_MELODY
  }
};

const genericErrors = {
  'input.invalid': 'Invalid input',
  'required.input.missing': 'Required',
  'validation.exception': 'Invalid Input',
  'max.length.exceeded': 'Input too long',
  'credit.card.verification.declined': 'Credit card verification declined'
};

const fieldErrors = {
  invalidPhone: 'Invalid phone number',
  ccExpired: 'Card has expired.',
  ccBadFormat: 'Card number is not correct.',
  ccExpirationBadFormat: 'Expiration date is not correct.',
  ccLimitReached: 'Credit card verification declined. You won’t be able to add a card for the next hoursAndMinutes due to security reasons.',
  missingValue: {
    [PAYMENT_FIELDS.CC.fieldName]: 'Please enter a card number.',
    [PAYMENT_FIELDS.CC_EXPIRATION.fieldName]: 'Expiration date is not correct.',
    [PAYMENT_FIELDS.CC_EXPIRATION_MELODY.fieldName]: 'Expiration date is not correct.',
    [PAYMENT_FIELDS.CC_EXPIRATION_MONTH.fieldName]: 'Please enter an expiration month.',
    [PAYMENT_FIELDS.CC_EXPIRATION_YEAR.fieldName]: 'Please enter an expiration year.',
    [PAYMENT_FIELDS.NAME_ON_CARD.fieldName]: "Please enter cardholder's name."
  }
};
