import ExecutionEnvironment from 'exenv';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';
import type { AnyAction } from 'redux';
import { stringify } from 'query-string';

import imageServerConfig from 'helpers/imageServerConfig';
import {
  EVENT_SIZING_IMPRESSION,
  HIDE_SELECT_SIZE_TOOLTIP,
  HIGHLIGHT_SELECT_SIZE_TOOLTIP,
  PRODUCT_AGE_GROUP_CHANGED,
  PRODUCT_COLOR_CHANGED,
  PRODUCT_DESCRIPTION_COLLAPSED,
  PRODUCT_DESCRIPTION_TOGGLE,
  PRODUCT_GENDER_CHANGED,
  PRODUCT_SINGLE_SHOE_SIDE_CHANGED,
  PRODUCT_SIZE_CHANGED,
  PRODUCT_SIZE_RANGE_CHANGED,
  PRODUCT_SIZE_UNIT_CHANGED,
  PRODUCT_SWATCH_CHANGE,
  RECEIVE_BRAND_PROMO,
  RECEIVE_GENERIC_SIZING_BIAS,
  RECEIVE_LOWEST_PRICES,
  RECEIVE_PDP_STORY_SYMPHONY_COMPONENTS,
  RECEIVE_PDP_SYMPHONY_COMPONENTS,
  RECEIVE_PRODUCT_DETAIL,
  RECEIVE_SIMILAR_STYLES,
  RECEIVE_SIZING_PREDICTION_FAILURE,
  RECEIVE_SIZING_PREDICTION_SUCCESS,
  REQUEST_LOWEST_PRICES,
  REQUEST_PDP_STORY_SYMPHONY_COMPONENTS,
  REQUEST_PDP_SYMPHONY_COMPONENTS,
  REQUEST_PRODUCT_DETAIL,
  REQUEST_SIMILAR_STYLES,
  SET_DOC_META_PDP,
  SHOW_SELECT_SIZE_TOOLTIP,
  TOGGLE_OOS_BUTTON,
  UNHIGHLIGHT_SELECT_SIZE_TOOLTIP,
  VALIDATE_DIMENSIONS
} from 'constants/reduxActions';
import { fetchSearchSimilarity } from 'apis/calypso';
import { err, setError } from 'actions/errors';
import timedFetch from 'middleware/timedFetch';
import { fetchAllowNotFoundErrorMiddleware, fetchErrorMiddleware, fetchErrorMiddlewareMaybeJson } from 'middleware/fetchErrorMiddleware';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { setSessionCookies } from 'actions/session';
import { trackEvent } from 'helpers/analytics';
import { createViewProductPageMicrosoftUetEvent, pushMicrosoftUetEvent } from 'actions/microsoftUetTag';
import { firePixelServer } from 'actions/pixelServer';
import { redirectTo, redirectToSearch } from 'actions/redirect';
import { absoluteImageUrl } from 'helpers/productImageHelpers';
import ProductUtils from 'helpers/ProductUtils';
import { isAllowedPreferredSubsite } from 'helpers/MarketplaceUtils';
import { buildSeoProductString, buildSeoProductUrl } from 'helpers/SeoUrlBuilder';
import { trackError } from 'helpers/ErrorUtils';
import { productBundle } from 'apis/cloudcatalog';
import { genericSizeBias, lowestPrices, sizingPrediction } from 'apis/opal';
import { getSymphonySlots } from 'apis/zcs';
import { IS_EGC_NAME, IS_NUMBER_RE, PRODUCT_ASIN } from 'common/regex';
import marketplace from 'cfg/marketplace.json';
import { sessionLoggerMiddleware } from 'middleware/sessionLoggerMiddleware';
import type { MapSomeDimensionIdTo, ProductBundle, ProductStyle } from 'types/cloudCatalog';
import type { AppState } from 'types/app';
import type { BrandPromo, PDPSymphonyComponent, PDPSymphonyStory, ProductLookupKey, ProductSimilarStyleResponse } from 'types/product';
import type { AirplaneCache } from 'types/AirplaneCache';
import type { FormattedProductBundle } from 'reducers/detail/productDetail';
import type { LowestStylePrice } from 'types/opal';
import { selectCalypsoConfig, selectOpalApi, selectZcsConfig } from 'selectors/environment';
import { productNotFound } from 'actions/productdetail/productNotFound';
import type { ZapposContentService } from 'types/zcs';
import type { Cookies } from 'types/cookies';
import { fetchOpts } from 'apis/mafia/common';

const {
  subsiteId: { desktop: subsiteId },
  pdp: { egcUrl, includeTsdImagesParam = false, hasLowestRecentPrice }
} = marketplace;

export function requestProductDetail() {
  return {
    type: REQUEST_PRODUCT_DETAIL
  } as const;
}

export function productSwatchChange(colorId: string) {
  return {
    type: PRODUCT_SWATCH_CHANGE,
    colorId
  } as const;
}

export function receiveProductDetail(
  productDetail: ProductBundle,
  imageServerUrl: string,
  lookupKeyObject: ProductLookupKey,
  colorId: string | undefined,
  // TODO annotate the proper type for this when search is typed
  searchFilters: unknown
) {
  return {
    type: RECEIVE_PRODUCT_DETAIL,
    product: { detail: productDetail },
    imageServerUrl,
    colorId,
    lookupKeyObject,
    receivedAt: Date.now(),
    searchFilters,
    calledClientSide: typeof window !== 'undefined'
  } as const;
}

export function firePdpPixelServer(product: ProductBundle | FormattedProductBundle, colorId?: string) {
  const style = ProductUtils.getStyleByColor(product.styles, colorId)!;
  return firePixelServer('pdp', {
    product: {
      sku: product.productId,
      styleId: style.styleId,
      price: style.price.replace('$', ''),
      name: product.productName,
      brand: product.brandName,
      category: product.defaultProductType,
      subCategory: product.defaultCategory,
      gender: ProductUtils.getGender(product)
    }
  });
}

export function toggleOosButton(oosModalActive: boolean) {
  return {
    type: TOGGLE_OOS_BUTTON,
    oosModalActive
  } as const;
}

function handleOos(dispatch: ThunkDispatch<AppState, void, AnyAction>, product: ProductBundle) {
  const seoTerm = buildSeoProductString(product);
  if (seoTerm) {
    dispatch(redirectToSearch(seoTerm));
  } else {
    dispatch(productNotFound());
  }
}

export type CCFetchOpts = Partial<{
  background: boolean;
  colorId: string;
  filterNonHardLaunchDateOosStyles: boolean;
  firePixel: boolean;
  errorOnOos: boolean;
  includeOos: boolean;
  includeOosSizing: boolean;
  includeRecos: boolean;
  includeTsdImages: boolean;
  isAllowedSubsite: typeof isAllowedPreferredSubsite;
  seoName: string;
  callbackOnSuccess: (...args: any[]) => any;
}>;
export function loadProductDetailPage(
  lookupKey: ProductLookupKey | string | number,
  options: CCFetchOpts
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return function (dispatch, getState) {
    return dispatch(fetchProductDetail(lookupKey, options)).then(() => {
      const state = getState();
      const {
        product: { detail },
        error
      } = state;
      const isProductTypeShoesOrClothing = ProductUtils.isProductTypeShoesOrClothing(detail?.defaultProductType);

      // only do this if we have actually properly loaded a product, otherwise we'll get error noise in the logs
      if (detail && !error) {
        dispatch(setProductDocMeta(detail, options?.colorId, isProductTypeShoesOrClothing));
      }
    });
  };
}

export function getSymphonyContentAfterPdpLoad(
  { styles, productId }: { styles: ProductStyle[]; productId: string },
  colorId: string,
  getCall = getSymphonyComponents
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return dispatch => {
    const style = ProductUtils.getStyleByColor(styles, colorId)!;
    const { styleId } = style;
    return dispatch(getCall(productId, styleId));
  };
}

export function setProductDocMeta(product: FormattedProductBundle, colorId?: string, isProductTypeShoesOrClothing?: boolean) {
  return { type: SET_DOC_META_PDP, metaPayload: { product, colorId, isProductTypeShoesOrClothing } } as const;
}

// Filter styles out if there are OOS and without a future hardLaunchDate
export function makefilteredNonHardLaunchDateOosStyles(detail: ProductBundle) {
  const styles = detail.styles.filter(style => {
    const { hardLaunchDate } = style;
    const hasStock = ProductUtils.hasAvailableStock(style);
    const hasFutureHardLaunchDate = hardLaunchDate ? new Date(hardLaunchDate).getTime() > Date.now() : false;
    return hasStock || hasFutureHardLaunchDate;
  });
  return { ...detail, styles };
}

/**
 * Loads product data for the given product and stores it in the product reducer.
 * @param  {String|Object}  productId            Product to load either string productId, or an object with an asin, stockId, or productId field.
 * @param  {Object}  options              Optional flags-
 *                                        background(boolean=false) if true, does not set the isLoading flag while the request is running.  Additionally will not set full page error if request fails if it is "background" request.
 *                                          includeRecos(boolean=false) if calypso searchsimilarity should be sent.
 *                                          colorId(String)-- color of the product being viewed. only used for pixel server
 *                                          seoName(String) -- seo friendly name of product -- used to redirect to search if OOS
 *                                          firePixel(boolean=false) whether to fire the pdp pixel on load
 * @return {Promise}
 */
export function fetchProductDetail(
  lookupKeyObject: ProductLookupKey | string | number,
  {
    background = false,
    includeRecos = false,
    includeTsdImages = includeTsdImagesParam,
    colorId,
    seoName,
    firePixel = false,
    errorOnOos = true,
    includeOos = false,
    includeOosSizing = false,
    callbackOnSuccess,
    filterNonHardLaunchDateOosStyles = false,
    isAllowedSubsite = isAllowedPreferredSubsite
  }: CCFetchOpts = {}
): ThunkAction<Promise<any>, AppState, void, AnyAction> {
  return function (dispatch, getState) {
    if (typeof lookupKeyObject === 'string' || typeof lookupKeyObject === 'number') {
      lookupKeyObject = { productId: '' + lookupKeyObject };
    }
    if (lookupKeyObject.productId && !IS_NUMBER_RE.test(lookupKeyObject.productId)) {
      dispatch(productNotFound());
      return Promise.reject(err.PRODUCT_DETAILS);
    } else if (lookupKeyObject.asin) {
      lookupKeyObject.asin = lookupKeyObject.asin.toUpperCase();
      if (!PRODUCT_ASIN.test(lookupKeyObject.asin)) {
        dispatch(productNotFound());
        return Promise.reject(err.PRODUCT_DETAILS);
      }
    } else if (lookupKeyObject.stockId && !IS_NUMBER_RE.test(lookupKeyObject.stockId)) {
      dispatch(productNotFound());
      return Promise.reject(err.PRODUCT_DETAILS);
    }

    const state = getState();
    const {
      cookies,
      environmentConfig: {
        api: { cloudcatalog: cloudcatalogInfo }
      },
      filters: searchFilters,
      pageView: { pageType },
      url: { userAgent }
    } = state;

    const imageServerOpts = imageServerConfig(state);
    if (!background) {
      dispatch(requestProductDetail());
    }

    const productRequest = productBundle(
      cloudcatalogInfo,
      {
        ...lookupKeyObject,
        includeTsdImages,
        includeOos,
        includeOosSizing
      },
      timedFetch('cloudCatalogProduct'),
      cookies
    )
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchErrorMiddleware);

    const dispatchErrorMessageOrNotFound = function (error: any) {
      if (error && error.status === 404 && seoName) {
        dispatch(redirectToSearch(seoName));
      } else {
        if (!background) {
          dispatch(setError(err.PRODUCT_DETAILS, error));
        }
      }
      return Promise.reject(err.PRODUCT_DETAILS);
    };

    if (hasLowestRecentPrice && lookupKeyObject.productId) {
      dispatch(fetchLowestPrices(lookupKeyObject.productId));
    }

    return productRequest
      .then(async productResponse => {
        if (!productResponse.product || productResponse.product.length !== 1) {
          dispatch(productNotFound());
          // Also throw an error because this means the response returned without the data we need
          throw err.PRODUCT_DETAILS;
        }

        const product = filterNonHardLaunchDateOosStyles
          ? makefilteredNonHardLaunchDateOosStyles(productResponse.product[0]!)
          : productResponse.product[0]!;

        const { preferredSubsite } = product;

        if (preferredSubsite) {
          const { url, id } = preferredSubsite;
          const domConditionalUserAgent = ExecutionEnvironment.canUseDOM ? navigator.userAgent : userAgent;
          const shouldRedirect = isAllowedSubsite(marketplace, id, domConditionalUserAgent);
          // we dont want to redirect for the reviews page https://github01.zappos.net/mweb/marty/issues/11165
          const isPdpPageType = pageType === 'product';
          if (shouldRedirect && isPdpPageType) {
            return dispatch(redirectTo(`https://${url}${buildSeoProductUrl(product, colorId)}`, 301));
          }
        }

        if (IS_EGC_NAME.test(product.productName)) {
          return dispatch(redirectTo(egcUrl));
        }

        const { defaultImageUrl, styles } = product;
        const { imageServerUrl } = imageServerOpts;

        // If there are no styles, the product is effectively out of stock.
        if ((!styles || styles.length < 1) && errorOnOos) {
          return handleOos(dispatch, product);
        }

        if (typeof lookupKeyObject === 'string' || typeof lookupKeyObject === 'number') {
          lookupKeyObject = { productId: '' + lookupKeyObject };
        }

        const lookup = lookupKeyObject; // Previous typeguards won't be in effect, as the narrowing won't cross function scopes. Re-assigning safely fixes this.

        if (lookup.asin || lookup.stockId) {
          const style = styles.find(style => style.stocks.find(stock => stock.asin === lookup.asin || stock.stockId === lookup.stockId));
          if (style) {
            ({ colorId } = style);
          }
        }

        product.hasHalfSizes = null;
        if (ProductUtils.isShoeType(product.defaultProductType) && product.sizing.allValues) {
          product.hasHalfSizes = product.sizing.allValues.some(({ value }) => value.includes('.5'));
        }

        product.defaultImageUrl = absoluteImageUrl(defaultImageUrl, imageServerUrl);

        dispatch(receiveProductDetail(product, imageServerUrl, lookupKeyObject, colorId, searchFilters));
        if (callbackOnSuccess) {
          dispatch(callbackOnSuccess(product, colorId));
        }
        if (firePixel) {
          dispatch(pushMicrosoftUetEvent(createViewProductPageMicrosoftUetEvent(product.productId)));
          dispatch(firePdpPixelServer(product, colorId));
        }

        // If we're also fetching recos and/or reviews, then the resulting promise should resolve
        // when the recos are loaded, otherwise the promise is resolved when details are loaded
        if (includeRecos) {
          const { styleId } = styles[0]!;
          return fetchProductSearchSimilarity(product.productId, styleId)(dispatch, getState);
        }
        return product;
      })
      .catch(dispatchErrorMessageOrNotFound);
  };
}

function requestSimilarStyles() {
  return {
    type: REQUEST_SIMILAR_STYLES,
    requestedAt: Date.now()
  } as const;
}

export function receiveSimilarStyles(productId: string, similarStylesData: ProductSimilarStyleResponse) {
  return {
    type: RECEIVE_SIMILAR_STYLES,
    similarStylesData,
    productId,
    receivedAt: Date.now()
  } as const;
}

// These are Calypso "searchSimilarity" recos not true janus recos.  Instead see actions/recos.
export function fetchProductSearchSimilarity(
  productId: string,
  styleId: string,
  type = 'moreLikeThis',
  limit = 10,
  page = 1,
  fetcher = fetchSearchSimilarity
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return function (dispatch, getState) {
    const state = getState();
    const calypso = selectCalypsoConfig(state);

    dispatch(requestSimilarStyles);
    return fetcher(calypso.url, {
      styleId,
      type,
      limit,
      page,
      siteId: calypso.siteId,
      subsiteId,
      opts: imageServerConfig(state)
    })
      .then((similarStyleResponse: ProductSimilarStyleResponse) => {
        dispatch(receiveSimilarStyles(productId, similarStyleResponse));
      })
      .catch((e: Error) => {
        dispatch(receiveSimilarStyles(productId, { results: [] }));
        trackError('NON-FATAL', 'Could not load Similar Product Styles.', e);
      });
  };
}

export function productAgeGroupChanged(ageGroup: AirplaneCache['constraints']['ageGroup']) {
  return { type: PRODUCT_AGE_GROUP_CHANGED, ageGroup } as const;
}

export function productGenderChanged(id: AirplaneCache['constraints']['gender']) {
  return { type: PRODUCT_GENDER_CHANGED, id } as const;
}

export function productSingleShoeSideChanged(id: AirplaneCache['constraints']['shoeType']) {
  return { type: PRODUCT_SINGLE_SHOE_SIDE_CHANGED, id } as const;
}

export function productSizeRangeChanged(id: AirplaneCache['constraints']['sizeRange']) {
  return { type: PRODUCT_SIZE_RANGE_CHANGED, id } as const;
}

export function productSizeUnitChanged(id: AirplaneCache['constraints']['countryOrUnit']) {
  return { type: PRODUCT_SIZE_UNIT_CHANGED, id } as const;
}

export function productSizeChanged(dimensions: MapSomeDimensionIdTo<string>) {
  return {
    type: PRODUCT_SIZE_CHANGED,
    dimensions
  } as const;
}

export function toggleProductDescription(payload: string) {
  return { type: PRODUCT_DESCRIPTION_TOGGLE, payload } as const;
}

export function onProductDescriptionCollapsed(ref: React.RefObject<HTMLElement>) {
  return { type: PRODUCT_DESCRIPTION_COLLAPSED, ref } as const;
}

export function validateDimensions(showValidation?: boolean) {
  return {
    type: VALIDATE_DIMENSIONS,
    showValidation: !!showValidation
  } as const;
}

export function showSelectSizeTooltip() {
  return {
    type: SHOW_SELECT_SIZE_TOOLTIP
  } as const;
}

export function hideSelectSizeTooltip() {
  return {
    type: HIDE_SELECT_SIZE_TOOLTIP
  } as const;
}

export function highlightSelectSizeTooltip() {
  return {
    type: HIGHLIGHT_SELECT_SIZE_TOOLTIP
  } as const;
}

export function unhighlightSelectSizeTooltip() {
  return {
    type: UNHIGHLIGHT_SELECT_SIZE_TOOLTIP
  } as const;
}

export function receiveSizingPredictionSuccess(sizingPredictionValue: string, colorId: string) {
  return {
    type: RECEIVE_SIZING_PREDICTION_SUCCESS,
    sizingPredictionValue,
    colorId
  } as const;
}

export function receiveSizingPredictionFailure(isOnDemandEligible: boolean | undefined) {
  return {
    type: RECEIVE_SIZING_PREDICTION_FAILURE,
    isOnDemandEligible
  } as const;
}

interface OpalSizeBias {
  value: string;
  score: number;
}

export function receiveGenericSizingBias(genericSizeBiases: { productId: string; sizeBiases: OpalSizeBias[]; text: string }) {
  const amethystSizeBiases = {
    productId: genericSizeBiases.productId,
    sizeBiases: genericSizeBiases.sizeBiases.map(sizeBias => ({ value: sizeBias.value, score: String(sizeBias.score) })),
    text: genericSizeBiases.text
  };
  return {
    type: RECEIVE_GENERIC_SIZING_BIAS,
    genericSizeBiases: amethystSizeBiases
  } as const;
}

export function fetchSizingPrediction(
  detail: FormattedProductBundle,
  productId: string,
  colorId: string,
  sizingPredictionFetcher = sizingPrediction
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return function (dispatch, getState) {
    const state = getState();
    const {
      environmentConfig: {
        api: { opal: opalInfo }
      }
    } = state;
    return sizingPredictionFetcher(opalInfo, { productId })
      .then(fetchErrorMiddleware)
      .then(response => {
        const { prediction, error, onDemandEligible } = response;
        if (prediction) {
          dispatch(receiveSizingPredictionSuccess(prediction, colorId));
          trackEvent('TE_PDP_SIZING', `${productId}:SizeSelected:${prediction}`);
          trackEvent('TE_PDP_SIZING', `${productId}:SizeSuggested:${prediction}`);
          // We fetch the generic sizing bias regardless of sizing prediction
          dispatch(fetchGenericSizingBias(detail, productId));
        } else {
          dispatch(receiveSizingPredictionFailure(onDemandEligible));
          trackEvent('TE_PDP_SIZING', `${productId}:NoResponse:${error}`);
          // If there isn't an explicit sizing prediction available, get generic size bias data
          dispatch(fetchGenericSizingBias(detail, productId));
        }
      })
      .catch(e => {
        trackError('NON-FATAL', 'Could not load Sizing Prediction.', e);
      });
  };
}

interface ProductSizeBias {
  productId: string;
  sizeBiases: OpalSizeBias[];
  roundingMessaging: string | null;
}

const ROUNDING_MESSAGING: { [index: string]: string } = {
  TRUE_TO_SIZE_TEXT: '<p>This fits <strong>true to size</strong>.</p>',
  FITS_FULL_UP_TEXT: '<p>Fits <strong>true to size</strong> for most. Consider a <strong>size up</strong> if you wear half sizes.</p>',
  FITS_FULL_DOWN_TEXT: '<p>Fits <strong>true to size</strong> for most. Consider a <strong>size down</strong> if you wear half sizes.</p>',
  FULL_UP_TEXT: '<p>Fits a <strong>size up</strong> for most. Your usual size may feel small.</p>',
  FULL_DOWN_TEXT: '<p>Fits a <strong>full size down</strong> for most. Your usual size may feel too big.</p>',
  HALF_UP_TEXT: '<p>Consider a <strong>1/2 size up</strong> from your usual size if you have wide feet or are between sizes.</p>',
  HALF_DOWN_TEXT: '<p>Consider a <strong>1/2 size down</strong> from your usual size if you have narrow feet.</p>',
  RUNS_BIG_TEXT:
    '<p>This runs big. Consider a <strong>1/2 size down from your usual size</strong> or a <strong>full size down</strong> if you have wide feet.</p>',
  RUNS_SMALL_TEXT:
    '<p>This runs small. Consider a <strong>1/2 size up from your usual size</strong> or a <strong>full size up</strong> if you have narrow feet.</p>',
  FITS_HALF_UP_TEXT:
    '<p>Fits <strong>true to size</strong> for most. Consider a <strong>1/2 size up</strong> if you have wide feet or are between sizes.</p>',
  FITS_HALF_DOWN_TEXT: '<p>Fits <strong>true to size</strong> for most. Consider a <strong>1/2 size down</strong> if you have narrow feet.</p>',
  MOST_HALF_UP_TEXT: '<p>Fits a <strong>1/2 size up</strong> for most. Your usual size may feel tight.</p>',
  MOST_FULL_UP_TEXT: '<p>Fits a <strong>full size up</strong> for most. Your usual size may feel too small.</p>',
  MOST_HALF_DOWN_TEXT: '<p>Fits a <strong>1/2 size down</strong> for most. Your usual size may feel loose.</p>',
  MOST_FULL_DOWN_TEXT: '<p>Fits a <strong>full size down</strong> for most. Your usual size may feel too big.</p>',
  RUNS_HALF_DOWN_TEXT: '<p>This runs <strong>big</strong>. We recommend a <strong>1/2 size down</strong>.</p>',
  RUNS_FULL_DOWN_TEXT: '<p>This runs <strong>big</strong>. We recommend a <strong>full size down</strong>.</p>',
  RUNS_HALF_UP_TEXT: '<p>This <strong>runs small</strong>. We recommend a <strong>1/2 size up</strong>.</p>',
  RUNS_FULL_UP_TEXT: '<p>This <strong>runs small</strong>. We recommend a <strong>full size up</strong>.</p>'
};

export function fetchGenericSizingBias(detail: FormattedProductBundle, productId: string): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const opalApi = selectOpalApi(state);

    return genericSizeBias(opalApi, productId, detail.hasHalfSizes, cookies)
      .then(fetchErrorMiddleware as any)
      .then((sizeBias: ProductSizeBias) => {
        const { sizeBiases, roundingMessaging } = sizeBias;

        const genericSizeBiases = {
          productId,
          sizeBiases,
          text: roundingMessaging ? (ROUNDING_MESSAGING[roundingMessaging!] ?? '') : ''
        };

        dispatch(receiveGenericSizingBias(genericSizeBiases));
      })
      .catch((err: Error) => trackError('NON-FATAL', 'Could not load generic size bias.', err));
  };
}

export function receiveBrandPromo(response: string | BrandPromo) {
  return {
    type: RECEIVE_BRAND_PROMO,
    brandPromo: response
  } as const;
}

export function getBrandPromo({ url }: ZapposContentService, brandId: string, fetcher = timedFetch('getBrandPromo')) {
  return fetcher(`${url}/zcs/content?pageLayout=Simple&slotName=primary-1&pageName=pdp-brand-promo-${brandId}`);
}

export function fetchBrandPromo(brandId: string, brandPromoFetcher = getBrandPromo): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const zcsConfig = selectZcsConfig(getState());

    return brandPromoFetcher(zcsConfig, brandId)
      .then(fetchErrorMiddlewareMaybeJson)
      .then((response: BrandPromo) => {
        dispatch(receiveBrandPromo(response));
      })
      .catch((e: Error) => {
        // Api doesn't return anything if no brand promo found. Need to reset brandPromo object
        dispatch(receiveBrandPromo('no-promo-data'));
        trackError('NON-FATAL', 'Could not load brand promo.', e);
      });
  };
}

export function selectedColorChanged(colorId: string) {
  return { type: PRODUCT_COLOR_CHANGED, colorId } as const;
}

export function fireSizingImpression(event: string, sizeObj?: { id: string; value: string }) {
  return {
    type: EVENT_SIZING_IMPRESSION,
    event,
    sizeObj
  } as const;
}

export function requestPdpSymphonyComponents() {
  return {
    type: REQUEST_PDP_SYMPHONY_COMPONENTS
  } as const;
}

export function receivePdpSymphonyComponents(response: PDPSymphonyComponent, productId: string, styleId: string) {
  return {
    type: RECEIVE_PDP_SYMPHONY_COMPONENTS,
    symphony: { ...response, productId, styleId }
  } as const;
}

export function getSymphonyPdpComponents(
  { url, siteId, subsiteId }: ZapposContentService,
  { productId, styleId }: any,
  credentials: Cookies = {},
  fetcher = timedFetch('getSymphonyPdpComponents')
) {
  const qs = stringify({
    product: productId,
    style: styleId,
    siteId,
    subsiteId
  });
  const reqUrl = `${url}/zcs/productPage?${qs}`;
  return fetcher(reqUrl, fetchOpts({}, credentials));
}

export function getSymphonyComponents(productId: string, styleId: string): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    dispatch(requestPdpSymphonyComponents());
    const state = getState();
    const { cookies } = state;
    const zcsConfig = selectZcsConfig(state);

    return getSymphonyPdpComponents(zcsConfig, { productId, styleId }, cookies)
      .then(fetchErrorMiddleware)
      .then((response: PDPSymphonyComponent) => dispatch(receivePdpSymphonyComponents(response, productId, styleId)))
      .catch((err: Error) => {
        trackError('ERROR', `Failed to fetch PDP Symphony info for: productId: ${productId || 'NA'}, styleId: ${styleId || 'NA'}`, err);
      });
  };
}

export function requestPdpStorySymphonyComponents() {
  return {
    type: REQUEST_PDP_STORY_SYMPHONY_COMPONENTS
  } as const;
}

export function receivePdpStorySymphonyComponents(response: PDPSymphonyStory, productId: string) {
  return {
    type: RECEIVE_PDP_STORY_SYMPHONY_COMPONENTS,
    symphonyStory: { ...response, productId }
  } as const;
}
export function getPdpStoriesSymphonyComponents(
  productId: string,
  symphonySlotFetcher = getSymphonySlots
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    dispatch(requestPdpStorySymphonyComponents());
    const state = getState();
    const { cookies } = state;
    const zcsConfig = selectZcsConfig(state);

    return symphonySlotFetcher(zcsConfig, { pageName: productId, pageLayout: 'detail' }, cookies)
      .then((response: any) => sessionLoggerMiddleware(response, cookies))
      .then(fetchErrorMiddleware)
      .then((response: PDPSymphonyStory) => dispatch(receivePdpStorySymphonyComponents(response, productId)))
      .catch((err: Error) => {
        trackError('NON-FATAL', `Failed to fetch PDP Symphony story info for: productId: ${productId}`, err);
      });
  };
}

export function requestLowestPrices() {
  return {
    type: REQUEST_LOWEST_PRICES
  } as const;
}

export function receiveLowestPrices(lowestPrices: LowestStylePrice[]) {
  return {
    type: RECEIVE_LOWEST_PRICES,
    lowestPrices
  } as const;
}

export function fetchLowestPrices(productId: string): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return function (dispatch, getState) {
    dispatch(requestLowestPrices());
    const state = getState();
    const {
      environmentConfig: {
        api: { opal: opalInfo }
      }
    } = state;

    return lowestPrices(opalInfo, productId)
      .then(fetchAllowNotFoundErrorMiddleware)
      .then(res => {
        if (!res) {
          dispatch(receiveLowestPrices([]));
        } else {
          dispatch(receiveLowestPrices(res.results));
        }
      })
      .catch(e => {
        trackError('NON-FATAL', 'Could not load lowest prices for product.', e);
      });
  };
}

export type ProductDetailAction =
  | ReturnType<typeof requestProductDetail>
  | ReturnType<typeof productSwatchChange>
  | ReturnType<typeof receiveProductDetail>
  | ReturnType<typeof toggleOosButton>
  | ReturnType<typeof setProductDocMeta>
  | ReturnType<typeof requestSimilarStyles>
  | ReturnType<typeof receiveSimilarStyles>
  | ReturnType<typeof productAgeGroupChanged>
  | ReturnType<typeof productGenderChanged>
  | ReturnType<typeof productSingleShoeSideChanged>
  | ReturnType<typeof productSizeRangeChanged>
  | ReturnType<typeof productSizeUnitChanged>
  | ReturnType<typeof productSizeChanged>
  | ReturnType<typeof toggleProductDescription>
  | ReturnType<typeof onProductDescriptionCollapsed>
  | ReturnType<typeof validateDimensions>
  | ReturnType<typeof showSelectSizeTooltip>
  | ReturnType<typeof hideSelectSizeTooltip>
  | ReturnType<typeof highlightSelectSizeTooltip>
  | ReturnType<typeof unhighlightSelectSizeTooltip>
  | ReturnType<typeof receiveSizingPredictionSuccess>
  | ReturnType<typeof receiveSizingPredictionFailure>
  | ReturnType<typeof receiveGenericSizingBias>
  | ReturnType<typeof receiveBrandPromo>
  | ReturnType<typeof selectedColorChanged>
  | ReturnType<typeof fireSizingImpression>
  | ReturnType<typeof requestPdpSymphonyComponents>
  | ReturnType<typeof receivePdpSymphonyComponents>
  | ReturnType<typeof requestPdpStorySymphonyComponents>
  | ReturnType<typeof receivePdpStorySymphonyComponents>
  | ReturnType<typeof requestLowestPrices>
  | ReturnType<typeof receiveLowestPrices>;
