export const ZAW_PAGE_NAMES = ['zaw-test', 'zapposatwork-new', 'zappos-at-work', 'zapposatwork'];

// Used to check if a locations pathname includes allowed urls for landing pages.
export const isZAWPage = (pathname: string = '') => !!pathname && ZAW_PAGE_NAMES.some((pageName: string) => pathname.includes(pageName));

export const appendUTMs = (baseUrl: string, newUtms: string): string => {
  const baseUrlWithUtms = baseUrl?.includes('?')
    ? `${baseUrl}${newUtms.length ? '&' : ''}${newUtms}`
    : `${baseUrl}${newUtms.length ? '?' : ''}${newUtms}`;
  return baseUrlWithUtms;
};
