import {
  ADD_IMAGE_DIMESIONS,
  ADD_OUTFIT_PRODUCT_DETAILS,
  SET_CURRENTLY_VIEWING_OUTFIT_RECO,
  SET_OUTFIT_RECOS,
  SET_SOURCE_PRODUCT_URL
} from 'constants/reduxActions';
import type { OutfitRecosAction } from 'actions/outfitRecos';
import type { ImageDimensionMap, OutfitRecoContent, RecoProductsData } from 'types/outfitRecos';

export interface OutfitRecosState {
  outfitRecosContent: OutfitRecoContent[];
  recoProductsData: RecoProductsData;
  currentlyViewingOutfitReco?: OutfitRecoContent;
  imageDimensions: ImageDimensionMap;
  sourceProductUrl: string | null;
}

const initialState = {
  outfitRecosContent: [],
  recoProductsData: {},
  imageDimensions: {},
  sourceProductUrl: null
};

export default function outfitRecos(state: Readonly<OutfitRecosState> = initialState, action: OutfitRecosAction): OutfitRecosState {
  switch (action.type) {
    case SET_OUTFIT_RECOS:
      return { ...state, outfitRecosContent: action.recos };
    case SET_CURRENTLY_VIEWING_OUTFIT_RECO:
      return { ...state, currentlyViewingOutfitReco: { ...action.reco } };
    case ADD_OUTFIT_PRODUCT_DETAILS:
      return { ...state, recoProductsData: { ...state.recoProductsData, ...action.recoProductsData } };
    case ADD_IMAGE_DIMESIONS:
      return { ...state, imageDimensions: { ...state.imageDimensions, ...action.imageDimensions } };
    case SET_SOURCE_PRODUCT_URL:
      return { ...state, sourceProductUrl: action.sourceProductUrl };
    default:
      return state;
  }
}
