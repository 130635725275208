import { useEffect } from 'react';

import { lazyExecute } from 'helpers/index';
import type { RumConfig } from 'helpers/rum';
import { injectRUM } from 'helpers/rum';

export const useInjectRum = (rumConfig: RumConfig, userAgent: string) =>
  useEffect(() => {
    const cancelFn = lazyExecute(() => {
      injectRUM(rumConfig, userAgent);
    }, 1_000);
    return cancelFn;
  }, [rumConfig, userAgent]);
