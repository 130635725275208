import ExecutionEnvironment from 'exenv';

import type { ApiConfig, AppState } from 'types/app';
import { emptyArray, emptyObject } from 'selectors/constants';
import { selectIsFeatureCalypsoAkamai, selectIsFeatureMafiaAkamai, selectIsZCSDirect } from 'selectors/features';
import marketplace from 'cfg/marketplace.json';

export const selectEnvironment = (state: AppState) => state.environmentConfig || emptyArray;

export const selectApiConfig = (state: AppState) => selectEnvironment(state).api || emptyObject;

export const selectCloudCatalogApi = (state: AppState) => selectEnvironment(state).api.cloudcatalog || emptyObject;

export const selectIsMockApi = (state: AppState) => selectEnvironment(state).isMockApi;

export const selectVisualSearchMafiaURL = (state: AppState) => selectEnvironment(state).visualSearch?.mafiaBaseUrl;

export const selectOpalApi = (state: AppState) => selectEnvironment(state).api.opal || {};

export const selectCalypsoConfig = (state: AppState): ApiConfig => {
  const isFeatureCalypsoAkamai = selectIsFeatureCalypsoAkamai(state);
  const calypsoConfig = selectApiConfig(state).calypso || emptyObject;

  // Fallback if state values are not available
  if (!Object.keys(calypsoConfig).length) {
    return { ...calypsoConfig, url: marketplace.api.calypso.url };
  }
  const akamaiOrDirectUrl =
    ExecutionEnvironment.canUseDOM && window?.location?.hostname && !window?.location?.port
      ? `https://${window.location.hostname}/mobileapi/olympus`
      : calypsoConfig.url;
  return { ...calypsoConfig, url: isFeatureCalypsoAkamai ? akamaiOrDirectUrl : calypsoConfig.url };
};

export const selectMafiaConfig = (state: AppState) => {
  const isFeatureMafiaAkamai = selectIsFeatureMafiaAkamai(state);
  const mafiaConfig = selectApiConfig(state).mafia || emptyObject;

  // Fallback if state values are not available
  if (!Object.keys(mafiaConfig).length) {
    return { ...mafiaConfig, url: marketplace.api.mafia.url };
  }

  return { ...mafiaConfig, url: isFeatureMafiaAkamai ? mafiaConfig.akamaiUrl : mafiaConfig.url };
};

export const selectZcsConfig = (state: AppState) => {
  const isZcsDirect = selectIsZCSDirect(state);
  const zcsConfig = selectApiConfig(state).zcs || emptyObject;

  // Fallback if state values are not available
  if (!Object.keys(zcsConfig).length) {
    return { ...zcsConfig, url: marketplace.api.zcs.url };
  }
  const akamaiOrDirectUrl =
    ExecutionEnvironment.canUseDOM && window?.location?.hostname && !window?.location?.port
      ? `https://${window.location.hostname}/mobileapi/zcsd`
      : zcsConfig.directUrl;
  return { ...zcsConfig, url: isZcsDirect ? akamaiOrDirectUrl : zcsConfig.url };
};

export const selectVisualSearchMafiaConfig = (state: AppState) => {
  const mafiaConfig = selectApiConfig(state).mafia || emptyObject;
  const mafiaBaseUrl = selectVisualSearchMafiaURL(state) ?? marketplace.api.mafia.url;

  return { ...mafiaConfig, url: mafiaBaseUrl };
};

export const selectAccountConfig = (state: AppState) => {
  const isFeatureMafiaAkamai = selectIsFeatureMafiaAkamai(state);
  const accountConfig = selectApiConfig(state).account || emptyObject;

  // Fallback if state values are not available
  if (!Object.keys(accountConfig).length) {
    return { ...accountConfig, url: isFeatureMafiaAkamai ? marketplace.api.mafia.akamaiUrl : marketplace.api.mafia.url };
  }

  return { ...accountConfig, url: isFeatureMafiaAkamai ? accountConfig.akamaiUrl : accountConfig.url };
};
