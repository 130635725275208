import {
  RECEIVE_VISUAL_SEARCH_BOUNDING_BOXES,
  RECEIVE_VISUAL_SEARCH_CANCELLATION,
  RECEIVE_VISUAL_SEARCH_FAILURE,
  RECEIVE_VISUAL_SEARCH_INPUT_IMAGE,
  RECEIVE_VISUAL_SEARCH_STYLE_IDS,
  RECEIVE_VISUAL_SEARCH_SUCCESS,
  REQUEST_VISUAL_SEARCH,
  RESET_VISUAL_SEARCH_BOUNDING_BOXES,
  RESET_VISUAL_SEARCH_INPUT_IMAGE,
  RESET_VISUAL_SEARCH_RESULTS
} from 'constants/reduxActions';
import type { BoundingBoxApiResponse, BoundingBoxData } from 'components/VisualSearch/VisualSearchResults/visualSearchResults.types';
import { VisualSearchAPIResponse } from 'components/VisualSearch/VisualSearchResults/visualSearchResults.types';
import type { ProductWithRelationsFromCalypso } from 'types/calypso';
import type { VisualSearchAction } from 'actions/visualSearch';
import { convertApiResponseToBoundingBoxDataMap } from 'components/VisualSearch/VisualSearchResults/Products/VisualSearchProductResults/VisualSearchResults.utility';

export interface VisualSearchResultsState {
  products: ProductWithRelationsFromCalypso[];
  visualSearchDataStatus: VisualSearchAPIResponse;
  inputImage: File | null;
  styleIds: string[];
  boundingBoxes: Map<string, BoundingBoxData>;
  boundingBoxApiResponse: BoundingBoxApiResponse;
  queryId: string;
  imageURI: string;
}

const initialState: VisualSearchResultsState = {
  products: [],
  visualSearchDataStatus: VisualSearchAPIResponse.UNKNOWN,
  inputImage: null,
  styleIds: [],
  boundingBoxes: new Map<string, BoundingBoxData>(),
  boundingBoxApiResponse: {
    result: [],
    debugInfo: {
      totalTime: 0,
      queryId: ''
    }
  },
  queryId: '',
  imageURI: ''
};

export default function visualSearch(state: VisualSearchResultsState = initialState, action: VisualSearchAction): VisualSearchResultsState {
  switch (action.type) {
    case REQUEST_VISUAL_SEARCH:
      return { ...state, visualSearchDataStatus: VisualSearchAPIResponse.FETCHING };
    case RECEIVE_VISUAL_SEARCH_SUCCESS:
      const { products } = action;
      return { ...state, products, visualSearchDataStatus: VisualSearchAPIResponse.FETCHED };
    case RECEIVE_VISUAL_SEARCH_FAILURE:
      return { ...state, visualSearchDataStatus: VisualSearchAPIResponse.FAILED };
    case RESET_VISUAL_SEARCH_RESULTS:
      return { ...state, products: [], visualSearchDataStatus: VisualSearchAPIResponse.UNKNOWN, styleIds: [] };
    case RECEIVE_VISUAL_SEARCH_INPUT_IMAGE:
      const { inputImage } = action;
      return { ...state, inputImage };
    case RESET_VISUAL_SEARCH_INPUT_IMAGE:
      return { ...state, inputImage: null };
    case RECEIVE_VISUAL_SEARCH_STYLE_IDS:
      return { ...state, styleIds: action.styleIds };
    case RECEIVE_VISUAL_SEARCH_BOUNDING_BOXES:
      const { queryId, imageURI, apiResponse } = action;
      return {
        ...state,
        boundingBoxApiResponse: action.apiResponse,
        boundingBoxes: convertApiResponseToBoundingBoxDataMap(apiResponse),
        queryId,
        imageURI
      };
    case RESET_VISUAL_SEARCH_BOUNDING_BOXES:
      return {
        ...state,
        boundingBoxApiResponse: { result: [], debugInfo: { totalTime: 0, queryId: '0' } },
        boundingBoxes: new Map<string, BoundingBoxData>(),
        queryId: '',
        imageURI: ''
      };
    case RECEIVE_VISUAL_SEARCH_CANCELLATION:
      return { ...state, visualSearchDataStatus: VisualSearchAPIResponse.FETCHING, products: [], styleIds: [] };
    default:
      return state;
  }
}
