import { stringify } from 'query-string';

import { selectZcsConfig } from 'selectors/environment';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { trackError } from 'helpers/ErrorUtils';
import type { ZapposContentService, ZCSGetPageRequest, ZCSGetSlotsResponse } from 'types/zcs';
import type { Cookies } from 'types/cookies';
import timedFetch from 'middleware/timedFetch';
import { addClientHeaders, fetchOpts } from 'apis/mafia/common';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';
import type { AppState } from 'types/app';
import { RECEIVE_ACCOUNT_CONTENT } from 'constants/reduxActions';

export function receiveAccountContent(content: any) {
  return {
    type: RECEIVE_ACCOUNT_CONTENT,
    content
  };
}

export function getSymphonySlots(
  { url, siteId, subsiteId }: ZapposContentService,
  { ...params } = {},
  credentials: Cookies = {},
  fetcher = timedFetch('getSymphonyPdpComponents')
) {
  const qs = stringify({
    siteId,
    subsiteId,
    ...params
  });
  const reqUrl = `${url}/zcs/getSlots?${qs}`;
  return fetcher(reqUrl, fetchOpts({}, credentials));
}

export function fetchMyAccountContent(getOrderStatuses = getSymphonySlots) {
  return (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();
    const { cookies } = state;
    const zcsConfig = selectZcsConfig(state);

    return getOrderStatuses(zcsConfig, { pageName: 'account', pageLayout: 'your-account' }, cookies)
      .then(fetchErrorMiddleware)
      .then((response: ZCSGetSlotsResponse) => {
        dispatch(receiveAccountContent(response?.slotData));
      })
      .catch((err: any) => {
        trackError('NON-FATAL', 'Failed to fetch Symphony order statuses', err);
      });
  };
}

export function getLandingPageInfo(
  { url, siteId, subsiteId }: ZapposContentService,
  { pageName }: ZCSGetPageRequest,
  credentials: Cookies = {},
  request = {},
  location: any = {},
  fetcher = timedFetch('getLandingPageInfo')
) {
  const qs = stringify(
    {
      ...location.query, // pass query params to mafia
      siteId,
      subsiteId,
      pageName
    }
    // TODO: Doesn't exist in this version of query-string, see comment in package.json
    // { sort: false }
  );
  const reqUrl = `${url}/zcs/getPage?${qs}`;
  const headers = addClientHeaders(request);
  // Attach 'geo' cookie to server-side marty calls as 'zfc-geo' header
  if (credentials['geo']) {
    headers['zfc-geo'] = credentials['geo'];
  }
  return fetcher(reqUrl, fetchOpts({ headers }, credentials));
}

export function getTaxonomyBrandPageInfo(
  { url, siteId, subsiteId }: ZapposContentService,
  brandId: string,
  credentials: Cookies = {},
  request = {},
  fetcher = timedFetch('getTaxonomyBrandPageInfo')
) {
  const qs = stringify({
    pageName: 'generic-brands-template',
    brandId,
    siteId,
    subsiteId
  });
  const reqUrl = `${url}/zcs/getPage?${qs}`;
  const headers = addClientHeaders(request);
  // Attach 'geo' cookie to server-side marty calls as 'zfc-geo' header
  if (credentials['geo']) {
    headers['zfc-geo'] = credentials['geo'];
  }
  return fetcher(reqUrl, fetchOpts({ headers }, credentials));
}
