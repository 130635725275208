import type { MutableRefObject, RefCallback, RefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { deepEqual } from 'fast-equals';

type IncludesType = {
  scrollWidth?: boolean;
  scrollHeight?: boolean;
  scrollLeft?: boolean;
  scrollTop?: boolean;
  offsetWidth?: boolean;
  offsetHeight?: boolean;
  clientWidth?: boolean;
  clientHeight?: boolean;
};

type TrueKeys<T extends Partial<IncludesType>> = {
  [K in keyof T]: T[K] extends true ? K : never; // Only keep keys with `true`
}[keyof T];

// Use TrueKeys to create the ReturnType with only true properties, (as they appear in a block element)
type DimensionsType<T extends Partial<IncludesType>> =
  | {
      [K in TrueKeys<T>]: K extends keyof HTMLElement ? HTMLElement[K] : never; // The filtered keys will
    }
  | null;

type OptionsType = {
  includes: Partial<IncludesType>;
  setRef?: RefCallback<HTMLElement> | RefObject<HTMLElement>;
};

const defaultOptions = {
  includes: {
    clientWidth: true,
    clientHeight: true
  }
};
/**
 *
 * @return          [dimensions, setRef]  -
 *                    dimensions: dimensions of the window
 *                    setRef: a RefCallback to set the reference to use.
 * @param options    includes: monitor those changes.
 *                   setRef: optional RefCallback chaining
 */
export function useRefScrollDimensions<T extends OptionsType>(
  options: T = defaultOptions as T
): [DimensionsType<T['includes']>, RefCallback<HTMLElement>] {
  const { includes } = options;

  const [element, setElement] = useState<HTMLElement>();

  // triggering state
  const [dimensions, setDimensions] = useState<DimensionsType<T['includes']>>(null);

  // non-triggering state
  const ref = useRef({
    dimensions: null as DimensionsType<T['includes']>, // debounce returns.
    resizeObserver: null as ResizeObserver | null,
    mutationObserver: null as MutationObserver | null,
    options
  });

  useEffect(() => {
    if (!element) return;
    const hasScrollSize = includes.scrollWidth || includes.scrollHeight || false;

    const gatherConfiguredDimensions = () => {
      if (!element) return;

      const newState: any = {};

      // find items we want to monitor and read them into the newState
      for (const key of Object.keys(includes) as (keyof typeof includes)[]) {
        if (options.includes[key]) {
          newState[key] = element[key];
        }
      }

      // deduplicate newState
      if (!deepEqual(ref.current.dimensions, newState)) {
        ref.current.dimensions = newState;
        setDimensions(newState);
      }
    };

    const handleChildChange = (childrenCanChange = false) => {
      if (!element) {
        return;
      }
      const initChildren = !ref.current.resizeObserver || childrenCanChange;
      if (!ref.current.resizeObserver) {
        ref.current.resizeObserver = new ResizeObserver(() => gatherConfiguredDimensions());
        ref.current.resizeObserver.observe(element);
      } else if (initChildren) {
        // not the first time and child elements have changed
        ref.current.resizeObserver.disconnect();
      }

      if (initChildren) {
        ref.current.resizeObserver.observe(element);
        for (const child of element.children) {
          ref.current.resizeObserver.observe(child);
        }
      }
    };

    // observe for w/h changes.
    handleChildChange();

    if (hasScrollSize) {
      // observe change of children elements to ensure scrollWidth/scrollHeight stay current
      // only if scrollWidth/scrollHeight are configured.
      const mutationObserver = (ref.current.mutationObserver = new MutationObserver(mutationsList => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            handleChildChange(true);
            return;
          }
        }
      }));
      mutationObserver.observe(element, { childList: true });
    }

    // observe for scroll changes, if those attributes are configured.
    let scrollHandler: { (): void; (this: HTMLElement, ev: Event): any; (this: HTMLElement, ev: Event): any } | null = null;
    if (includes.scrollLeft || includes.scrollTop) {
      scrollHandler = () => gatherConfiguredDimensions();
      element.addEventListener('scroll', scrollHandler, { passive: true });
    }

    // launch immediately for first update
    typeof window !== 'undefined' && typeof window.requestAnimationFrame !== 'undefined'
      ? requestAnimationFrame(() => gatherConfiguredDimensions())
      : gatherConfiguredDimensions();

    return () => {
      if (element) {
        ref.current.resizeObserver?.disconnect();
        ref.current.mutationObserver?.disconnect();
        scrollHandler && element?.removeEventListener('scroll', scrollHandler);
      }
    };
  }, [element]);

  const elementRefCallback: RefCallback<HTMLElement> = useCallback((e: HTMLElement) => {
    const setRef = (options as OptionsType)?.setRef;
    if (typeof setRef === 'function') {
      // If the ref is a function, call it with the value
      setRef(e);
    } else if (setRef && typeof ref === 'object') {
      // If the ref is an object, set its current property
      (setRef as MutableRefObject<HTMLElement | null>).current = e;
    }
    setElement(e);
  }, []);

  return [dimensions, elementRefCallback];
}
