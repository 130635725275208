import type { MouseEvent } from 'react';
import { forwardRef } from 'react';

import { cn } from 'helpers/classnames';

export type HeaderIconLinkProps = {
  children: React.ReactNode;
  className?: string;
  href: string;
  label: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  text?: string;
} & { 'aria-expanded'?: boolean };

const linkClasses = 'relative flex items-center gap-x-1 p-1 rounded-full transition-colors duration-300 hover:bg-default-subtle';
const activeLinkClasses = 'bg-button-primary text-button-reversed';
const textClasses = 'pointer-events-none select-none pr-2 text-sm font-bold';

const HeaderIconLink = forwardRef<HTMLAnchorElement, HeaderIconLinkProps>((props, ref) => {
  const { children, className, 'aria-expanded': isExpanded, label, text, ...forwardProps } = props;

  return (
    <a className={cn(linkClasses, { [activeLinkClasses]: isExpanded }, className)} ref={ref} aria-disabled aria-label={label} {...forwardProps}>
      {children}
      {text && <span className={textClasses}>{text}</span>}
    </a>
  );
});

export default HeaderIconLink;
