import { injectScriptToHead } from 'helpers/HtmlHelpers';

if (typeof window !== 'undefined') {
  window.scriptLoadMap = window.scriptLoadMap || {};
}

const markScriptFullyLoaded = (src: string) => (window.scriptLoadMap[src] = true);

const isScriptAdded = (src: string) => Boolean(document.querySelector('script[src="' + src + '"]'));

const isScriptFullyLoaded = (src: string) => window.scriptLoadMap[src] === true;

export const loadScript = ({
  src,
  onLoadCallback,
  onLoadErrorCallback,
  id
}: {
  src: string;
  onLoadCallback?: (() => void) | null;
  onLoadErrorCallback?: (() => void) | null;
  id?: string;
}): void => {
  if (!src) {
    return;
  }

  if (isScriptAdded(src)) {
    if (isScriptFullyLoaded(src)) {
      if (typeof onLoadCallback === 'function') {
        onLoadCallback();
      }
    }

    return;
  }

  const onload = () => {
    markScriptFullyLoaded(src);

    if (typeof onLoadCallback === 'function') {
      onLoadCallback();
    }
  };

  const onerror = () => {
    const js = document.querySelector('script[src="' + src + '"]');
    js?.remove();

    if (typeof onLoadErrorCallback === 'function') {
      onLoadErrorCallback();
    }
  };

  injectScriptToHead({
    src,
    type: 'text/javascript',
    id,
    onload,
    onerror
  });
};
