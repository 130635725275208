import type React from 'react';
import ExecutionEnvironment from 'exenv';

import { cleanUp } from './index';

import { HTML_TAG_RE } from 'common/regex';

// Get psuedo element content with JS
export function getContent(element: Element, pseudo: string | null | undefined): string {
  return window
    .getComputedStyle(element, pseudo)
    .getPropertyValue('content')
    .replace(/['"]|(none)/gi, '');
}

/**
 * Leverages `getContent` to return a string of the current screen size.
 * @returns {String} 'mobile'|'tablet'|'desktop'
 */
export function getScreenSize(): string {
  return getContent(document.documentElement, ':before');
}

/**
 * Leverages `getContent` to return a string of the current screen size.
 * @returns {String} 'hf-mobile'
 */
export function getHFScreenSize(): string {
  return getContent(document.documentElement, ':after');
}

// Get css breakpoint with JS
export function isMobileContentBreakpoint(el: Element): boolean {
  return ExecutionEnvironment.canUseDOM && getContent(el, ':after') === 'mobile';
}

// Check for details/summary support https://caniuse.com/details
// Code borrowed from here https://github.com/mathiasbynens/jquery-details/blob/master/jquery.details.js#L9
export function isDetailsSupported(doc = document): boolean {
  const el = doc.createElement('details');
  let fake;

  if (!('open' in el)) {
    return false;
  }

  const root =
    doc.body ||
    (function () {
      const de = doc.documentElement;
      fake = true;
      return de.insertBefore(doc.createElement('body'), de.firstElementChild || de.firstChild);
    })();

  el.innerHTML = '<summary>a</summary>b';
  el.style.display = 'block';
  root.appendChild(el);
  const heightInitial = el.offsetHeight;
  el.open = true;
  const diff = heightInitial !== el.offsetHeight;
  root.removeChild(el);

  if (fake) {
    root.parentNode?.removeChild(root);
  }

  return diff;
}

function tryConvertStringToBoolean(s: string): string | boolean {
  if (s === 'true') {
    return true;
  }
  if (s === 'false') {
    return false;
  }
  return s;
}

/*
  Take form element. Returns inner inputs in an object like: {name: value}.
  WARNING: this has some limitations and assumptions.
  - Checkboxes are bools.
  - Radios will be converted to bools if value is true/false.
  - Multi-selects are probably not supported in the way you would need.
  - File inputs won't work. You'll likely need FormData for that
  Whatever you're doing, be sure to always test before using. Custom solutions may be necessary
  depending on the needs of the endpoint.
  Related GH convo: https://github01.zappos.net/mweb/marty/pull/13336#discussion_r58339
*/
export function formInputsToObject(formEl: HTMLFormElement): Record<string, string | boolean> {
  const formObj: Record<string, string | boolean> = {};
  Array.from(formEl.elements as Iterable<HTMLInputElement>).forEach(el => {
    const { tagName, type, name, value, checked, disabled } = el;
    if (['INPUT', 'TEXTAREA', 'SELECT'].includes(tagName) && type !== 'submit' && !disabled) {
      if (type === 'checkbox') {
        formObj[name] = checked;
      } else if (type === 'radio') {
        if (checked) {
          formObj[name] = tryConvertStringToBoolean(value);
        }
      } else {
        formObj[name] = value;
      }
    }
  });
  return formObj;
}

const LI_RE = /^\s*-(.*)|\n\s*-(.*)/g;
const NEWLINE_RE = /\n/g;
const BOLD_RE = /\*\*(.+?)\*\*/g;
const ITALICS_RE = /\*(.+?)\*/g;
const STRIKETHROUGH_RE = /~~(.+?)~~/g;
const FOOTNOTE_RE = /__(.+?)__/g;
const ASTERISK_RE = /\\\*/g;
export function parseMarkdown(text: string): string {
  return text
    .replace(FOOTNOTE_RE, '<cite>$1</cite>')
    .replace(LI_RE, '<p style="margin: 0.5em 0 0.5em 1em">$1$2</p>') // Why no <li>? We'd need to enclose them with '<ul>', adding complexity to this code.
    .replace(NEWLINE_RE, '<br />')
    .replace(BOLD_RE, '<strong>$1</strong>')
    .replace(ITALICS_RE, '<em>$1</em>')
    .replace(STRIKETHROUGH_RE, '<del>$1</del>')
    .replace(ASTERISK_RE, '*');
}

export function markdownToHtml(htmlText: string): string {
  const html = parseMarkdown(htmlText);
  const htmlSanitized = cleanUp(html);
  // wrap in <p> to preserve whitespace when rendered to html
  return `<p>${htmlSanitized}</p>`;
}

export function injectScriptToHead(props: Record<string, any>): void {
  if (!ExecutionEnvironment.canUseDOM) {
    return;
  }

  // TODO try to convert this to idiomatic typescript someday
  const script = document.createElement('script') as any; // <-- `any` is bad

  Object.keys(props).map((key: string) => {
    script[key] = props[key];
  });

  document.head.appendChild(script as HTMLScriptElement);
}

export function injectLinkToHead(href?: string, rel?: string, crossOrigin?: boolean, id?: string): void {
  if (!ExecutionEnvironment.canUseDOM) {
    return;
  }

  const link = document.createElement('link') as HTMLLinkElement;

  if (crossOrigin) {
    link.crossOrigin = '';
  }
  if (href) {
    link.href = href;
  }
  if (rel) {
    link.rel = rel;
  }
  if (id) {
    link.id = id;
  }

  document.head.appendChild(link as HTMLLinkElement);
}

export function extractTextFromHtml(htmlString: string): string {
  return htmlString.replace(new RegExp(HTML_TAG_RE, 'g'), '');
}

export function convertRichTextToPlainText(richText: string): string {
  const textWithSpacesForNewLines = richText.replace(new RegExp('</p>', 'g'), ' ');
  const textWithBullets = textWithSpacesForNewLines.replace(new RegExp('<li>', 'g'), '\u{2022}');
  return extractTextFromHtml(textWithBullets);
}

/**
 * This function does not handle block element logic. For example:
 *
 * ```
 * getTextContent(
 *   <div>
 *    <p>foo</p>
 *    <p>bar</p>
 *   </div>
 * )
 * ```
 *
 * returns 'foobar' when it should return 'foo bar'
 *
 */
export function getTextContent(el: React.ReactElement | string | undefined): string {
  if (!el) {
    return '';
  }

  if (typeof el === 'string') {
    return el;
  }

  const children = el.props?.children;
  if (children instanceof Array) {
    return children.map(getTextContent).join('');
  }
  return getTextContent(children);
}
