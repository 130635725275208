import {
  ADD_PRODUCT_COMPARISON_PRODUCT_RELATIONS,
  RECEIVE_PRODUCT_COMPARISON_TABLE_FAILURE,
  RECEIVE_PRODUCT_COMPARISON_TABLE_SUCCESS,
  REQUEST_PRODUCT_COMPARISON_TABLE,
  RESET_PRODUCT_COMPARISON_TABLE
} from 'constants/reduxActions';
import type { ProductComparisonTableType, ProductFeature, ProductHeader } from 'types/productComparison';
import { ProductComparisonAPIResponse } from 'types/productComparison';
import { flattenProductRelations, populateProductWithRelatedStyleIds } from 'helpers/RecoUtils';
import type { ProductWithRelations } from 'types/calypso';
import type { ProductComparisonAction } from 'actions/productdetail/productComparison';

export interface ProductComparisonState {
  comparisonTableData: ProductComparisonTableType;
  comparisonTableDataStatus: ProductComparisonAPIResponse;
}

const emptyComparisonData = {
  asin: '',
  comparisonModule: {
    header: [],
    row: [],
    rowAdditionalInfo: {}
  }
};

const initialState: ProductComparisonState = {
  comparisonTableData: emptyComparisonData,
  comparisonTableDataStatus: ProductComparisonAPIResponse.UNKNOWN
};

export default function productComparison(state: ProductComparisonState = initialState, action: ProductComparisonAction): ProductComparisonState {
  const MINIMUM_PRODUCTS_REQUIRED = 2;
  switch (action.type) {
    case REQUEST_PRODUCT_COMPARISON_TABLE:
      return { ...state, comparisonTableDataStatus: ProductComparisonAPIResponse.FETCHING };
    case RECEIVE_PRODUCT_COMPARISON_TABLE_SUCCESS:
      const { comparisonTableData } = action;
      return { ...state, comparisonTableData, comparisonTableDataStatus: ProductComparisonAPIResponse.FETCHED };
    case RECEIVE_PRODUCT_COMPARISON_TABLE_FAILURE:
      return { ...state, comparisonTableDataStatus: ProductComparisonAPIResponse.FAILED };
    case RESET_PRODUCT_COMPARISON_TABLE:
      return {
        ...state,
        comparisonTableData: emptyComparisonData,
        comparisonTableDataStatus: ProductComparisonAPIResponse.UNKNOWN
      };
    case ADD_PRODUCT_COMPARISON_PRODUCT_RELATIONS:
      const { productComparisonProductRelations } = action;

      const styleIdProductRelationsMap = flattenProductRelations(productComparisonProductRelations);
      const {
        comparisonTableData: comparisonTableDataState,
        comparisonTableData: {
          comparisonModule: { header, row },
          comparisonModule
        }
      } = state;
      const oosProductAsins: string[] = [];
      const headersWithProductRelations: ProductHeader[] = [];
      header.forEach(h => {
        const { styleId, productId, linkedAsin } = h;
        const productWithRelations = getProductWithRelatedStylesForComparisonTable(styleId, productId, styleIdProductRelationsMap);
        if (!productWithRelations) {
          oosProductAsins.push(linkedAsin);
        } else {
          headersWithProductRelations.push({ ...h, productWithRelations });
        }
      });
      // reset table to initial state if comparison table has only source product left
      if (header.length - oosProductAsins.length < MINIMUM_PRODUCTS_REQUIRED) {
        return { ...state, comparisonTableData: emptyComparisonData, comparisonTableDataStatus: ProductComparisonAPIResponse.UNKNOWN };
      }

      // Remove out of stock product columns from table values
      const updatedRows = handleOOSProducts(row, oosProductAsins);

      const updatedComparisonTableData = {
        ...comparisonTableDataState,
        comparisonModule: { ...comparisonModule, header: headersWithProductRelations, row: updatedRows }
      };
      return { ...state, comparisonTableData: updatedComparisonTableData };
    default:
      return state;
  }
}
const getProductWithRelatedStylesForComparisonTable = (
  styleId: string,
  productId: string,
  productRelations: { [s: string]: ProductWithRelations }
) => {
  if (!productRelations) {
    return undefined;
  }

  const productWithRelations = productRelations[styleId] || Object.values(productRelations).find(p => p.productId === productId);

  if (!productWithRelations?.relatedStyleIds?.length) {
    return productWithRelations;
  }

  const { relatedStyleIds } = productWithRelations;

  productWithRelations.relatedStyles = populateProductWithRelatedStyleIds(relatedStyleIds, productRelations);
  return productWithRelations;
};

function handleOOSProducts(row: ProductFeature[], oosProductAsins: string[]) {
  if (!oosProductAsins.length) {
    return row;
  } else {
    return row.map(r => {
      const { value } = r;
      const newValue = Object.keys(value)
        .filter(asin => !oosProductAsins.includes(asin))
        .reduce((obj: { [index: string]: any }, asin) => {
          obj[asin] = value[asin];
          return obj;
        }, {});
      return { ...r, value: newValue };
    });
  }
}
