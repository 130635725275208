import type { AppState } from 'types/app';
import type { CSDropdown } from 'types/navigation';

const selectHeaderFooter = (state: AppState) => state.headerFooter;

export const selectIsMobile = (state: AppState) => selectHeaderFooter(state).isMobile;

export const selectIsRemoteHF = (state: AppState) => !!selectHeaderFooter(state)?.isRemote;

export const selectIsMobileHeaderExpanded = (state: AppState) => selectHeaderFooter(state).isMobileHeaderExpanded;

export const selectOpenedNav = (state: AppState) => selectHeaderFooter(state).openedNav;

export const selectOpenedSubNav = (state: AppState) => selectHeaderFooter(state).openedSubNav;

const selectHeaderContent = (state: AppState) => selectHeaderFooter(state).content.Header;

export const selectHeaderSlotData = (slot: string, state: AppState) => selectHeaderContent(state).slotData[slot];

export const selectCustomerServiceMenu = (state: AppState): CSDropdown => selectHeaderSlotData('customer-service-menu', state);
