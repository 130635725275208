import { types as CardType } from 'credit-card-type';
import queryString from 'query-string';

import { PHONE_CHARS } from 'common/regex';
import {
  CANCELLED,
  COMPLETED,
  CS_CANCELLED,
  CS_COMPLETED,
  CS_CUSTOMER_ACTION_REQUIRED,
  CS_DECLINED,
  CS_DECLINED_CANCELLED,
  CS_DELIVERY_MISTAKE,
  CS_PROCESSING,
  CS_RETURN_IN_PROGESS,
  CS_RETURNED,
  CS_RETURNING,
  CS_SHIPPED,
  CS_SUBMITTED,
  CUSTOMER_ACTION_REQUIRED,
  DECLINED,
  DECLINED_ALT,
  DECLINED_CANCELLED,
  DELIVERY_MISTAKE,
  PROCESSING,
  RETURNED,
  RETURNING,
  RETURNING_ALT,
  SHIPPED,
  SUBMITTED
} from 'constants/orderStatuses';
import {
  AVAILABLE_FOR_PICKUP,
  CS_AVAILABLE_FOR_PICKUP,
  CS_DELAYED,
  CS_DELIVERED,
  CS_DELIVERY_ATTEMPTED,
  CS_IN_TRANSIT,
  CS_OUT_FOR_DELIVERY,
  CS_RETURNED_TO_SELLER,
  CS_RETURNING_TO_SELLER,
  CS_SHIPPING_SOON,
  CS_UNDELIVERABLE,
  DELAYED,
  DELIVERED,
  DELIVERY_ATTEMPTED,
  IN_TRANSIT,
  OUT_FOR_DELIVERY,
  RETURNED as SHIPPING_RETURNED,
  RETURNING as SHIPPING_RETURNING,
  SHIPPING_SOON,
  UNDELIVERABLE
} from 'constants/shippingStatuses';
import {
  CS_DROPOFF_PENDING,
  CS_PICKUP_SCHEDULED,
  CS_PROCESSING_RETURN,
  CS_RETURNED_TO_ZAPPOS,
  CS_RETURNING_TO_ZAPPOS,
  DROPOFF_PENDING,
  PICKUP_SCHEDULED,
  PROCESSING_RETURN,
  RETURNED_TO_ZAPPOS,
  RETURNING_TO_ZAPPOS
} from 'constants/returnTrackingStatuses';
import type { Address } from 'types/checkout';
import type { LineItem, LineItemProduct, OrderStatus } from 'types/mafia';
import { EMAIL_TOKEN_SEARCH_PARAM } from 'constants/cookies';

export const RETURN_STATUSES = [
  RETURNING,
  RETURNED,
  RETURNING_ALT,
  SHIPPING_RETURNING,
  SHIPPING_RETURNED,
  DROPOFF_PENDING,
  RETURNED_TO_ZAPPOS,
  RETURNING_TO_ZAPPOS,
  PICKUP_SCHEDULED,
  PROCESSING_RETURN
];
export const CANCEL_STATUSES = [CANCELLED, DECLINED_CANCELLED, DECLINED, DECLINED_ALT, DELIVERY_MISTAKE, UNDELIVERABLE];
export const SHIPPED_STATUSES = [
  SHIPPED,
  IN_TRANSIT,
  DELAYED,
  OUT_FOR_DELIVERY,
  DELIVERY_ATTEMPTED,
  AVAILABLE_FOR_PICKUP,
  SHIPPING_SOON,
  CUSTOMER_ACTION_REQUIRED
];
export const DELIVERED_STATUSES = [DELIVERED, COMPLETED];
export const LEGACY_ORDER_RETURN_STATUSES = [RETURNING, RETURNED];

const legacyStatusMap = {
  // Order Statuses
  [CS_SUBMITTED]: SUBMITTED,
  [CS_PROCESSING]: PROCESSING,
  [CS_DECLINED]: DECLINED,
  [CS_DECLINED_CANCELLED]: DECLINED_CANCELLED,
  [CS_CANCELLED]: CANCELLED,
  [CS_COMPLETED]: COMPLETED,
  [CS_SHIPPED]: SHIPPED,
  [CS_CUSTOMER_ACTION_REQUIRED]: CUSTOMER_ACTION_REQUIRED,
  [CS_DELIVERY_MISTAKE]: DELIVERY_MISTAKE,
  [CS_RETURN_IN_PROGESS]: RETURNING,
  [CS_RETURNED]: RETURNED,
  [CS_RETURNING]: RETURNING,
  // Shipping Statuses
  [CS_IN_TRANSIT]: IN_TRANSIT,
  [CS_DELAYED]: DELAYED,
  [CS_OUT_FOR_DELIVERY]: OUT_FOR_DELIVERY,
  [CS_DELIVERY_ATTEMPTED]: DELIVERY_ATTEMPTED,
  [CS_AVAILABLE_FOR_PICKUP]: AVAILABLE_FOR_PICKUP,
  [CS_DELIVERED]: DELIVERED,
  [CS_UNDELIVERABLE]: UNDELIVERABLE,
  [CS_RETURNED_TO_SELLER]: SHIPPING_RETURNED,
  [CS_RETURNING_TO_SELLER]: SHIPPING_RETURNING,
  [CS_SHIPPING_SOON]: SHIPPING_SOON,
  // Return Tracking Statuses
  [CS_DROPOFF_PENDING]: DROPOFF_PENDING,
  [CS_RETURNED_TO_ZAPPOS]: RETURNED_TO_ZAPPOS,
  [CS_RETURNING_TO_ZAPPOS]: RETURNING_TO_ZAPPOS,
  [CS_PICKUP_SCHEDULED]: PICKUP_SCHEDULED,
  [CS_PROCESSING_RETURN]: PROCESSING_RETURN
};

const compositeStatusMap = {
  // Order Statuses
  [SUBMITTED]: CS_SUBMITTED,
  [PROCESSING]: CS_PROCESSING,
  [DECLINED_CANCELLED]: CS_DECLINED_CANCELLED,
  [CANCELLED]: CS_CANCELLED,
  [COMPLETED]: CS_COMPLETED,
  [SHIPPED]: CS_SHIPPED,
  [CUSTOMER_ACTION_REQUIRED]: CS_CUSTOMER_ACTION_REQUIRED,
  [DELIVERY_MISTAKE]: CS_DELIVERY_MISTAKE,
  [RETURNED]: CS_RETURNED,
  [RETURNING]: CS_RETURNING,
  // Shipping Statuses
  [IN_TRANSIT]: CS_IN_TRANSIT,
  [DELAYED]: CS_DELAYED,
  [OUT_FOR_DELIVERY]: CS_OUT_FOR_DELIVERY,
  [DELIVERY_ATTEMPTED]: CS_DELIVERY_ATTEMPTED,
  [AVAILABLE_FOR_PICKUP]: CS_AVAILABLE_FOR_PICKUP,
  [DELIVERED]: CS_DELIVERED,
  [UNDELIVERABLE]: CS_UNDELIVERABLE,
  [SHIPPING_RETURNED]: CS_RETURNED_TO_SELLER,
  [SHIPPING_RETURNING]: CS_RETURNING_TO_SELLER,
  [SHIPPING_SOON]: CS_SHIPPING_SOON,
  // Return Tracking Statuses
  [DROPOFF_PENDING]: CS_DROPOFF_PENDING,
  [RETURNED_TO_ZAPPOS]: CS_RETURNED_TO_ZAPPOS,
  [RETURNING_TO_ZAPPOS]: CS_RETURNING_TO_ZAPPOS,
  [PICKUP_SCHEDULED]: CS_PICKUP_SCHEDULED,
  [PROCESSING_RETURN]: CS_PROCESSING_RETURN
};

type CompositeStatusMap = keyof typeof compositeStatusMap;
type LegacyStatusMap = keyof typeof legacyStatusMap;
type Shipment = LineItem['shipment'];

export function getPaymentType(creditCardNumber: string) {
  switch (parseInt(creditCardNumber.slice(0, 1))) {
    case 3:
      return 'AmericanExpress';
    case 4:
      return 'Visa';
    case 5:
      return 'MasterCard';
    case 6:
      return 'Discover';
    default:
      return null;
  }
}

export function isCreditCardExpired(creditCard: { expirationMonth: string | number; expirationYear: string | number }) {
  const currDate = new Date(Date.now());
  const expMonth = typeof creditCard.expirationMonth === 'number' ? creditCard.expirationMonth : parseInt(creditCard.expirationMonth);
  const expYear = typeof creditCard.expirationYear === 'number' ? creditCard.expirationYear : parseInt(creditCard.expirationYear);
  return expYear < currDate.getFullYear() || (expYear === currDate.getFullYear() && expMonth < currDate.getMonth() + 1);
}

export function formatCreditCardExpiration(expiration: string) {
  let [month, year] = expiration.split(/\W|\//);

  if (month?.length === 1) {
    month = `0${month}`;
  }

  if (year?.length === 2) {
    year = `20${year}`;
  }

  return {
    expirationMonth: month,
    expirationYear: year
  };
}

export function isExpirationDateValid(expMonth: number, expYear: number) {
  const today = new Date();
  const selectedDate = new Date();
  selectedDate.setFullYear(expYear, expMonth, 1);
  return selectedDate >= today;
}

export function formatCreditCard(number: string) {
  return number.replace(/\D/g, '');
}

export function hasTracking(order: { lineItems: { shipment?: Shipment }[] }) {
  const { lineItems } = order;
  return lineItems.every(item => item?.shipment?.tracking?.events?.length);
}

export function hasOrderBeenShipped(lineItems: { state: string }[]) {
  return lineItems.every(({ state }) => state === SHIPPED || state === RETURNING || state === RETURNED || state === COMPLETED);
}

export function hasOrderBeenDelivered(lineItems: { state: string; shipment: Shipment }[]) {
  return (
    hasOrderBeenShipped(lineItems) &&
    lineItems.every(({ state, shipment }) => {
      const { tracking } = shipment;
      return tracking.statusCode === DELIVERED || state === COMPLETED;
    })
  );
}

export const hasShipmentBeenDelivered = (status: string) => DELIVERED_STATUSES.includes(status);

export const hasShipmentBeenShipped = (status: string) => SHIPPED_STATUSES.includes(status);

export function getCurrentShipment(shipmentId?: string, shipments?: Shipment[]): Partial<Shipment> {
  return shipments?.find(shipment => shipmentId && shipmentId === shipment.id) || {};
}

export function getCurrentShipmentStatus(shipment: Partial<Shipment>) {
  return shipment.tracking ? shipment.tracking?.status : (shipment.compositeStatus ?? '');
}

export function getShipmentsWithFilteredOutCompositeStatuses(shipments: Shipment[]) {
  return shipments?.filter(({ compositeStatus }) => compositeStatus !== CS_CANCELLED);
}

// it filters out duplicate ids
function filterDuplicateShipments(shipments: Shipment[] = []) {
  const filteredShipments: Shipment[] = [];
  shipments.forEach(item => {
    if (!filteredShipments.some(shipment => shipment.id === item.id)) {
      filteredShipments.push(item);
    }
  });
  return filteredShipments;
}

// we will do some filtering on what is counted as a shipment
export function getTotalShipmentsNumber(shipments: Shipment[]) {
  const compositeShipments = getShipmentsWithFilteredOutCompositeStatuses(shipments);
  return filterDuplicateShipments(compositeShipments).length;
}

export function getCurrentShipmentNumber(shipmentId: string, shipments: Shipment[]) {
  const compositeShipments = getShipmentsWithFilteredOutCompositeStatuses(shipments);
  return filterDuplicateShipments(compositeShipments).findIndex(shipment => shipmentId === shipment.id) + 1;
}

export function getCurrentShipmentMessage(shipmentId: string, shipments: Shipment[]) {
  return getCurrentShipment(shipmentId, shipments)?.shipmentDisplayMessage;
}

// composite status represents
export function getLegacyStatusFromCompositeStatus(status?: OrderStatus | string) {
  return legacyStatusMap[status as LegacyStatusMap];
}

export function getCompositeStatusfromLegacyStatus(status: CompositeStatusMap) {
  return compositeStatusMap[status];
}

/*
  With the release of the exchange feature, an order will not be cancellable
  by the customer - but still is by the CLT - if it is
  generated by an exchange. The new flag 'isCustomerCancellable', will
  support that business rule.
  But we keep the possibility to use the old flags by default.
*/
export function isOrderCancellable(lineItems: LineItem[], doesUseNewFlags = false) {
  return lineItems.some(lineItem => isItemCancellable(lineItem, doesUseNewFlags));
}

export function isItemCancellable(item?: LineItem, useNewFlag = false) {
  return useNewFlag ? !!item?.customerCancellable : !!item?.cancellable;
}

export function isOrderExchangeable(lineItems: LineItem[]) {
  return lineItems.some(lineItem => lineItem.exchangeable);
}

export function getAllExchangeableItemUniqueIds(lineItems: LineItem[]) {
  const filteredExchangeableItems = lineItems.filter(({ exchangeable }) => !!exchangeable);
  return filteredExchangeableItems.map(({ itemUniqueId }) => itemUniqueId);
}

/*
  With the release of the exchange feature, an order will not be returnable
  by the customer - but still is by the CLT - if it is
  generated by an exchange. The new flag 'isCustomerReturnable', will
  support that business rule.
  But we keep the possibility to use the old flags by default.
*/
export function isOrderReturnable(lineItems: LineItem[], doesUseNewFlags = false) {
  return doesUseNewFlags ? lineItems.some(lineItem => lineItem.customerReturnable) : lineItems.some(lineItem => lineItem.returnable);
}

export function isItemReturnable(lineItem?: LineItem, doesUseNewFlags = false) {
  return doesUseNewFlags ? lineItem?.customerReturnable : lineItem?.returnable;
}

export function checkIsItemStatusExchangeableReturnable(lineItem?: LineItem, shipments?: Shipment[]) {
  const shipment = getCurrentShipment(lineItem?.shipment.id, shipments);
  const message = shipment.shipmentDisplayMessage;
  return (
    !message?.toLowerCase()?.includes('delayed') && !message?.toLowerCase()?.includes('arriving') && lineItem?.compositeStatus !== CS_IN_TRANSIT
  );
}

export function getAllReturnableItemUniqueIds(lineItems: LineItem[]) {
  const filteredReturnableItems = lineItems.filter(lineItem => isLineItemReturnable(lineItem));
  return filteredReturnableItems.map(({ itemUniqueId }) => itemUniqueId);
}

export function extractReturnableOrders(orders: { lineItems: LineItem[] }[], doesUseNewFlag = false) {
  return orders.filter(order => isOrderReturnable(order.lineItems, doesUseNewFlag));
}

export function isLineItemReturnable(lineItem: LineItem, doesUseNewFlags = false) {
  return doesUseNewFlags ? lineItem.customerReturnable : lineItem.returnable;
}

export function isOrderGeneratedByExchange(order: { exchangeOrder: unknown }) {
  return !!order.exchangeOrder;
}

export function reverseReturnUrlLookup(url: string) {
  if (/^\/addresses$/.test(url)) {
    return 'Shipping Information';
  } else if (/^\/addresses\/new$/.test(url)) {
    return 'Add New Address';
  } else if (/^\/payments$/.test(url)) {
    return 'Manage Payments';
  } else if (/^\/payments\/new$/.test(url)) {
    return 'Add New Payment';
  } else if (/^\/payments\/[a-zA-Z0-9-_]+\/edit$/.test(url)) {
    return 'Edit Payment Method';
  } else if (/^\/orders\/[a-zA-Z0-9-_]+$/.test(url)) {
    return 'Order Information';
  }
  return;
}

export function normalizePaymentType(paymentType: string) {
  switch (paymentType) {
    case 'Visa':
      return CardType.VISA;
    case 'MasterCard':
      return CardType.MASTERCARD;
    case 'Discover':
      return CardType.DISCOVER;
    case 'AmericanExpress':
      return CardType.AMERICAN_EXPRESS;
  }
}

export function generateProductUrl(product: LineItemProduct, isLegacy: boolean) {
  const { asin, productUrl } = product;
  if (isLegacy) {
    return productUrl;
  } else {
    return `/p/asin/${asin}`;
  }
}

export function isItemInReturnState(itemState: string) {
  return RETURN_STATUSES.includes(itemState);
}

export function isItemInCancelState(itemState: string) {
  return CANCEL_STATUSES.includes(itemState);
}

/* query-string library not correctly parsing '+' symbol and sending spaces for subscriptions endpoint */
export function replaceAuthTokenSpace(authToken: string) {
  if (authToken) {
    return authToken.split(' ').join('+');
  }
  return;
}

/*
  Generates unique ids for each lineItem and assigns them.

  The ids have the following format:
  - {order id}-{returnable-fingerprint}-{item code}-{index}

  Example:
  - 113-5838898-9370642-r-21136263204721-0

  {returnable-fingerprint} specifies whether the item is (r)eturnable.
  In the example above, '-r-' means the item is indeed returnable.

  {index} differentiates between two or more line items with
  identical order ids, attributes and codes.
*/
export function injectUniqueIdsToLineItems({ orderId, lineItems = [] }: { orderId: string; lineItems: LineItem[] }) {
  const idSuffixes: Record<string, number> = {};
  return lineItems.map(lineItem => {
    const { id: lineItemId, returnable } = lineItem;
    const returnableFingerPrint = returnable ? '-r' : '';
    const idPrefix = `${orderId}${returnableFingerPrint}-${lineItemId}`;
    idSuffixes[idPrefix] = idSuffixes[idPrefix] || 0;
    const idSuffix = idSuffixes[idPrefix]++;
    return {
      ...lineItem,
      itemUniqueId: `${idPrefix}-${idSuffix}`
    };
  });
}

/* Combine info from fetchShipmentTracking call with order data in state */
export function injectTrackingToLineItems({ lineItems }: { lineItems: LineItem[] }, trackingInfo: Shipment[]) {
  const updatedLineItems: LineItem[] = [];
  lineItems.forEach(lineItem => {
    const lineItemCopy = Object.assign({}, lineItem);
    const {
      shipment: { id }
    } = lineItem;
    trackingInfo.forEach(info => {
      const { id: trackingInfoShipmentId } = info;
      if (id === trackingInfoShipmentId) {
        lineItemCopy.shipment = info;
      }
    });
    updatedLineItems.push(lineItemCopy);
  });
  return updatedLineItems;
}

export function getAsinFromProductUrl(productUrl: string) {
  if (productUrl && productUrl.includes('asin')) {
    return productUrl.split(/\/asin\//)[1];
  }
  return null;
}

export function getOrderAddress(orderShippingAddress: Address, shipping: { addresses: Address[] }) {
  const { addressId: orderAddressId } = orderShippingAddress || {};
  const { addresses: customerAddresses } = shipping;
  if (!customerAddresses?.length) {
    return orderShippingAddress;
  }

  const matchedAddress = customerAddresses.find(customerAddress => customerAddress.addressId === orderAddressId);

  if (matchedAddress) {
    return orderShippingAddress;
  }

  let defaultAddress = customerAddresses.find(customerAddresses => customerAddresses.isDefaultShippingAddress);

  if (!defaultAddress) {
    defaultAddress = customerAddresses[0];
  }

  const {
    addressId,
    mailingAddress: { addressLine1, addressLine2, city, countryCode, countryName, postalCode, stateOrRegion },
    name: { fullName },
    isDefaultShippingAddress,
    phone: {
      voice: {
        primary: { number }
      }
    }
  } = defaultAddress as Address;

  return {
    addressId: addressId,
    addressLine1: addressLine1,
    addressLine2: addressLine2,
    city: city,
    countryCode: countryCode,
    countryName: countryName,
    fullName: fullName,
    phoneNumber: number,
    postalCode: postalCode,
    primaryShippingAddress: isDefaultShippingAddress,
    stateOrRegion: stateOrRegion
  };
}

export function makeShipmentStatusEvent(order: { shipments: Shipment[]; orderId: string }) {
  const { shipments = [], orderId } = order || {};

  // return all shipment statuses of an order
  return shipments.map(shipment => {
    const { id: shipmentId, compositeStatus } = shipment || {};

    return {
      orderId,
      shipmentId,
      compositeStatus
    };
  });
}

export const getPaginationInformation = (search: string) => {
  const queryParams = queryString.parse(search);
  const { page, pageSize } = queryParams;
  return {
    currentPage: parseInt(page, 10) || 1,
    pageSize
  };
};

export function applyReturnTrackingStatusToLegacyStatus(status: string, returnTrackingStatus: string) {
  if (LEGACY_ORDER_RETURN_STATUSES.includes(status)) {
    return getReturnStatusFromReturnTrackingInfo(returnTrackingStatus);
  } else {
    return status;
  }
}

export function applyReturnTrackingStatusToCompositeStatus(compositeStatus: OrderStatus, returnTrackingStatus: string) {
  const status = getLegacyStatusFromCompositeStatus(compositeStatus);
  if (LEGACY_ORDER_RETURN_STATUSES.includes(status)) {
    const convertedStatus = getReturnStatusFromReturnTrackingInfo(returnTrackingStatus);
    return convertedStatus ? getCompositeStatusfromLegacyStatus(convertedStatus) : null;
  } else {
    return compositeStatus;
  }
}

// takes the lower end of the estimate on number of orders
export function calculateEstimatedOrderTotal(pageSize: number, totalPages: number, numberOfOrdersOnFirstPage: number) {
  if (totalPages > 1) {
    // use orders if totalPages === 1;
    return pageSize ? (totalPages - 1) * pageSize + 1 : (totalPages - 1) * 10 + 1;
  } else {
    return numberOfOrdersOnFirstPage;
  }
}

export function getReturnStatusFromReturnTrackingInfo(returnTrackingInfo: string) {
  switch (returnTrackingInfo) {
    case 'DROPOFF_PENDING':
      return DROPOFF_PENDING;
    case 'PICKUP_SCHEDULED':
      return PICKUP_SCHEDULED;
    case 'PICKUP_FAILED':
    case 'NO_TRACKING_DATA':
      return CUSTOMER_ACTION_REQUIRED;
    case 'RETURNING':
      return RETURNING_TO_ZAPPOS;
    case 'PROCESSING':
      return PROCESSING_RETURN;
    case 'PROCESSED':
    case 'REFUNDED':
      return RETURNED_TO_ZAPPOS;
    default:
      return null;
  }
}

export function formatExpirationMMYY(value: string) {
  let month = '';
  let year = '';
  const numRegex = /^[0-9]*$/; // will only allow numbers to be typed.

  for (let i = 0; i < value.length; i++) {
    const currentCharacter = value[i] as string;

    if (month.length === 2 && year.length === 4) {
      break;
    }

    if (numRegex.test(currentCharacter)) {
      if (month.length < 2) {
        switch (month.length) {
          case 0:
            if (currentCharacter >= '2' && currentCharacter <= '9') {
              month = `0${currentCharacter}`;
            } else {
              month = currentCharacter;
            }
            break;
          case 1:
            if (month === '0' || (month === '1' && currentCharacter <= '2')) {
              month += currentCharacter;
            } else {
              month = `0${month}`;
              year = currentCharacter;
            }
            break;
          default:
            break;
        }
      } else if (year.length < 4) {
        switch (year.length) {
          case 0:
            year = currentCharacter;
            break;
          case 1:
            year += currentCharacter;
            break;
          case 2:
            year += currentCharacter;
            break;
          case 3:
            year += currentCharacter;
            break;
          default:
            break;
        }
      }
    }
  }

  let builtString = '';
  if (year.length) {
    if (year.length > 2) {
      const lastTwoDigitsOfYear = year.slice(-2);
      builtString = `${month} / ${lastTwoDigitsOfYear}`;
    } else {
      builtString = `${month} / ${year}`;
      year = `20${year}`;
    }
  } else if (value[1] === '/' && month === '1') {
    builtString = '01 / ';
  } else if (value.length === 5 || value.length === 4) {
    builtString = `${month}`;
  } else if (month.length === 2) {
    builtString = `${month} / `;
  } else {
    builtString = month;
  }

  return { builtString, month, year };
}

export const createExpirationErrorString = (month: string, year: string, currentDate = new Date()) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  let textError = '';

  if (year.length < 4) {
    textError = 'Please enter month and year (MM/YY).';
  } else if (parseInt(year) < currentYear) {
    textError = 'Please enter a valid year.';
  } else if (parseInt(month) < currentMonth && parseInt(year) === currentYear) {
    textError = 'Expiry cannot be in the past.';
  }

  return textError;
};

export function formatCVVBasedCCNumber(value: string, paymentType: string) {
  let cvvNumber = '';

  for (let i = 0; i < value.length; i++) {
    const currentCharacter = value[i] as string;
    if (PHONE_CHARS.test(currentCharacter)) {
      switch (paymentType === 'AmericanExpress') {
        case true:
          if (cvvNumber.length === 4) {
            break;
          }
          cvvNumber += currentCharacter;
          break;
        case false:
          if (paymentType !== '' && cvvNumber.length === 3) {
            break;
          } else if (paymentType === '' && cvvNumber.length === 4) {
            break;
          }
          cvvNumber += currentCharacter;
          break;
      }
    }
  }
  return cvvNumber;
}

export function createCVVErrorString(cvv: string) {
  let errorString = '';
  if (cvv.length < 3 && cvv) {
    errorString = "Your card's security code is incomplete.";
  } else if (cvv.length === 0) {
    errorString = 'CVV cannot be empty.';
  }

  return errorString;
}

export function createDeleteAccountMailToLink(siteName: string, name: string, email: string) {
  if (!siteName || !name || !email) {
    return;
  }

  const to = 'privacy@zappos.com';
  const subject = `Request to delete my ${siteName} account`;
  const body = `${siteName} customer,\n${name}\n${email}\n\nHas requested on ${siteName}.com to have the account permanently deleted. Please email the customer at ${email} and wait for confirmation before permanently deleting the account.`;
  const mailToLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  return mailToLink;
}

export function isMaybeGuestAuthOrderFlow(search: string | undefined) {
  try {
    const params = new URLSearchParams(search);
    return params.has(EMAIL_TOKEN_SEARCH_PARAM) && !!params.get(EMAIL_TOKEN_SEARCH_PARAM);
  } catch (e) {
    return false;
  }
}
