import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/common/lowStockLabel.scss';

const LowStockLabel = () => {
  const { testId } = useMartyContext();

  return (
    <p className={css.lowStockLabel} data-test-id={testId('lowStockLabel')} aria-label="Low stock">
      Low Stock
    </p>
  );
};

export default LowStockLabel;
