import { forwardRef } from 'react';

import { cn } from 'helpers/classnames';
import type TagProps from 'tailwind/components/Tag/Tag.types';
import { baseClasses, sizeClasses, variantClasses } from 'tailwind/components/Tag/Tag.classes';

const Tag = forwardRef<HTMLSpanElement, TagProps>((props: TagProps, ref) => {
  const { size = 'large', variant, ...forwardProps } = props;

  return <span className={cn(baseClasses, variantClasses[variant], sizeClasses[size])} {...forwardProps} ref={ref} />;
});

export default Tag;
