import React from 'react';

import { cn } from 'helpers/classnames';
import { pluralize } from 'helpers/index';

interface Style {
  styleId: string;
}

interface Props {
  cssHeartActive: string;
  style: Style;
  cssHeartContainer?: string;
  extraRecoStyle?: string | null;
  handleHeartClick?: (...args: any[]) => any;
  isUninteractive?: boolean;
  showFavoriteHeart?: boolean;
  productId?: string;
  merchantId?: string;
  isDisplayCount?: boolean;
  testId?: string | null;
  isHearted: boolean;
  count: string | number;
}
const Heart = ({
  cssHeartActive,
  cssHeartContainer,
  extraRecoStyle = null,
  handleHeartClick = f => f,
  isUninteractive = false,
  showFavoriteHeart,
  style,
  productId,
  isDisplayCount = true,
  testId = null,
  isHearted = false,
  count = 0
}: Props) => {
  if (!showFavoriteHeart) {
    return null;
  }

  const currentCountLabel = `${count} ${pluralize('person', count)} ${pluralize('has', count)} favorited this`;

  if (isUninteractive) {
    return (
      <span className={cn(cssHeartContainer, { [cssHeartActive]: isHearted }, extraRecoStyle)} data-test-id={testId}>
        <span className="sr-only">{currentCountLabel}</span>
        <span aria-hidden="true">{count}</span>
      </span>
    );
  }

  return (
    <button
      type="button"
      className={cn(cssHeartContainer, { [cssHeartActive]: isHearted }, extraRecoStyle)}
      aria-pressed={isHearted}
      onClick={handleHeartClick(style, productId)}
      data-test-id={testId}
    >
      <span className="sr-only">
        {isHearted ? 'Remove from favorites' : 'Add to favorites'}. {currentCountLabel}
      </span>
      {isDisplayCount && <span aria-hidden="true">{count}</span>}
    </button>
  );
};

export default Heart;
