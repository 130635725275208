export const getSearchInputValue = (
  term: string,
  suggestionIndex: number,
  suggestions: {
    suggestion: string;
    categories: string[];
    searchUrl: string;
    isSegment: boolean;
    parentSuggestion: string;
  }[] = []
) => {
  if (typeof suggestionIndex === 'number' && suggestions?.length) {
    const suggestion = suggestions[suggestionIndex];
    const { categories, searchUrl, isSegment, parentSuggestion } = suggestion || {};
    if (isSegment && searchUrl) {
      return `${parentSuggestion} ${suggestion?.suggestion}`;
    } else if (searchUrl) {
      return '';
    } else if (categories?.length && suggestionIndex === 0) {
      return `${suggestion?.suggestion} in ${categories[0]}`;
    } else if (typeof suggestion === 'object') {
      return `${suggestion.suggestion}`;
    } else {
      return `${suggestion}`;
    }
  }
  return term;
};

type DisplayPhraseData = {
  link?: string;
  text?: string;
};

export const isDisplayPhraseDataEqual = (displayPhraseData1: DisplayPhraseData, displayPhraseData2: DisplayPhraseData) => {
  if ((displayPhraseData1 && !displayPhraseData2) || (!displayPhraseData1 && displayPhraseData2)) {
    return false;
  }

  const { link: link1 = '', text: text1 = '' } = displayPhraseData1 || {};
  const { link: link2 = '', text: text2 = '' } = displayPhraseData2 || {};
  return link1 === link2 && text1 === text2;
};

/*
 * overwriteNavItem()
 *
 * Always returns a navigation object.
 * <First clause>: If assignment node is an object, return that nav object for overwrite.
 * <Second clause>: If assignment node equals `DoNotRender`, set text to blank string and return nav.
 * Header nav logic says if there is no text belonging to a nav item, don't render it.
 * <Defaut>: Return nav object.
 *
 * overwriteSubNavItems()
 *
 * If the test treatment is an object, then return overwriteSubNavItems
 * or return undefined
 */

type TreatmentState = {
  treatment?:
    | string
    | {
        subNavMenuItems: unknown;
      };
};

const isValidTestTreatment = (index: number, state: TreatmentState[]) => state && state[index] && !!state[index]?.treatment;
const treatmentExistsAndIsObject = (index: number, state: TreatmentState[]) =>
  isValidTestTreatment(index, state) && typeof state[index]?.treatment === 'object';

export function overwriteNavItem(nav: { text: string }, state: TreatmentState[], index: number) {
  if (treatmentExistsAndIsObject(index, state)) {
    return state[index]?.treatment;
  } else if (isValidTestTreatment(index, state) && state[index]?.treatment === 'DoNotRender') {
    nav.text = '';
  }
  return nav;
}

export function overwriteSubNavItems(index: number, state: TreatmentState[]) {
  if (treatmentExistsAndIsObject(index, state)) {
    return (state[index]?.treatment as { subNavMenuItems: unknown }).subNavMenuItems;
  }
  return undefined;
}
